<template>
  <FloatLabel variant="on">
    <InputText
      id="inventory-comment"
      v-model="commentComputed"
      class="inputfield w-full"
      data-testid="inventory-comment"
      :disabled="props.quantityInStock === 0 || props.quantityInStock === undefined"
      v-tooltip.bottom="{
        value: t('placeholder.select', { property: t('inventory.edit-inventory.comment').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
    />
    <label for="inventory-comment">{{ t(`inventory.edit-inventory.comment`) }}</label>
  </FloatLabel>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { computed } from "vue";

const { t } = useI18n();

const props = defineProps<{
  comment: string;
  quantityInStock: number;
}>();

const emit = defineEmits<{
  (e: "update:comment", value: string): void;
}>();

const commentComputed = computed<string>({
  get: (): string => {
    return props.comment;
  },
  set: (value: string) => {
    emit("update:comment", value);
  },
});
</script>
