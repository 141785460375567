import { useAuth } from "@cumulus/event-bus";
import { customerApi } from "./CustomerApi";
import { Customer } from "./model/Customer";
import { useErrorHandler } from "../ErrorHandler";

export function useCustomer() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getCustomer = async (id: string): Promise<Customer> => {
    try {
      const authHeaders = await getAuthHeaders();
      const customer = await customerApi.get(authHeaders, id);

      return customer;
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return { getCustomer };
}
