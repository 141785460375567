import { InventoryCountLine } from "./InventoryCountLine";

export class UpdateInventoryCountJobLinesRequest {
  inventoryCountJobId = "";
  updatedLines: InventoryCountLine[] = [];
  deletedLineIds: string[] = [];

  constructor(inventoryCountJobId: string, updatedLines: InventoryCountLine[], deletedLineIds: string[] = []) {
    this.inventoryCountJobId = inventoryCountJobId;
    this.updatedLines = updatedLines;
    this.deletedLineIds = deletedLineIds;
  }
}
