import { useErrorHandler } from "@/repositories/ErrorHandler";
import { clientRepository } from "@/repositories/client/ClientRepository";
import { Client, useAuth } from "@cumulus/event-bus";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useClientStore = defineStore("client", () => {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const clients = ref<Client[]>([]);

  const getAllClients = async (): Promise<void> => {
    try {
      const authHeaders = await getAuthHeaders();
      clients.value = await clientRepository.getAll(authHeaders);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return {
    clients,
    getAllClients,
  };
});
