import { InventoryChangeLogFilters } from "./search/InventoryChangeLogFilters";

export class GetInventoryChangeLogsRequest {
  page = 1;
  pageSize = 50;
  filters = new InventoryChangeLogFilters();
  sortBy: string | null = null;
  sortOrder: string | null = null;

  constructor(page: number, pageSize: number, filters: InventoryChangeLogFilters, sortBy: string | null) {
    this.page = page;
    this.pageSize = pageSize;
    this.filters = filters;
    this.sortBy = sortBy;
  }
}
