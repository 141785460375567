import { ProductGroupFilter } from "./ProductGroupFilter";
import { ProductStatus } from "./ProductStatus";

export class ProductSearchFilters {
  activeOnWeb: boolean | null = null;
  manufacturers: string[] = [];
  warehouses: string[] = [];
  productStatuses: ProductStatus[] = [];
  productGroups: ProductGroupFilter = new ProductGroupFilter();
}
