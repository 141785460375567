import { useAuth, loginRequest, InteractionRequiredAuthError } from "@cumulus/event-bus";
import { BadRequestError, InternalServerError, NotFoundError } from "@cumulus/http";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";
import { useI18n } from "vue-i18n";
import { ApiRequestError } from "@cumulus/http/dist/error-handler";

export function useErrorHandler() {
  const { instance } = useAuth();
  const toast = useCumulusToast(useToast());
  const { t } = useI18n();

  const handleError = async (error: unknown): Promise<void> => {
    if (error instanceof InteractionRequiredAuthError) {
      await instance.acquireTokenRedirect(loginRequest);
      return;
    }

    let errorMessage = "";
    if (error instanceof InternalServerError) {
      errorMessage = t("common.error-detail.server-error");
    } else if (error instanceof NotFoundError) {
      errorMessage = t("common.error-detail.not-found");
    } else if (error instanceof BadRequestError) {
      let errorMessages: string[] = [];
      if (error.message instanceof Array) {
        errorMessages = error.message.map((message: ApiRequestError) => message.errorMessage);
      }
      if (errorMessages.length > 0) {
        errorMessages.forEach(addErrorToast);
      } else {
        errorMessage = t("common.error-detail.bad-request");
      }
    } else {
      errorMessage = t("common.error-detail.unknown");
    }

    if (errorMessage !== "") {
      addErrorToast(errorMessage);
    }
  };

  const addErrorToast = (message: string): void => {
    toast.add({
      severity: "error",
      summary: t("common.error-detail.error-occured"),
      detail: message,
      closable: true,
    });
  };

  return { handleError };
}
