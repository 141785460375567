import { useAuth } from "@cumulus/event-bus";
import { useErrorHandler } from "@/repositories/ErrorHandler";
import { bringApi } from "./BringApi";
import { BringShippingGuideRequest } from "./BringShippingGuideRequest";
import { NewBringBooking } from "./NewBringBooking";
import { PickupPoint } from "./PickupPoint";

export function useBring() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getBringShippingGuide = async (request: BringShippingGuideRequest) => {
    try {
      const authHeaders = await getAuthHeaders();
      return await bringApi.getBringShippingGuide(authHeaders, request);
    } catch (error) {
      await handleError(error);
    }
  };

  const storeBringBookingData = async (newBringBooking: NewBringBooking): Promise<string> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await bringApi.storeBringBookingData(authHeaders, newBringBooking);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getPickupPoints = async (
    countryCode: string,
    postalCode: string,
    street: string,
    streetNumber: string
  ): Promise<PickupPoint[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      const response = await bringApi.getPickupPoints(
        authHeaders,
        `postalCode=${postalCode}&countryCode=${countryCode}&street=${street}&streetNumber=${streetNumber}`
      );

      return response.pickupPoint;
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return { storeBringBookingData, getBringShippingGuide, getPickupPoints };
}
