import { SearchFilters } from "./SearchFilters";

export class OrderReadyForPickingSearchRequest {
  query = "";
  page = 1;
  pageSize = 25;
  sortBy = "";
  sortOrder = "asc";
  filters = new SearchFilters();

  constructor(query: string, page: number, pageSize: number, filters: SearchFilters) {
    this.query = query;
    this.page = page;
    this.pageSize = pageSize;
    this.filters = filters;
  }
}
