<template>
  <Dialog
    v-model:visible="visible"
    :modal="true"
    :header="t(`checkout.header`)"
    :style="{ width: '95vw' }"
    class="c-checkout-dialog md:!w-3/4"
  >
    <div class="grid grid-cols-12 gap-4 mb-4 m-2">
      <div class="col-span-12 md:col-span-6">
        <DeliverTo :deliverTo="deliverTo" />
      </div>

      <div class="col-span-12 md:col-span-6">
        <PickingListNumber :pickinglistNumber="pickinglistNumber" />
        <OrderNumber :orderNumber="orderNumber" />
        <Customer :customerNumber="customerNumber" :customerName="customerName" />
      </div>

      <div class="col-span-12 md:col-span-6">
        <FreightMethod
          v-model:pickingFreight="pickingFreight"
          v-model:bring="bring"
          ref="freightMethodRef"
          @pickup-point-changed="handlePickupPointChanged"
          :deliver-to="deliverTo"
        />
      </div>

      <div class="col-span-12">
        <Card>
          <template #content>
            <Parcels
              v-model:parcels="parcels"
              @weight-changed="handleWeightChanged"
              @parcel-added="handleParcelAdded"
              @parcel-deleted="handleParcelDeleted"
              @dimensions-changed="handleDimensionsChanged"
            />
          </template>
        </Card>
      </div>
      <div class="col-span-12">
        <FreightSummary
          :parcels="parcels"
          :picking-freight="pickingFreight"
          v-model:freightPriceOverridden="freightPriceOverridden"
          :agreedPrice="props.agreedFreightPriceExVat != null"
        />

        <BringError :errors="shippingGuideResponse?.consignments[0].products[0].errors ?? []" />
      </div>
      <div v-if="!printixNotFound" class="col-span-12 md:col-span-6">
        <label for="shipping-label-printer"> {{ t("common.shipping-label-printer") }}: </label>
        <Select
          v-model="selectedShippingLabelPrinter"
          :options="printers"
          optionLabel="name"
          :placeholder="t('common.select-printer')"
          :loading="loadingPrinters"
          id="shipping-label-printer"
          pt:list:data-testid="shipping-label-printer-list"
        />
        <div v-if="showSelectShippingPrinterError" class="p-error">{{ "Select printer" }}</div>
      </div>
      <div v-if="!printixNotFound" class="col-span-12 md:col-span-6 text-right">
        <label for="packing-note-printer"> {{ t("common.packing-note-printer") }}: </label>
        <Select
          v-model="selectedPackingNotePrinter"
          :options="printers"
          optionLabel="name"
          :placeholder="t('common.select-printer')"
          :loading="loadingPrinters"
          id="packing-note-printer"
          pt:list:data-testid="packing-note-printer-list"
        />
        <div v-if="showSelectPackingNoteError" class="p-error">{{ "Select packing note printer" }}</div>
      </div>
      <div v-if="printixNotFound">
        <p class="p-error">{{ t("error.no-printers-found") }}</p>
      </div>
    </div>

    <template #footer>
      <div class="flex justify-between flex-wrap card-container purple-container mt-6">
        <div class="flex items-center justify-center">
          <Button
            :label="t(`common.cancel`)"
            data-testid="cancel-btn"
            @click="visible = false"
            tabindex="47"
            class="c-dialog-default-button mr-4"
            text
          />

          <Button
            :label="t(`checkout.confirm`)"
            data-testid="btn-confirm-checkout"
            @click="onConfirm"
            class="c-dialog-default-button c-dialog-success-button"
            tabindex="46"
            :disabled="confirmButtonDisabled"
          />
        </div>
      </div>
      <div v-if="errorReason !== ''" class="p-error flex justify-start mt-2" data-testid="available-error">
        {{ errorReason }}
      </div>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { computed, ref, nextTick, watch, onMounted, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import { Delivery } from "@/repositories/picking-list/model/Delivery";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";
import { PickingFreight } from "@/repositories/picking-list/model/PickingFreight";
import { Bring } from "@/goods-out/bring-integration/Bring";
import { v4 as uuid } from "uuid";

import useValidate from "@vuelidate/core";
import PickingListNumber from "./components/PickingListNumber.vue";
import OrderNumber from "./components/OrderNumber.vue";
import Customer from "./components/Customer.vue";
import DeliverTo from "./components/DeliverTo.vue";
import FreightMethod from "./components/FreightMethod.vue";
import Parcels from "./components/Parcels.vue";
import FreightSummary from "./components/FreightSummary.vue";
import { useWarehouse } from "@/repositories/warehouse/WarehouseService";
import { Parcel } from "@/goods-out/bring-integration/Parcel";
import {
  BringShippingGuideRequest,
  Consignment,
  Package,
  Product as RequestProduct,
} from "@/goods-out/bring-integration/BringShippingGuideRequest";
import BringError from "./components/BringError.vue";
import { type BringShippingGuideResponse } from "@/goods-out/bring-integration/BringShippingGuideResponse";
import { useBring } from "@/goods-out/bring-integration/BringService";
import { Warehouse } from "@/repositories/warehouse/model/Warehouse";
import { type PickupPoint } from "@/goods-out/bring-integration/PickupPoint";
import { Printer } from "@/goods-out/document/Printer";
import { useDocumentService } from "@/goods-out/document/DocumentService";
import { DocumentType } from "@/goods-out/document/DocumentType";
import { NotFoundError } from "@cumulus/http";
import { DefaultPrinterInfo } from "@/goods-out/document/DefaultPrinterInfo";

const { t, locale } = useI18n();
const val = useValidate();
const toast = useCumulusToast(useToast());
const errorReason = ref("");
const loadingPrinters = ref(true);
const bringService = useBring();
const confirmButtonDisabled = computed(() => fetchingShippingGuide.value || val.value.$error);

const selectedShippingLabelPrinter = ref<Printer | null>();
const selectedPackingNotePrinter = ref<Printer | null>();
const printers = ref<Printer[]>([]);
const printixNotFound = ref(false);

const showSelectShippingPrinterError = ref(false);
const showSelectPackingNoteError = ref(false);

const { getAllWarehouses } = useWarehouse();
const { getAllPrinters, getDefaultPrinterByDocumentType } = useDocumentService();

const bring = ref<Bring | null>(null);
const props = defineProps<{
  showDialog: boolean;
  pickinglistNumber: number;
  orderNumber: number;
  customerNumber: string;
  customerName: string;
  deliverTo: Delivery;
  freightMethodId: string;
  agreedFreightPriceExVat: number | null | undefined;
}>();

const emit = defineEmits<{
  (e: "update:showDialog", value: boolean): void;
  (
    e: "confirm",
    value: PickingFreight,
    bring: Bring | null,
    warehouse: Warehouse,
    parcels: Parcel[],
    pickupPoint: PickupPoint | null,
    shippingLabelPrinter: string,
    packingNotePrinter: string,
  ): void;
}>();
const selectedPickupPoint = ref<PickupPoint | null>(null);
const pickingFreight = ref<PickingFreight>(new PickingFreight());

const parcels = ref<Parcel[]>([new Parcel(1)]);
const handleParcelDeleted = () => {
  if (bring.value) getBringShippingGuide();
};
const handleParcelAdded = () => {
  pickingFreight.value.freightPackages = parcels.value.length;
};
const handleWeightChanged = () => {
  pickingFreight.value.freightWeight = parcels.value.map((x) => x.weight).reduce((a, b) => a + b);
  if (bring.value) getBringShippingGuide();
};
const handleDimensionsChanged = () => {
  if (bring.value) getBringShippingGuide();
};
const handlePickupPointChanged = (value: PickupPoint) => {
  selectedPickupPoint.value = value;
  getBringShippingGuide();
};

watch(
  () => bring.value,
  () => {
    if (bring.value != null) getBringShippingGuide();
    return;
  },
);

const shippingGuideResponseHasErrors = computed(() => {
  if (shippingGuideResponse.value == null) return false;
  return (shippingGuideResponse.value.consignments?.[0]?.products?.[0]?.errors?.length ?? [].length > 0) ? true : false;
});

const fetchingShippingGuide = ref(false);
const shippingGuideResponse = ref<BringShippingGuideResponse>();
const createBringShippingGuideRequest = () => {
  const shippingGuideRequest = new BringShippingGuideRequest();
  shippingGuideRequest.withGuiInformation = true;
  shippingGuideRequest.language = getLanguageFromLocale(locale.value);

  const consignment = new Consignment();
  consignment.id = uuid();
  //We only support norway currently
  consignment.fromCountryCode = consignment.toCountryCode = "NO";
  if (selectedPickupPoint.value != null) {
    consignment.toPostalCode = selectedPickupPoint.value.postalCode;
    consignment.pickupPointId = selectedPickupPoint.value.id;
  } else {
    consignment.toPostalCode = props.deliverTo.address.postalCode;
  }
  consignment.fromPostalCode = warehouse.value.address.postalCode ?? "";
  consignment.packages = parcels.value.map((parcel) => new Package().fromParcel(parcel));

  shippingGuideRequest.consignments = [consignment];

  const product = new RequestProduct();
  product.id = bring.value?.bringShippingService.requestCode ?? "";
  product.customerNumber = bring.value?.bringServiceAgreement.customerNumber ?? "";

  consignment.products = [product];
  return shippingGuideRequest;
};

const freightPriceOverridden = ref(false);
const setShippingPriceFromBring = (bringResponse: BringShippingGuideResponse) => {
  if (shippingGuideResponse.value == null || bring.value == null) return null;
  if (shippingGuideResponseHasErrors.value) return null;

  const price =
    bringResponse.consignments?.[0]?.products?.[0]?.price?.listPrice?.priceWithAdditionalServices?.amountWithoutVAT;

  if (price == null || isNaN(parseFloat(price))) {
    toast.add({
      severity: "warn",
      summary: t("checkout.error"),
      detail: t("checkout.error-getting-shipping-guide"),
    });
  }

  const cost = parseFloat(price);
  if (!freightPriceOverridden.value) {
    pickingFreight.value.shippingPrice = cost;
  }
  pickingFreight.value.shippingCost = cost;
};

const getBringShippingGuide = async () => {
  if (bring.value == null) return;
  if (pickingFreight.value.freightWeight === 0) return;
  if (parcels.value.some((x) => x.length === 0 || x.height === 0 || x.width === 0)) return;
  fetchingShippingGuide.value = true;

  const shippingGuideRequest = createBringShippingGuideRequest();
  shippingGuideResponse.value = await bringService.getBringShippingGuide(shippingGuideRequest);

  if (shippingGuideResponse.value != null) {
    setShippingPriceFromBring(shippingGuideResponse.value);
  }
  fetchingShippingGuide.value = false;
};

const getLanguageFromLocale = (locale: string) => {
  //Read the locale and return the langueage code in format NO, EN, DK, SE
  switch (locale) {
    case "en-GB":
      return "EN";
    case "nb-NO":
      return "NO";
    default:
      return "NO";
  }
};

watch(
  () => props.freightMethodId,
  (id: string) => {
    if (id) {
      pickingFreight.value.freightMethodId = id;
    }
  },
);

const freightMethodRef = ref();
const focusFirstInput = () => {
  if (freightMethodRef.value) {
    freightMethodRef.value.focus();
  }
};

const visible = computed({
  get: () => {
    if (props.showDialog) {
      nextTick(focusFirstInput);
    }
    return props.showDialog;
  },
  set: (value) => emit("update:showDialog", value),
});

watch([selectedShippingLabelPrinter, selectedPackingNotePrinter], () => {
  if (selectedShippingLabelPrinter.value) {
    showSelectShippingPrinterError.value = false;
  }
  if (selectedPackingNotePrinter.value) {
    showSelectPackingNoteError.value = false;
  }
});

const onConfirm = async () => {
  if (!selectedShippingLabelPrinter.value) {
    showSelectShippingPrinterError.value = true;
  }

  if (!selectedPackingNotePrinter.value) {
    showSelectPackingNoteError.value = true;
  }
  val.value.$touch();
  await val.value.$validate();
  if (val.value.$error) {
    toast.add({
      severity: "warn",
      summary: t("toast.validation-error.summary"),
      detail: t("toast.validation-error.detail"),
    });
    return;
  }
  emit(
    "confirm",
    pickingFreight.value,
    bring.value,
    warehouse.value,
    parcels.value,
    selectedPickupPoint.value,
    selectedShippingLabelPrinter.value?.name ?? "",
    selectedPackingNotePrinter.value?.name ?? "",
  );
};

watchEffect(() => {
  if (props.agreedFreightPriceExVat != null) {
    pickingFreight.value.shippingPrice = props.agreedFreightPriceExVat;
    freightPriceOverridden.value = true;
  }
});

const warehouse = ref<Warehouse>(new Warehouse());

const getWarehouses = async () => {
  const warehouses = await getAllWarehouses();
  if (warehouses.length > 0) {
    warehouse.value = warehouses[0];
  }
};
const defaultShippingLabelPrinter = ref<DefaultPrinterInfo>();
const defaultPackingNotePrinter = ref<DefaultPrinterInfo>();
const fetchPrinters = async () => {
  try {
    loadingPrinters.value = true;
    printers.value = await getAllPrinters();
    defaultShippingLabelPrinter.value = await getDefaultPrinterByDocumentType(DocumentType.ShippingLabel);
    defaultPackingNotePrinter.value = await getDefaultPrinterByDocumentType(DocumentType.PackingNote);
  } catch (error) {
    if (error instanceof NotFoundError) {
      printixNotFound.value = true;
    }
  } finally {
    loadingPrinters.value = false;
  }
};

onMounted(async () => {
  await getWarehouses();
  await fetchPrinters();

  selectedShippingLabelPrinter.value = printers.value.find(
    (x) => x.name === defaultShippingLabelPrinter.value?.printerName,
  );
  selectedPackingNotePrinter.value = printers.value.find(
    (x) => x.name === defaultPackingNotePrinter.value?.printerName,
  );
});
</script>

<style lang="scss">
.c-checkout-dialog {
  @media screen and (min-width: 767px) {
    min-width: 55rem;
    max-width: 70rem;
  }
}
</style>
