<template>
  <PrimeDialog
    v-model:visible="visible"
    :header="t('inventory.export-header')"
    :modal="true"
    id="inventory-edit"
    :breakpoints="{ '999px': '90vw', '640px': '95vw' }"
    class="w-3/12"
  >
    <div class="formgrid grid grid-cols-12 gap-4">
      <div class="field col-span-12">
        <label for="inventory-export-warehouse">{{ t("inventory.warehouse") }}</label>
        <Select
          id="inventory-export-warehouse"
          v-model="selectedWarehouseId"
          :options="warehouses"
          optionLabel="name"
          optionValue="id"
          class="w-full"
          pt:list:data-testid="inventory-export-warehouse-list"
        />
      </div>
      <div class="field col-span-12">
        <label for="inventory-export-formats">{{ t("inventory.file-formats") }}</label>
        <Select
          id="inventory-export-formats"
          v-model="selectedFormat"
          :options="fileFormats"
          class="w-full"
          pt:list:data-testid="inventory-export-formats-list"
        />
      </div>
    </div>
    <template #footer>
      <ProgressSpinner class="w-16 h-16" v-if="loading" />
      <PrimeButton
        :label="t('inventory.export')"
        data-testid="btn-inventory-export-file"
        @click="exportInventoryProducts"
        v-else
      />
    </template>
  </PrimeDialog>
</template>

<script setup lang="ts">
import { Warehouse } from "@/repositories/warehouse/model/Warehouse";
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useInventory } from "@/api/inventory/InventoryService";

const props = defineProps<{
  visibleDialog: boolean;
  warehouses: Warehouse[];
}>();

const emit = defineEmits<{
  (e: "update:visibleDialog", value: boolean): void;
}>();

const { exportInventoryProductsToCsv, exportInventoryProductsToXlsx } = useInventory();

const { t } = useI18n();
const fileFormats = ref<string[]>(["Excel", "CSV"]);
const loading = ref<boolean>(false);
const selectedWarehouseId = ref<string>("");
const selectedFormat = ref<string>(fileFormats.value[0]);

const visible = computed<boolean>({
  get: () => props.visibleDialog,
  set: (value) => emit("update:visibleDialog", value),
});

watch(
  () => props.warehouses,
  (warehouses) => {
    if (warehouses.length > 0) {
      selectedWarehouseId.value = warehouses[0].id;
    }
  }
);

const exportInventoryProducts = async () => {
  loading.value = true;
  try {
    if (selectedFormat.value === "CSV") {
      await exportInventoryProductsToCsv(selectedWarehouseId.value);
    }
    if (selectedFormat.value === "Excel") {
      await exportInventoryProductsToXlsx(selectedWarehouseId.value);
    }
    visible.value = false;
  } finally {
    loading.value = false;
  }
};
</script>
