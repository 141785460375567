<template>
  <div class="c-page-content h-auto !overflow-hidden">
    <div class="grid grid-cols-12 my-5 ml-5">
      <div class="c-transition" :class="showSidebar && !isMobile ? 'col-span-9 2xl:col-span-10' : 'col-span-12'">
        <Card>
          <template #title>
            <InventoryChangesLogsHeader
              v-model:showSidebar="showSidebar"
              @navigateToInventoryClicked="navigateToInventorySearch"
              @refreshList="onRefreshList"
            />
          </template>

          <template #content>
            <div class="mx-4 my-0">
              <div class="flex items-left gap-2 mt-3">
                <FilterOverview v-model:filters="filtersComputed" />
              </div>

              <DataTable
                class="c-datatable"
                :value="inventoryChanges"
                dataKey="id"
                :autoLayout="true"
                responsiveLayout="scroll"
                selectionMode="single"
                :loading="loading"
                contextMenu
                data-testid="inventory-changes-log-list"
                stripedRows
                :sortField="sortField"
                :sortOrder="sortOrder"
                :rows="50"
                :paginator="true"
                :removableSort="true"
                :lazy="true"
                scrollable
                scrollHeight="75vh"
                :rowsPerPageOptions="[50, 100]"
                :totalRecords="totalHits"
                :currentPageReportTemplate="
                  t('common.current-page-template', {
                    first: '{first}',
                    last: '{last}',
                    totalRecords: '{totalRecords}',
                  })
                "
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                @page="onPage"
                @sort="onSort"
                @row-dblclick="openInventoryChangeLogDialog"
              >
                <Column field="changeNumber" :header="t('inventory.change-log.change-number')" sortable />
                <Column field="reason" :header="t('inventory.change-log.reason')" sortable />
                <Column field="costPrice" :header="t('inventory.change-log.cost-price')" sortable>
                  <template #body="{ data, field }">
                    {{ n(data[field as keyof typeof data], "decimal") }}
                  </template>
                </Column>
                <Column field="quantityChange" :header="t('inventory.change-log.quantity-change')" sortable>
                  <template #body="{ data, field }">
                    {{ n(data[field as keyof typeof data], "decimal") }}
                  </template>
                </Column>
                <Column field="totalChangeValue" :header="t('inventory.change-log.total-change-value')" sortable>
                  <template #body="{ data, field }">
                    {{ n(data[field as keyof typeof data], "decimal") }}
                  </template>
                </Column>
                <Column
                  field="changeDate"
                  :header="t('inventory.change-log.change-date')"
                  filterField="fromDate"
                  sortable
                >
                  <template #body="{ data, field }">
                    {{ d(data[field as keyof typeof data]) }}
                  </template>
                </Column>
                <template #empty>
                  <span>{{ t("inventory.change-log.no-inventory-changes-found") }}</span>
                </template>
              </DataTable>
            </div>
          </template>
        </Card>
      </div>

      <div v-if="isMobile">
        <Drawer v-model:visible="showSidebar" :header="t('inventory.search-filters.title')" position="right">
          <ChangeLogFilterSidebar :loading="loading" v-model:filters="filtersComputed" />
        </Drawer>
      </div>

      <div
        v-else
        class="transition-all duration-300 ease-in-out"
        :class="showSidebar ? 'col-span-3 2xl:col-span-2' : 'w-0 hidden'"
      >
        <Card class="ml-5 min-h-full">
          <template #content>
            <div class="c-filter-header-container">
              <i class="pi pi-sliders-h"></i>
              <span class="ml-4 font-semibold text-xl">{{ t("inventory.search-filters.title") }}</span>
            </div>

            <ChangeLogFilterSidebar :loading="loading" v-model:filters="filtersComputed" />
          </template>
        </Card>
      </div>
    </div>

    <InventoryChangeLogDialog
      v-if="visibleDialog"
      v-model:visible="visibleDialog"
      :inventoryChangeLogId="selectedInventoryChangeLogId"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useInventory } from "@/api/inventory/InventoryService";
import { InventoryChangeLog } from "@/models/inventory/InventoryChangeLog";
import InventoryChangeLogDialog from "../components/InventoryChangeLogDialog.vue";
import {
  type DataTablePageEvent,
  type DataTableRowDoubleClickEvent,
  type DataTableSortEvent,
} from "primevue/datatable";
import { GetInventoryChangeLogsRequest } from "@/models/inventory/GetInventoryChangeLogsRequest";
import { InventoryChangeLogFilters } from "@/models/inventory/search/InventoryChangeLogFilters";
import ChangeLogFilterSidebar from "../components/ChangeLogFilterSidebar.vue";
import InventoryChangesLogsHeader from "../components/InventoryChangesLogsHeader.vue";
import { useMobile } from "@/utils/MobileService";
import FilterOverview from "@/inventory/components/InventoryChangesFilterOverview.vue";

const loading = ref<boolean>(false);
const totalHits = ref<number>(0);
const page = ref<number>(1);
const pageSize = ref<number>(50);
const visibleDialog = ref<boolean>(false);
const sortField = ref<string>("");
const sortOrder = ref<number>(-1);
const inventoryChanges = ref<InventoryChangeLog[]>([]);
const selectedInventoryChangeLogId = ref<string>("");
const showSidebar = ref(false);
const filters = ref<InventoryChangeLogFilters>(new InventoryChangeLogFilters());

const { isMobile } = useMobile();
watch(isMobile, (value) => {
  if (value == true) showSidebar.value = false;
});

const { t, d, n } = useI18n();
const router = useRouter();
const { getInventoryChangeLogs } = useInventory();

const navigateToInventorySearch = () => {
  router.push({ name: "warehouse/inventory/search" });
};

const openInventoryChangeLogDialog = (event: DataTableRowDoubleClickEvent) => {
  selectedInventoryChangeLogId.value = event.data.id;
  visibleDialog.value = true;
};

const onPage = (event: DataTablePageEvent) => {
  page.value = event.page + 1;
  pageSize.value = event.rows;

  get();
};

const onSort = (event: DataTableSortEvent) => {
  if (typeof event.sortField === "string") {
    sortField.value = event.sortField;
    sortOrder.value = event.sortOrder === 1 ? 1 : -1;
    page.value = 1;
    get();
  }
};

const filtersComputed = computed<InventoryChangeLogFilters>({
  get: () => filters.value,
  set: async (value) => {
    await onFilterUpdate(value);
  },
});

const onFilterUpdate = async (value: InventoryChangeLogFilters) => {
  filters.value = value;
  loading.value = true;
  page.value = 1;
  await get();
};

const onRefreshList = async () => {
  loading.value = true;
  await get();
};

const get = async () => {
  try {
    loading.value = true;
    const request = new GetInventoryChangeLogsRequest(page.value, pageSize.value, filters.value, sortField.value);
    request.sortOrder = sortOrder.value === 1 ? "asc" : "desc";
    const repsonse = await getInventoryChangeLogs(request);

    inventoryChanges.value = repsonse.inventoryChangeLogs;
    totalHits.value = repsonse.totalHits;
  } finally {
    loading.value = false;
  }
};

onMounted(() => {
  sortField.value = "changeNumber";
  sortOrder.value = -1;
  get();
});
</script>

<style scoped lang="scss">
.c-inventory-changes-log-list {
  margin: var(--default-content-margin);
}

.c-transition {
  transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
  max-height: 90vh;
}
</style>
