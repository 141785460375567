import { ProductSearchFilters } from "./ProductSearchFilters";

export class ProductSearchRequest {
  query = "";
  page = 1;
  pageSize = 25;
  sortBy = "";
  sortOrder = "asc";
  filters = new ProductSearchFilters();
  includeAvailability = false;

  constructor(query: string) {
    this.query = query;
  }
}
