import { InventoryCountLine } from "./InventoryCountLine";
import { InventoryCountStatus } from "./InventoryCountStatus";

export class InventoryCountJob {
  id = "";
  warehouseId = "";
  name = "";
  inventoryCountNumber = 0;
  comment = "";
  lines: InventoryCountLine[] = [];
  status: InventoryCountStatus = InventoryCountStatus.New;
  registeredByEmployeeName = "";
  registeredByEmployeeEmail = "";
  updatedDateTime = "";
  createdDateTime = "";
  progressPercentage = 0;
}
