import { useAuth } from "@cumulus/event-bus";
import { searchApi } from "./SearchApi";
import { ProductSearchRequest } from "./model/product/ProductSearchRequest";
import { ProductSearchResponse } from "./model/product/ProductSearchResponse";
import { useErrorHandler } from "../ErrorHandler";
import { OrderReadyForPickingSearchRequest } from "./model/order/OrderReadyForPickingSearchRequest";
import { OrderReadyForPickingSearchResponse } from "./model/order/OrderReadyForPickingSearchResponse";

export function useSearch() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const productSearch = async (request: ProductSearchRequest): Promise<ProductSearchResponse> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await searchApi.productSearch(authHeaders, request);
    } catch (error) {
      await handleError(error);
      return new ProductSearchResponse();
    }
  };

  const orderReadyForPickingSearch = async (
    request: OrderReadyForPickingSearchRequest
  ): Promise<OrderReadyForPickingSearchResponse> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await searchApi.orderReadyForPickingSearch(authHeaders, request);
    } catch (error) {
      await handleError(error);
      return new OrderReadyForPickingSearchResponse();
    }
  };

  const getWebPubSubUrl = async (hubName: string): Promise<string> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await searchApi.getWebPubSubUrl(hubName, authHeaders);
    } catch (error) {
      await handleError(error);
      return "";
    }
  };

  return { productSearch, orderReadyForPickingSearch, getWebPubSubUrl };
}
