<template>
  <div>
    <span class="font-medium mr-1">{{ t(`checkout.order-number`) }}</span>
    <span data-testid="checkout-order-number">{{ orderNumber }}</span>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";

const { t } = useI18n();

defineProps<{
  orderNumber: number;
}>();
</script>
