<template>
  <div class="c-chips">
    <ul class="c-chips-multiple-container" v-if="hasActiveFilters">
      <li class="c-chips-token-clear-filter">
        <span>{{ t("inventory.change-log.clear-filters") }}</span>
        <span class="c-chips-token-icon pi pi-times-circle" @click="clearFilters" data-testid="clear-filter-btn">
        </span>
      </li>
      <InventoryChangesFilterItem
        v-if="filters.fromDate !== null"
        filterType="fromDate"
        :values="d(filters.fromDate)"
        @removeFilter="removeFilter"
      />
      <InventoryChangesFilterItem
        v-if="filters.toDate !== null"
        filterType="toDate"
        :values="d(filters.toDate)"
        @removeFilter="removeFilter"
      />
      <InventoryChangesFilterItem
        v-if="filters.reasons.length > 0"
        :values="filters.reasons"
        filterType="reasons"
        @removeFilter="removeFilter"
      />
    </ul>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import InventoryChangesFilterItem from "./InventoryChangesFilterItem.vue";
import { InventoryChangeLogFilters } from "@/models/inventory/search/InventoryChangeLogFilters";

const { t, d } = useI18n();

const filters = defineModel<InventoryChangeLogFilters>("filters", {
  required: true,
});

const clearFilters = () => {
  filters.value = new InventoryChangeLogFilters();
};

const removeFilter = (filterType: keyof InventoryChangeLogFilters | string, index: number) => {
  const copyFilters = { ...filters.value };
  const filterToRemove = copyFilters[filterType as keyof InventoryChangeLogFilters];

  if (Array.isArray(filterToRemove)) {
    filterToRemove.splice(index, 1);
  } else if (filterType === "fromDate" || filterType === "toDate") {
    copyFilters[filterType] = null;
  }

  filters.value = copyFilters;
};

const hasActiveFilters = computed<boolean>(() => {
  return filters.value.reasons.length > 0 || filters.value.fromDate !== null || filters.value.toDate !== null;
});
</script>
<style scoped lang="scss">
.c-chips {
  display: inline-flex;
}

.c-chips-multiple-container {
  padding: 0.25rem 0.5rem;
  margin: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.c-chips-token-clear-filter {
  padding: 0.25rem 0.5rem;
  margin-right: 0.5rem;
  color: var(--primary-color-text);
  border-radius: 1rem;
  background-color: var(--chips-bg-primary);
  display: inline-flex;
  align-items: center;
}

.c-chips-token-icon {
  margin-left: 0.5rem;
  cursor: pointer;
}
</style>
