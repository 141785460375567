<template>
  <label for="inventory-changed-cost-value" class="c-text-ellipsis mb-2">{{ labelComputed }}</label>
  <div id="inventory-changed-value" class="c-col-color">{{ changedCostValue }}</div>
</template>

<script setup lang="ts">
import { InventoryCostType } from "@/models/inventory/InventoryCostType";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  changedCostValue: number;
  costType: InventoryCostType;
}>();

const labelComputed = computed<string>(() => {
  return props.costType === InventoryCostType.TotalInventoryCost
    ? t("inventory.edit-cost.changed.new-inventory-value")
    : t("inventory.edit-cost.changed.changed-plus-minus");
});
</script>
<style scoped lang="scss">
.c-col-color {
  min-height: calc(2rem + 0.25rem);
  background-color: var(--list-row-bg);
  padding: 0.5rem 1rem;
  border-radius: 3px;
  text-align: right;
}
.c-text-ellipsis {
  display: block;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}
</style>
