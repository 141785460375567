<template>
  <div class="flex items-center -mt-2 pb-2 border-b">
    <div class="flex-1 text-color font-semibold leading-6">
      <Button class="c-circular-button mr-4" @click="onImportClicked" data-testid="btn-import">
        <i class="pi pi-plus c-success-button c-circular-icon"></i>
        <span class="">{{ t("inventory-count.import") }}</span>
      </Button>
    </div>
    <div class="ml-2">
      <Button
        data-testid="c-refresh-button"
        @click="(event: Event) => emit('onClickInventoryCountRefresh', event)"
        severity="secondary"
        variant="text"
        size="small"
        class="mr-2"
      >
        <i class="pi pi-refresh"></i>
      </Button>
      <Button
        data-testid="btn-column-chooser"
        @click="(event: Event) => emit('openColumnChooser', event)"
        severity="secondary"
        text
        :class="{ 'c-toolbar-button-active': props.isChooserVisible }"
      >
        <i class="pi pi-list"></i>
      </Button>
    </div>
  </div>
  <ImportInventoryCountJobDialog v-model:visibleDialog="visibleExportDialog" />
</template>
<script setup lang="ts">
import { ref } from "vue";
import ImportInventoryCountJobDialog from "./ImportInventoryCountJobDialog.vue";
import { useI18n } from "vue-i18n";

const visibleExportDialog = ref<boolean>(false);
const { t } = useI18n();

const props = defineProps<{
  isChooserVisible: boolean;
}>();

const emit = defineEmits<{
  (e: "onClickInventoryCountRefresh", event: Event): void;
  (e: "openColumnChooser", event: Event): void;
}>();

const onImportClicked = () => {
  visibleExportDialog.value = true;
};
</script>
