import { setupI18n } from "@cumulus/locale";
import { LocaleMessageDictionary, VueMessageType } from "vue-i18n";
import defaultCommonLocale from "./en.json";
import defaultInventoryCountLocale from "../inventory-count/locales/en.json";
import defaultPackingNoteLocale from "../goods-out/packing-note/locales/en.json";

async function loadMessagesAsync(locale: string): Promise<LocaleMessageDictionary<VueMessageType>> {
  const commonMessages = await import(`../locales/${locale}.json`);
  const inventoryCountMessages = await import(`../inventory-count/locales/${locale}.json`);
  const packingNoteMessages = await import(`../goods-out/packing-note/locales/${locale}.json`);

  const messages = { ...commonMessages.default, ...inventoryCountMessages.default, ...packingNoteMessages.default };
  return messages as LocaleMessageDictionary<VueMessageType>;
}

export const i18n = setupI18n(
  { ...defaultCommonLocale, ...defaultInventoryCountLocale, ...defaultPackingNoteLocale },
  loadMessagesAsync
);
