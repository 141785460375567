<template>
  <div class="flex items-center -mt-2 pb-2 border-b">
    <div class="flex-1 text-color font-semibold leading-6">
      <Button
        class="text-sm"
        variant="text"
        size="small"
        @click="emit('createPickingLists')"
        data-testid="btn-create-picking-lists"
      >
        <i class="pi pi-list c-success-button c-circular-icon"></i>
        <span class="px-4">{{ t("order-ready-for-picking.context-menu.create-picking-lists") }}</span>
      </Button>
    </div>

    <div class="ml-2">
      <Button
        data-testid="c-refresh-button"
        severity="secondary"
        variant="text"
        size="small"
        @click="emit('refreshList')"
      >
        <i class="pi pi-refresh"></i>
      </Button>
    </div>

    <div class="ml-2">
      <Button
        data-testid="filter-button"
        severity="secondary"
        variant="text"
        size="small"
        :class="{ 'c-toolbar-button-active': showSidebar }"
        @click="emit('onToggleFilterSidebar')"
      >
        <i class="pi pi-filter"></i>
      </Button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
const { t } = useI18n();

defineProps<{
  showSidebar: boolean;
}>();

const emit = defineEmits<{
  (e: "createPickingLists"): void;
  (e: "onToggleFilterSidebar"): void;
  (e: "refreshList"): void;
}>();
</script>
