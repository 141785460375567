<template>
  <div class="col-span-12 md:col-span-6 gap-12">
    <div class="flex gap-6">
      <div class="flex-1">
        <FloatLabel variant="on">
          <InputNumber
            id="inventory-transfer-in-stock"
            data-testId="inventory-transfer-in-stock"
            v-model="quantityOnStock"
            :disabled="true"
            class="inputfield"
            :min="0"
            :minFractionDigits="2"
            :fluid="true"
            v-tooltip.bottom="{
              value: t('placeholder.select', {
                property: t('inventory.edit-transfer.in-stock').toLowerCase(),
              }),
              showDelay: 1000,
              hideDelay: 300,
            }"
          />
          <label for="inventory-transfer-in-stock">{{ t(`inventory.edit-transfer.in-stock`) }}</label>
        </FloatLabel>
      </div>
      <div class="flex-1">
        <FloatLabel variant="on">
          <InputNumber
            id="inventory-transfer-in-picking"
            data-testId="inventory-transfer-in-picking"
            v-model="quantityInPicking"
            :disabled="true"
            class="inputfield"
            :min="0"
            :fluid="true"
            :minFractionDigits="2"
            v-tooltip.bottom="{
              value: t('placeholder.select', {
                property: t('inventory.edit-transfer.in-picking').toLowerCase(),
              }),
              showDelay: 1000,
              hideDelay: 300,
            }"
          />
          <label for="inventory-transfer-in-picking">{{ t(`inventory.edit-transfer.in-picking`) }}</label>
        </FloatLabel>
      </div>
    </div>
  </div>
  <div class="col-span-12 md:col-span-6">
    <div class="flex gap-6">
      <div class="flex-1">
        <FloatLabel variant="on">
          <InputNumber
            id="inventory-transfer-available-quanity"
            data-testId="inventory-transfer-available-quanity"
            v-model="quantityInStock"
            :disabled="true"
            class="inputfield"
            :min="0"
            :minFractionDigits="2"
            :fluid="true"
            v-tooltip.bottom="{
              value: t('placeholder.select', {
                property: t('inventory.edit-inventory.available-inventory').toLowerCase(),
              }),
              showDelay: 1000,
              hideDelay: 300,
            }"
          />
          <label for="inventory-transfer-available-quanity">{{
            t(`inventory.edit-inventory.available-inventory`)
          }}</label>
        </FloatLabel>
      </div>
      <div class="flex-1">
        <FloatLabel variant="on">
          <InputNumber
            id="inventory-transfer-quanity"
            data-testId="inventory-transfer-quanity"
            v-model="quantityToTransfer"
            class="inputfield w-full"
            mode="decimal"
            :fluid="true"
            :max="quantityInStock"
            :min="1"
            :invalid="val.$error"
            :disabled="quantityInStock === 0 || quantityInStock === undefined"
            :allowEmpty="false"
            v-tooltip.bottom="{
              value: t('placeholder.select', { property: t('inventory.edit-transfer.qty-transfer').toLowerCase() }),
              showDelay: 1000,
              hideDelay: 300,
            }"
          />
          <label for="inventory-transfer-quanity">{{ t(`inventory.edit-transfer.qty-transfer`) }}</label>
        </FloatLabel>

        <small
          class="p-error"
          v-if="val.$error"
          id="inventory-transfer-quanity"
          data-testid="inventory-transfer-quanity-error"
        >
          {{ val.$errors[0].$message }}
        </small>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { reactive } from "vue";
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";

const { t } = useI18n();

const quantityOnStock = defineModel<number>("quantityOnStock");
const quantityInPicking = defineModel<number>("quantityInPicking");
const quantityInStock = defineModel<number>("quantityInStock", { required: true, default: 0 });
const quantityToTransfer = defineModel<number>("quantityToTransfer", { required: true, default: 0 });

const state = reactive({
  quantityToTransfer,
});

const rules = {
  quantityToTransfer: {
    required: helpers.withMessage(t("validations.required"), required),
    valueCantBeZero: helpers.withMessage(t("common.error-detail.greater-than-zero"), (value: number) => value > 0),
  },
};

const val = useVuelidate(rules, state);
</script>
