<template>
  <div class="flex items-center -mt-2 pb-2 border-b">
    <div class="flex-1 text-color font-semibold leading-6">
      <Button
        class="text-sm"
        variant="text"
        size="small"
        @click="emit('openDetailedOverview')"
        data-testid="btn-detailed-overview"
      >
        <i class="pi pi-search-plus c-default-button c-circular-icon"></i>
        <span class="px-4">{{ t("inventory.header.detailed") }}</span>
      </Button>

      <Button
        severity="secondary"
        variant="text"
        size="small"
        @click="emit('editInventoryProductQuantity')"
        data-testid="btn-change-inventory"
      >
        <i class="pi pi-sliders-v c-default-button c-circular-icon"></i>
        <span class="px-4">{{ t("inventory.header.inventory") }}</span>
      </Button>

      <Button
        severity="secondary"
        variant="text"
        size="small"
        @click="emit('editInventoryProductCost')"
        data-testid="btn-change-cost"
      >
        <i class="pi pi-dollar c-default-button c-circular-icon"></i>
        <span class="px-4">{{ t("inventory.header.cost") }}</span>
      </Button>

      <Button
        severity="secondary"
        variant="text"
        size="small"
        data-testid="btn-inventory-export"
        @click="visibleExportDialog = true"
      >
        <i class="pi pi-file c-default-button c-circular-icon p-2"></i>
        <span class="px-4 m-auto">{{ t("inventory.export") }}</span>
      </Button>

      <Button
        severity="secondary"
        variant="text"
        size="small"
        data-testid="btn-inventory-changes-log"
        @click="emit('inventoryChangesLogBtnClicked')"
      >
        <i class="pi pi-file c-default-button c-circular-icon p-2"></i>
        <span class="px-4 m-auto">{{ t("inventory.change-log.inventory-changes-log") }}</span>
      </Button>
    </div>

    <div class="ml-2">
      <Button
        data-testid="c-refresh-button"
        severity="secondary"
        variant="text"
        size="small"
        @click="emit('refreshList')"
      >
        <i class="pi pi-refresh"></i>
      </Button>
    </div>

    <div class="ml-2">
      <Button
        data-testid="filter-button"
        severity="secondary"
        variant="text"
        size="small"
        :class="{ 'c-toolbar-button-active': showSidebar }"
        @click="emit('onToggleFilterSidebar')"
      >
        <i class="pi pi-filter"></i>
      </Button>
    </div>
  </div>

  <ExportInventoryProductsDialog v-model:visibleDialog="visibleExportDialog" :warehouses="warehouses" />
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";

import { Warehouse } from "@/repositories/warehouse/model/Warehouse";
import ExportInventoryProductsDialog from "../components/ExportInventoryProductsDialog.vue";

defineProps<{
  showSidebar: boolean;
  warehouses: Warehouse[];
}>();

const emit = defineEmits<{
  (e: "openDetailedOverview"): void;
  (e: "editInventoryProductQuantity"): void;
  (e: "editInventoryProductCost"): void;
  (e: "onToggleFilterSidebar"): void;
  (e: "inventoryChangesLogBtnClicked"): void;
  (e: "refreshList"): void;
}>();

const { t } = useI18n();

const visibleExportDialog = ref<boolean>(false);
</script>
