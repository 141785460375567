import { httpClient, AuthHeaders } from "@cumulus/http";
import { CustomerGroup } from "@/repositories/customer-group/model/CustomerGroup";

class CustomerGroupApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_ORDER != undefined && import.meta.env.VITE_APP_API_ORDER
        ? `${import.meta.env.VITE_APP_API_ORDER as string}/customer-groups`
        : `${import.meta.env.VITE_APP_API_URL as string}/customer-groups`;
  }

  public async getAll(authHeaders: AuthHeaders): Promise<CustomerGroup[]> {
    return await httpClient(this.uri, authHeaders)
      .get("")
      .then((response) => response.data);
  }
}

const customerGroupApi = new CustomerGroupApi();
export { customerGroupApi };
