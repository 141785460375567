<template>
  <Dialog
    v-model:visible="visible"
    :header="isEditing ? t('common.update') : t('common.add')"
    :modal="true"
    class="c-warehouse-dialog"
  >
    <ProgressSpinner v-if="isSaving" class="w-16 h-16" />

    <div class="grid grid-cols-12 gap-4 mb-8">
      <div class="col-span-8">
        <WarehouseName v-model:warehouseName="warehouse.name" />
      </div>
      <div class="col-span-4">
        <WarehouseShortName v-model:warehouseShortName="warehouse.shortName" />
      </div>
    </div>

    <div class="grid grid-cols-12 gap-4 mb-8">
      <div class="col-span-4">
        <WarehouseClient v-model:clientId="warehouse.clientId" />
      </div>
      <div class="col-span-4">
        <WarehouseCurrency v-model:currencyIso="warehouse.currencyIso" />
      </div>
      <div class="col-span-4">
        <WarehouseState v-model:warehouseState="warehouse.state" />
        <small v-if="showIsActiveError" data-testid="active-delete-error" class="p-error">{{
          t("delete.active-error")
        }}</small>
      </div>
    </div>

    <div class="mb-8">
      <Panel :header="t('warehouse.warehouse-address')" class="c-toggle-panel">
        <div class="grid grid-cols-12 gap-4">
          <div class="field col-span-12">
            <WarehouseCompanyName v-model:warehouseCompanyName="warehouse.address.companyName" />
          </div>
          <div class="field col-span-12">
            <WarehouseAddressLines v-model:warehouseAddress="warehouse.address.addressLines" />
          </div>
          <div class="field col-span-12">
            <WarehouseCountry v-model:countryIso="warehouse.address.countryIso" />
          </div>
          <div class="field col-span-12">
            <WarehousePostalCode
              v-model:warehousePostalCode="warehouse.address.postalCode"
              v-model:warehouseCity="warehouse.address.city"
            />
          </div>
          <div class="field col-span-12">
            <WarehouseCity v-model:warehouseCity="warehouse.address.city" />
          </div>
        </div>
      </Panel>
    </div>
    <div v-if="errorReason !== ''" class="p-error flex justify-center mt-2">{{ errorReason }}</div>
    <template #footer>
      <div class="w-full">
        <div class="flex justify-between items-center">
          <div class="">
            <div class="" v-if="isEditing">
              <Button
                :label="t('common.delete')"
                data-testid="delete-btn"
                @click="confirmDelete"
                :disabled="isSaving"
                severity="danger"
              />
              <ConfirmPopup data-testid="delete-confirm"></ConfirmPopup>
            </div>
          </div>
          <div class="">
            <Button :label="t(`common.cancel`)" data-testid="cancel-btn" @click="onCancel" />

            <Button
              :label="isEditing ? t('common.update') : t('common.add')"
              data-testid="btn-commit"
              @click="onCommit"
              severity="success"
              class="ml-2"
            />
          </div>
          <Dialog
            data-testid="unsaved-changes-dialog"
            v-model:visible="unsavedChangesDialogVisible"
            :header="t('common.unsaved-changes-header')"
            :style="{ width: '30vw' }"
            position="center"
            :modal="true"
          >
            <p class="p-6">
              {{ t("common.unsaved-changes-text") }}
            </p>
            <template #footer>
              <Button :label="t('common.cancel')" @click="unsavedChangesDialogVisible = false" text />
              <Button :label="t('common.ignore')" @click="visible = false" text />
              <Button :label="t('common.save')" icon="pi pi-check" @click="onCommit" />
            </template>
          </Dialog>
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";
import { useConfirm } from "primevue/useconfirm";
import WarehouseName from "./components/WarehouseName.vue";
import WarehouseShortName from "./components/WarehouseShortName.vue";
import WarehouseClient from "./components/WarehouseClient.vue";
import WarehouseCurrency from "./components/WarehouseCurrency.vue";
import WarehouseState from "./components/WarehouseState.vue";
import WarehouseCompanyName from "./components/WarehouseCompanyName.vue";
import WarehouseAddressLines from "./components/WarehouseAddressLines.vue";
import WarehousePostalCode from "./components/WarehousePostalCode.vue";
import WarehouseCity from "./components/WarehouseCity.vue";
import WarehouseCountry from "./components/WarehouseCountry.vue";
import { Warehouse } from "@/repositories/warehouse/model/Warehouse";
import { WarehouseStates } from "@/repositories/warehouse/model/WarehouseStates";
import { useWarehouse } from "@/repositories/warehouse/WarehouseService";
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";

const props = defineProps<{
  showDialog: boolean;
  warehouse: Warehouse;
}>();

const emit = defineEmits<{
  (e: "update:showDialog", value: boolean): void;
  (e: "onCreateWarehouse"): void;
  (e: "onUpdateWarehouse", value: Warehouse): void;
  (e: "onDeleteWarehouse"): void;
}>();

const { t } = useI18n();
const val = useVuelidate();
const toast = useCumulusToast(useToast());
const confirm = useConfirm();
const { createWarehouse, updateWarehouse, deleteWarehouse } = useWarehouse();
const warehouse = ref<Warehouse>(cloneDeep(props.warehouse));
const initialWarehouse = ref<Warehouse>(cloneDeep(warehouse.value));
const isEditing = ref(false);
const isSaving = ref(false);
const showIsActiveError = ref(false);
const errorReason = ref("");
const unsavedChangesDialogVisible = ref();

const previouslyFocusedInput = ref<HTMLInputElement | null>(null);
const visible = computed<boolean>({
  get: () => props.showDialog,
  set: (value) => emit("update:showDialog", value),
});

const confirmDelete = (event: Event) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t("delete.confirm-message"),
    icon: "pi pi-exclamation-triangle",
    accept: async () => {
      onDeleteWarehouse();
    },
  });
};

const onDeleteWarehouse = () => {
  if (warehouse.value.state === WarehouseStates.Active) {
    toast.add({
      severity: "warn",
      summary: t("toast.error.summary"),
      detail: t("toast.error.active-error"),
      closable: true,
    });
    showIsActiveError.value = true;
    return;
  }
  repositoryHandler("delete");
};

const onCommit = async () => {
  val.value.$touch();
  await val.value.$validate();

  if (val.value.$error) {
    toast.add({
      severity: "warn",
      summary: t("toast.validation-error.summary"),
      detail: t("toast.validation-error.detail"),
      closable: true,
    });
    return;
  }
  if (!isEditing.value) {
    repositoryHandler("create");
  } else if (isEditing.value) {
    repositoryHandler("update");
  }
};

const repositoryHandler = async (repo: string) => {
  try {
    isSaving.value = true;

    switch (repo) {
      case "create":
        await createWarehouse(warehouse.value);
        emit("onCreateWarehouse");
        visible.value = false;
        break;
      case "update":
        await updateWarehouse(warehouse.value);
        emit("onUpdateWarehouse", warehouse.value);
        visible.value = false;
        break;
      case "delete":
        await deleteWarehouse(warehouse.value.id);
        emit("onDeleteWarehouse");
        visible.value = false;
        break;
    }
  } catch {
    // Ignore
  } finally {
    isSaving.value = false;
  }
};

const onInit = () => {
  if (props.warehouse.id) {
    isEditing.value = true;
  }
};

onMounted(onInit);

const hasUpdates = computed(() => {
  return !isEqual(warehouse.value, initialWarehouse.value);
});
const onCancel = () => {
  previouslyFocusedInput.value = document.activeElement as HTMLInputElement;
  if (hasUpdates.value === true) {
    unsavedChangesDialogVisible.value = true;
  } else {
    visible.value = false;
  }
};

const handleKeydown = (event: KeyboardEvent) => {
  if (event.key === "Escape") {
    event.stopImmediatePropagation();
    if (unsavedChangesDialogVisible.value) {
      unsavedChangesDialogVisible.value = false;
      previouslyFocusedInput.value?.focus();
    } else onCancel();
  } else if (event.ctrlKey && event.key === "i" && unsavedChangesDialogVisible.value) {
    visible.value = false;
  }
};

onMounted(async () => {
  document.addEventListener("keydown", handleKeydown);
});
onBeforeUnmount(() => {
  document.removeEventListener("keydown", handleKeydown);
});
</script>

<style lang="scss">
.c-warehouse-dialog {
  width: 90vw;

  @media screen and (min-width: 992px) {
    width: 60vw;
    max-width: 45rem;
  }
}
</style>
