import { useAuth } from "@cumulus/event-bus";
import { customerGroupApi } from "@/repositories/customer-group/CustomerGroupApi";
import { CustomerGroup } from "@/repositories/customer-group/model/CustomerGroup";
import { useErrorHandler } from "@/repositories/ErrorHandler";

export function useCustomerGroups() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getCustomerGroups = async (): Promise<CustomerGroup[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await customerGroupApi.getAll(authHeaders);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return {
    getCustomerGroups,
  };
}
