import { useAuth } from "@cumulus/event-bus";
import { Warehouse } from "./model/Warehouse";
import { warehouseApi } from "./WarehouseApi";
import { useErrorHandler } from "../ErrorHandler";

export function useWarehouse() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getWarehouse = async (id: string): Promise<Warehouse> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await warehouseApi.get(authHeaders, id);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getAllWarehouses = async (): Promise<Warehouse[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await warehouseApi.getAll(authHeaders);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const createWarehouse = async (warehouse: Warehouse): Promise<void> => {
    try {
      const authHeaders = await getAuthHeaders();
      await warehouseApi.create(authHeaders, warehouse);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const updateWarehouse = async (warehouse: Warehouse): Promise<void> => {
    try {
      const authHeaders = await getAuthHeaders();
      await warehouseApi.update(authHeaders, warehouse);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const deleteWarehouse = async (id: string): Promise<void> => {
    try {
      const authHeaders = await getAuthHeaders();
      await warehouseApi.delete(authHeaders, id);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return { getWarehouse, getAllWarehouses, createWarehouse, updateWarehouse, deleteWarehouse };
}
