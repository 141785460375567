export class Parcel {
  constructor(parcelNumber: number) {
    this.parcelNumber = parcelNumber;
  }
  id = "";
  parcelNumber = 1;
  content = "";
  weight = 0;
  width = 0;
  length = 0;
  height = 0;
}
