import { inventoryApi } from "./InventoryApi";
import { useErrorHandler } from "@/inventory/product-hierarchy/api/ErrorHandler";
import { InventoryProductQuantity } from "@/models/inventory/InventoryProductQuantity";
import { InventoryProductCost } from "@/models/inventory/InventoryProductCost";
import { useAuth } from "@cumulus/event-bus";
import { InventoriedProductInfo } from "@/models/inventory/InventoriedProductInfo";
import { WarehouseProduct } from "@/models/inventory/WarehouseProduct";
import { Product } from "@/models/inventory/Product";
import { InventoryChangeLog } from "@/models/inventory/InventoryChangeLog";
import { GetInventoryChangeLogsRequest } from "@/models/inventory/GetInventoryChangeLogsRequest";
import { GetInventoryChangeLogsResponse } from "@/models/inventory/GetInventoryChangeLogsResponse";

export function useInventory() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getWarehouseProduct = async (warehouseId: string, productId: string): Promise<WarehouseProduct> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await inventoryApi.getWarehouseProduct(authHeaders, warehouseId, productId);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getProductWithBatches = async (warehouseId: string, productId: string): Promise<InventoriedProductInfo> => {
    if (!warehouseId) {
      throw "getProductWithBatches - warehouseId is missing";
    }

    if (!productId) {
      throw "getProductWithBatches - productId is missing";
    }

    try {
      const authHeaders = await getAuthHeaders();
      return await inventoryApi.getProductWithBatches(authHeaders, warehouseId, productId);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const setNewInventoryLevel = async (inventoryProductQuantity: InventoryProductQuantity): Promise<void> => {
    const authHeaders = await getAuthHeaders();
    await inventoryApi.setNewInventoryLevel(authHeaders, inventoryProductQuantity);
  };

  const modifyCostPrice = async (inventoryProductCost: InventoryProductCost): Promise<void> => {
    try {
      const authHeaders = await getAuthHeaders();
      await inventoryApi.modifyCostPrice(authHeaders, inventoryProductCost);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const exportInventoryProductsToCsv = async (warehouseId: string): Promise<void> => {
    try {
      const authHeaders = await getAuthHeaders();
      await inventoryApi.exportInventoryProductsToCsv(authHeaders, warehouseId);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const exportInventoryProductsToXlsx = async (warehouseId: string): Promise<void> => {
    try {
      const authHeaders = await getAuthHeaders();
      await inventoryApi.exportInventoryProductsToXlsx(authHeaders, warehouseId);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getProductByProductNumber = async (productNumber: string): Promise<Product | null> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await inventoryApi.getProductByProductNumber(authHeaders, productNumber);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getInventoryChangeLogs = async (
    request: GetInventoryChangeLogsRequest
  ): Promise<GetInventoryChangeLogsResponse> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await inventoryApi.getInventoryChangeLogs(authHeaders, request);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getInventoryChangeLogById = async (id: string): Promise<InventoryChangeLog> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await inventoryApi.getInventoryChangeLogById(authHeaders, id);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return {
    getProductWithBatches,
    setNewInventoryLevel,
    modifyCostPrice,
    exportInventoryProductsToCsv,
    exportInventoryProductsToXlsx,
    getProductByProductNumber,
    getWarehouseProduct,
    getInventoryChangeLogs,
    getInventoryChangeLogById,
  };
}
