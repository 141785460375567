import { InventoryCountStatus } from "./InventoryCountStatus";

export class InventoryCountJobInfo {
  id = "";
  inventoryCountNumber = 0;
  status = InventoryCountStatus.New;
  numberOfLines = 0;
  registeredByEmployeeName = "";
  createdDateTime = "";
  updatedDateTime = "";
  progressPercentage = 0;
}
