export const OpenPickingListColumn = {
  ShippingDate: "shippingDate",
  PickingListNumber: "pickingListNumber",
  State: "state",
  AssignedEmployeeInitials: "assignedEmployeeInitials",
  PickingStarted: "pickingStarted",
  OrderNumber: "orderNumber",
  FreightMethodName: "freightMethodName",
  CustomerNumber: "customerNumber",
  CustomerName: "customerName",
  OrderReference: "orderReference",
  QuantityOfOrderItems: "quantityOfOrderItems",
  SumTotalLines: "sumTotalLines",
  PDF: "PDF",
} as const;
