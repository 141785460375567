<template>
  <FloatLabel variant="on">
    <InputText
      data-testid="warehouse-city"
      :model-value="warehouseCity"
      @input="$emit('update:warehouseCity', ($event.target as HTMLInputElement).value)"
      :class="{ 'p-invalid': validate.warehouseCity.$error }"
      class="inputfield w-full"
      v-tooltip.focus.bottom="{
        value: t('placeholder.type', { property: t('common.address.city').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
    />
    <label class="w-full" for="warehouseCity">{{ t("common.address.city") }}</label>
  </FloatLabel>
  <small class="p-error" v-if="validate.warehouseCity.$error" data-testid="warehouse-city-error">
    {{ validate.warehouseCity.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";

const { t } = useI18n();

const props = defineProps<{
  warehouseCity: string;
}>();

const rules = {
  warehouseCity: {
    required,
  },
};

const validate = useVuelidate(rules, props);
</script>
