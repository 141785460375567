<template>
  <label for="inventory-product-hierarchy" class="c-text-ellipsis mb-2">{{ t("inventory.productgroup") }}</label>
  <div id="inventory-product-hierarchy" data-testid="inventory-product-hierarchy" class="c-col-color">
    {{ productHierarchy }}
    <i class="pi pi-spin pi-spinner" style="font-size: 1rem" v-if="loading"></i>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useAuth } from "@cumulus/event-bus";
import { useProductHierarchyStore } from "../api/ProductHierarchyStore";
import { LanguageIsoType } from "../model/LanguageIsoType";
import { LanguageText } from "../model/LanguageText";

const { t } = useI18n();

const props = defineProps<{
  productHierarchyId: string;
}>();

const productHierarchyStore = useProductHierarchyStore();
const { loading } = storeToRefs(productHierarchyStore);

const { getUser } = useAuth();
const languageIso = ref<LanguageIsoType>(LanguageIsoType.English);
const productHierarchy = ref("");

onMounted(async () => {
  languageIso.value =
    ((await (await getUser()).getEmployee()?.languageCode) as LanguageIsoType) ?? LanguageIsoType.English;
});

watch(
  () => props.productHierarchyId,
  async () => {
    const result = await productHierarchyStore.getProductHierarchyById(props.productHierarchyId);
    productHierarchy.value =
      result === null
        ? ""
        : [
            result?.level1?.names.find((name: LanguageText) => name.languageIso === languageIso.value)?.text ?? "",
            result?.level2?.names.find((name: LanguageText) => name.languageIso === languageIso.value)?.text ?? "",
            result?.level3?.names.find((name: LanguageText) => name.languageIso === languageIso.value)?.text ?? "",
            result?.level4?.names.find((name: LanguageText) => name.languageIso === languageIso.value)?.text ?? "",
            result?.level5?.names.find((name: LanguageText) => name.languageIso === languageIso.value)?.text ?? "",
          ]
            .filter((name) => name != "")
            .join(" | ");
  },
);
</script>
<style scoped lang="scss">
.c-col-color {
  min-height: calc(2rem + 0.25rem);
  flex-grow: 1;
  background-color: var(--list-row-bg);
  padding: 0.5rem 1rem;
  border-radius: 3px;
}
.c-text-ellipsis {
  display: block;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
