export enum LanguageIsoType {
  Norwegian = "NO",
  English = "EN",
  Danish = "DK",
  Swedish = "SE",
  Finnish = "FI",
  German = "DE",
  French = "FR",
  Spanish = "ES",
  Italian = "IT",
}
