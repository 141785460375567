<template>
  <SearchHeader />
  <div class="c-inventory-count-job-search-result">
    <div class="flex">
      <div class="flex-auto">
        <Card>
          <template #content>
            <Suspense>
              <SearchInventoryCountJobsList
                :inventoryCountJobs="inventoryCountJobs"
                :loading="loading"
                :totalHits="totalHits"
                :pageSize="pageSize"
                :page="page"
                :sortOrder="sortOrder"
                :sortField="sortField"
                @rowDblClicked="routeToInventoryCountJob"
                @refreshList="onReloadClicked"
                @update:sortOrder="onUpdateSortOrder"
                @update:sortField="onUpdateSortField"
                @update:page="onUpdatePage"
                @update:pageSize="onUpdatePageSize"
              />
            </Suspense>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount } from "vue";
import SearchInventoryCountJobsList from "../components/SearchInventoryCountJobsList.vue";
import { useInventoryCountService } from "../api/InventoryCountService";
import { InventoryCountJobInfo } from "../model/InventoryCountJobInfo";
import SearchHeader from "../components/SearchHeader.vue";
import { useCumulusToast } from "@cumulus/toast";
import { useToast } from "primevue/usetoast";
import { InventoryCountJobWebPubSubMessage } from "../model/InventoryCountJobWebPubSubMessage";
import { InventoryCountStatus } from "../model/InventoryCountStatus";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const { getAll, getWebPubSubUrl } = useInventoryCountService();

const inventoryCountJobs = ref<InventoryCountJobInfo[]>([]);
const loading = ref(false);
const totalHits = ref(0);
const pageSize = ref<number>(50);
const page = ref(1);
const sortField = ref("");
const sortOrder = ref(-1);
const toast = useCumulusToast(useToast());
const webSocket = ref<WebSocket | null>();
const router = useRouter();
const { t } = useI18n();

const getInventoryCountJobs = async () => {
  try {
    loading.value = true;
    if (sortField.value === "") {
      sortOrder.value = -1;
    }
    inventoryCountJobs.value = await getAll();
    totalHits.value = inventoryCountJobs.value.length;
  } finally {
    loading.value = false;
  }
};

const getPubSubUrl = async () => {
  const url: string = await getWebPubSubUrl();

  if (!url) {
    handleWebSocketError();
    return;
  }

  initializeWebSocket(url);
};

const initializeWebSocket = (url: string) => {
  webSocket.value = new WebSocket(url);
  webSocket.value.onmessage = handleWebSocketMessage;
  webSocket.value.onerror = handleWebSocketError;
};

const handleWebSocketError = () => {
  toast.add({
    severity: "warn",
    summary: t("inventory-count.websocket-error"),
    detail: t("inventory-count.websocket-error-details"),
    life: 5000,
  });
};

const handleWebSocketMessage = async (event: MessageEvent) => {
  const pubSubMessage = JSON.parse(event.data) as InventoryCountJobWebPubSubMessage;

  if (pubSubMessage.status === InventoryCountStatus.InProgress) {
    const job = inventoryCountJobs.value.find((job) => job.id === pubSubMessage.id);
    if (job) {
      job.status = InventoryCountStatus.InProgress;
      job.progressPercentage = pubSubMessage.progressPercentage;
    }
  } else {
    setTimeout(async () => await getInventoryCountJobs(), 500);
  }

  if (pubSubMessage.status === InventoryCountStatus.Ready) {
    toast.add({
      severity: "success",
      summary: t("inventory-count.import-ready"),
      detail: t("inventory-count.import-ready-details"),
    });
  }

  if (pubSubMessage.status === InventoryCountStatus.Failed) {
    toast.add({
      severity: "error",
      summary: t("inventory-count.import-failed"),
      detail: t("inventory-count.import-failed-details"),
    });
  }
};

const onReloadClicked = async () => {
  await getInventoryCountJobs();
};

const routeToInventoryCountJob = (inventoryCountJob: InventoryCountJobInfo) => {
  const id = inventoryCountJob.id;
  router.push({ name: "inventory-count-job", params: { id } });
};

const onUpdateSortOrder = (value: number) => {
  sortOrder.value = value;
  loading.value = true;
};

const onUpdateSortField = (value: string) => {
  sortField.value = value;
  loading.value = true;
};

onMounted(async () => {
  await getInventoryCountJobs();
  await getPubSubUrl();
});

const onUpdatePage = async (value: number) => {
  page.value = value;
  loading.value = true;
  await getInventoryCountJobs();
};

const onUpdatePageSize = async (value: number) => {
  pageSize.value = value;
  loading.value = true;
  await getInventoryCountJobs();
};

onBeforeUnmount(() => {
  if (webSocket.value) {
    webSocket.value?.close();
  }
});
</script>

<style scoped lang="scss">
.c-inventory-count-job-search-result {
  margin: var(--default-content-margin);
  @media (min-width: 992px) {
    margin-bottom: 2rem;
  }
}
</style>
