<template>
  <div>
    <Dialog
      v-model:visible="visible"
      :header="t('inventory.edit-transfer.header', { inventoryProduct: inventoriedProductInfo.productName })"
      :modal="true"
      id="inventory-transfer-edit"
      :breakpoints="{ '999px': '90vw', '640px': '95vw' }"
      :closeOnEscape="false"
      :closable="false"
      :style="{ height: '400px', width: '700px' }"
      :pt:content:class="'p-7 min-h-fit'"
    >
      <div v-if="loading" class="c-loading-overlay">
        <ProgressSpinner class="w-16 h-16" />
      </div>
      <div v-if="loadFailed">
        {{ t("inventory.load-failed-inventoried-product") }}
      </div>
      <div v-else>
        <div class="grid grid-cols-12 gap-12 mb-6">
          <div class="col-span-12 md:col-span-6">
            <FloatLabel variant="on">
              <Select
                v-model="currentWarehouse"
                :options="filteredWarehouses"
                optionLabel="name"
                id="transfer-from-warehouse"
                data-testid="transfer-from-warehouse"
                class="w-full"
              />
              <label for="transfer-from-warehouse">{{ t("inventory.edit-transfer.from-warehouse") }}</label>
            </FloatLabel>
          </div>
          <div class="col-span-12 md:col-span-6">
            <FloatLabel class="w-full" variant="on">
              <Select
                v-model="destinationWarehouse"
                :options="props.warehouses"
                optionLabel="name"
                class="w-full"
                id="transfer-to-warehouse"
                data-testid="transfer-to-warehouse"
              />
              <label for="transfer-to-warehouse">{{ t("inventory.edit-transfer.to-warehouse") }}</label>
            </FloatLabel>
          </div>
        </div>
        <div class="grid grid-cols-12 gap-12 mb-6">
          <TransferQuantity
            v-model:quantityToTransfer="inventoryProductTransfer.quantityToTransfer"
            :quantityInStock="inventoryProductTransfer.sourceWarehouseQuantity"
            :quantityOnStock="inventoriedProductInfo.quantityInStock"
            :quantityInPicking="inventoriedProductInfo.quantityAllocatedForPicking"
          />
        </div>

        <div class="items-end mb-6 w-full">
          <div class="col-span-12 md:col-span-8 lg:col-span-6 px-0">
            <TransferChangeReason
              :reason="inventoryProductTransfer.reason"
              :quantityInStock="inventoryProductTransfer.sourceWarehouseQuantity"
            />
          </div>
        </div>
        <div class="col-span-12 md:col-span-12 mb-5">
          <CommentTransfer
            v-model:comment="inventoryProductTransfer.comment"
            :quantityInStock="inventoryProductTransfer.sourceWarehouseQuantity"
          />
        </div>
      </div>
      <template #footer>
        <div class="flex mr-2">
          <Button
            :label="t('common.save')"
            data-testid="btn-save-inventory"
            @click="onSave"
            class="c-dialog-default-button c-dialog-success-button mr-6"
            :disabled="
              saving ||
              inventoryProductTransfer.sourceWarehouseQuantity === 0 ||
              inventoryProductTransfer.sourceWarehouseQuantity === undefined
            "
          />
          <Button
            :label="t(`common.cancel`)"
            data-testid="cancel-btn"
            @click="onCancel"
            severity="secondary"
            variant="outlined"
            :disabled="saving"
          />
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import useValidate from "@vuelidate/core";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";

import { useInventory } from "@/api/inventory/InventoryService";
import TransferQuantity from "../TransferQuantity.vue";
import TransferChangeReason from "../transfer-edit/TransferChangeReason.vue";
import CommentTransfer from "../CommentTransfer.vue";
import { InventoriedProductInfo } from "@/models/inventory/InventoriedProductInfo";
import cloneDeep from "lodash.clonedeep";
import { StockTransferRequest } from "@/models/inventory/InventoryProductTransfer";
import { Warehouse } from "@/repositories/warehouse/model/Warehouse";

const props = defineProps<{
  showTransferInventoryDialog: boolean;
  productId: string;
  warehouseId: string;
  warehouses: Warehouse[];
}>();

const emit = defineEmits<{
  (e: "update:showTransferInventoryDialog", value: boolean): void;
  (e: "update:warehouseId", value: string): void;
  (e: "inventoryRefresh"): void;
}>();

const { t } = useI18n();
const { getProductWithBatches, transferProductToNewWarehouse } = useInventory();
const val = useValidate();
const toast = useCumulusToast(useToast());
const saving = ref(false);
const loading = ref(false);
const loadFailed = ref(false);

const previouslyFocusedInput = ref<HTMLInputElement | null>(null);

const inventoriedProductInfo = ref<InventoriedProductInfo>(new InventoriedProductInfo());
const inventoryProductTransfer = ref<StockTransferRequest>(new StockTransferRequest());

const initialInventoryProductTransfer = ref<StockTransferRequest>(new StockTransferRequest());

const visible = computed<boolean>({
  get: () => {
    return props.showTransferInventoryDialog;
  },
  set: (value) => {
    emit("update:showTransferInventoryDialog", value);
  },
});
const currentWarehouse = ref<Warehouse>();
const destinationWarehouse = ref<Warehouse>();

watch(currentWarehouse, async (newValue) => {
  if (newValue) {
    getProductWithBatches(newValue.id, props.productId).then((product) => {
      inventoriedProductInfo.value = product;
      inventoryProductTransfer.value.sourceWarehouseQuantity = product.quantityAvailableFromStock;
    });
    if (newValue.id === destinationWarehouse.value?.id) {
      const differentWarehouse = props.warehouses.find((warehouse) => warehouse.id !== newValue.id);
      destinationWarehouse.value = differentWarehouse;
    }
  }
});

watch(destinationWarehouse, (newValue) => {
  if (newValue) {
    if (newValue.id === currentWarehouse.value?.id) {
      const differentWarehouse = props.warehouses.find((warehouse) => warehouse.id !== newValue.id);
      currentWarehouse.value = differentWarehouse;
    }
  }
});

const onSave = async () => {
  val.value.$touch();
  await val.value.$validate();

  if (val.value.$error) {
    toast.add({
      severity: "warn",
      summary: t("toast.validation-error.summary"),
      detail: t("toast.validation-error.detail"),
    });
    return;
  }

  saving.value = true;

  try {
    inventoryProductTransfer.value.destinationWarehouseId = destinationWarehouse.value
      ? destinationWarehouse.value.id
      : "";
    if (currentWarehouse.value) {
      await transferProductToNewWarehouse(inventoryProductTransfer.value, currentWarehouse.value.id, props.productId);
    }
    toast.add({
      severity: "success",
      summary: t("inventory.edit-inventory.add-success"),
      closable: true,
    });
    emit("inventoryRefresh");
    visible.value = false;
  } finally {
    saving.value = false;
  }
};

const fetchProductWithBatches = async () => {
  if (!props.warehouseId || !props.productId) return;

  loading.value = true;
  try {
    inventoriedProductInfo.value = await getProductWithBatches(currentWarehouse.value?.id || "", props.productId);
  } catch (e) {
    loadFailed.value = true;
  } finally {
    loading.value = false;
  }
};
const filteredWarehouses = ref<Warehouse[]>([]);
const isProductLinkedToWarehouse = async (warehouseId: string) => {
  try {
    const product = await getProductWithBatches(warehouseId, props.productId);
    return product && product.quantityAvailableFromStock !== undefined;
  } catch (e) {
    return false;
  }
};

const filterWarehouses = async () => {
  loading.value = true;
  try {
    const results = await Promise.all(
      props.warehouses.map(async (warehouse) => {
        const isConnected = await isProductLinkedToWarehouse(warehouse.id);
        return isConnected ? warehouse : null;
      }),
    );
    filteredWarehouses.value = results.filter((warehouse): warehouse is Warehouse => warehouse !== null);
  } catch (e) {
    loadFailed.value = true;
  } finally {
    loading.value = false;
  }
};

onMounted(async () => {
  currentWarehouse.value = props.warehouses[0];
  destinationWarehouse.value = props.warehouses[1];

  await fetchProductWithBatches();
  await filterWarehouses();
  inventoryProductTransfer.value.destinationWarehouseId = inventoriedProductInfo.value.warehouseId;
  inventoryProductTransfer.value.sourceWarehouseQuantity = inventoriedProductInfo.value.quantityAvailableFromStock;

  initialInventoryProductTransfer.value = cloneDeep(inventoryProductTransfer.value);
});

const onCancel = () => {
  previouslyFocusedInput.value = document.activeElement as HTMLInputElement;
  visible.value = false;
};

const handleKeydown = (event: KeyboardEvent) => {
  if (event.key === "Escape") {
    event.stopImmediatePropagation();
    onCancel();
  } else if (event.ctrlKey && event.key === "i") {
    visible.value = false;
  }
};

onMounted(async () => {
  document.addEventListener("keydown", handleKeydown);
});
onBeforeUnmount(() => {
  document.removeEventListener("keydown", handleKeydown);
});
</script>
