export const PackingNoteListColumn = {
  CreatedDateUtc: "createdDateUtc",
  PackingNoteNumber: "packingNoteNumber",
  OrderNumber: "orderNumber",
  FreightMethodName: "freightMethodName",
  CustomerNumber: "customerNumber",
  InvoiceName: "invoiceName",
  NumberOfLines: "numberOfLines",
  Pdf: "pdf",
} as const;
