<template>
  <div>
    <PrimeDialog
      v-model:visible="visible"
      :header="t('inventory.edit-cost.header', { inventoryProduct: inventoriedProductInfo.productName })"
      :modal="true"
      id="inventory-cost-edit"
      :breakpoints="{ '999px': '90vw', '640px': '95vw' }"
      :closeOnEscape="false"
      :closable="false"
    >
      <div v-if="loading" class="c-loading-overlay">
        <ProgressSpinner class="w-16 h-16" />
      </div>
      <div v-if="loadFailed">
        {{ t("inventory.load-failed-inventoried-product") }}
      </div>
      <div v-else>
        <div class="grid grid-cols-12 gap-4 mb-4">
          <div class="col-span-12 md:col-span-8">
            <ValueWithHeader
              alignText="left"
              id="inventory-product-number"
              :label="t('inventory.productnumber')"
              :value="inventoriedProductInfo.productNumber"
            />
          </div>
          <div class="col-span-12 md:col-span-4">
            <ValueWithHeader
              alignText="left"
              id="inventory-gti-number"
              :label="t('inventory.ean')"
              :value="inventoriedProductInfo.gtin"
            />
          </div>
          <div class="col-span-12 md:col-span-12">
            <ValueWithHeader
              alignText="left"
              id="inventory-product-name"
              :label="t('inventory.productname')"
              :value="inventoriedProductInfo.productName"
            />
          </div>
        </div>

        <Card class="mb-4">
          <template #content>
            <div class="grid grid-cols-12 gap-4">
              <div class="col-span-12 md:col-span-3 md:mt-10">
                <CostType
                  v-model:costValue="inventoryProductCost.costType"
                  :costType="InventoryCostType.TotalInventoryCost"
                  :disabled="true"
                  :uc="true"
                />
              </div>
              <div class="col-span-12 md:col-span-3 text-center">
                <ValueWithHeader
                  alignText="right"
                  id="inventory-total-cost-price"
                  :label="t(`inventory.edit-cost.inventory-cost`)"
                  :value="inventoriedProductInfo.totalInventoryCost"
                />
              </div>
              <div class="col-span-12 md:col-span-3 text-center">
                <InventoryCostValue
                  v-model:costValue="inventoryCost"
                  :disabled="inventoryProductCost.costType === InventoryCostType.TotalInventoryCost ? false : true"
                />
              </div>
              <div class="col-span-12 md:col-span-3 text-center">
                <ChangedCostValue
                  :changedCostValue="inventoriedProductInfo.totalInventoryCost + inventoryCost"
                  :costType="InventoryCostType.TotalInventoryCost"
                />
              </div>
              <div class="col-span-12 md:col-span-3 md:mt-10">
                <CostType
                  v-model:costValue="inventoryProductCost.costType"
                  :costType="InventoryCostType.UnitCost"
                  :disabled="false"
                  :uc="false"
                />
              </div>
              <div class="col-span-12 md:col-span-3 text-center">
                <ValueWithHeader
                  alignText="right"
                  id="inventory-cost-price"
                  :label="t(`inventory.edit-cost.unit-cost`)"
                  :value="inventoriedProductInfo.costPrice"
                />
              </div>
              <div class="col-span-12 md:col-span-3 text-center">
                <UnitCostValue
                  v-model:costValue="unitCost"
                  :inventoryProductUnitCost="inventoriedProductInfo.costPrice"
                  :disabled="inventoryProductCost.costType === InventoryCostType.UnitCost ? false : true"
                />
              </div>
              <div class="col-span-12 md:col-span-3 text-center">
                <ChangedCostValue :changedCostValue="costPriceChangeComputed" :costType="InventoryCostType.UnitCost" />
              </div>
            </div>
          </template>
        </Card>

        <div class="flex flex-wrap justify-between items-end items-center mb-4">
          <div class="col-span-12 md:col-span-8 lg:col-span-6 px-0">
            <CostChangeReason v-model:reason="inventoryProductCost.reason" />
          </div>
          <div class="formgroup-inline mt-4">
            <div class="field-checkbox">
              <label for="inventory-print" class="c-under-construction">{{
                t("inventory.edit-inventory.print")
              }}</label>
              <Checkbox id="inventory-print" class="ml-4" :binary="true" v-model="printChangedInventory" />
            </div>
          </div>
        </div>
        <div class="col-span-12 md:col-span-12 mb-8">
          <Comment v-model:comment="inventoryProductCost.comment" />
        </div>
      </div>
      <template #footer>
        <div class="flex">
          <PrimeButton
            class="c-dialog-default-button mr-6"
            :label="t(`common.cancel`)"
            data-testid="cancel-btn"
            @click="onCancel"
            :disabled="saving"
            text
          />
          <div v-tooltip.bottom="saveButtonTooltip">
            <PrimeButton
              :label="t('common.save')"
              data-testid="btn-save-inventory"
              @click="onSave"
              class="c-dialog-default-button c-dialog-success-button"
              :disabled="saving || !hasUpdates"
            />
          </div>
        </div>
      </template>
    </PrimeDialog>
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import useValidate from "@vuelidate/core";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";

import { useInventory } from "@/api/inventory/InventoryService";
import { InventoryProductCost } from "@/models/inventory/InventoryProductCost";
import { InventoryCostType } from "@/models/inventory/InventoryCostType";

import CostChangeReason from "../components/cost-edit/CostChangeReason.vue";
import Comment from "../components/Comment.vue";
import CostType from "../components/cost-edit/CostType.vue";
import ChangedCostValue from "../components/cost-edit/ChangedCostValue.vue";
import InventoryCostValue from "../components/cost-edit/InventoryCostValue.vue";
import UnitCostValue from "../components/cost-edit/UnitCostValue.vue";
import ValueWithHeader from "../components/overview/ValueWithHeader.vue";
import { InventoriedProductInfo } from "@/models/inventory/InventoriedProductInfo";
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";

const props = defineProps<{
  showEditCostDialog: boolean;
  productId: string;
  warehouseId: string;
}>();

const emit = defineEmits<{
  (e: "update:showEditCostDialog", value: boolean): void;
}>();

const { t } = useI18n();
const { modifyCostPrice, getProductWithBatches } = useInventory();
const printChangedInventory = ref(true);
const val = useValidate();
const toast = useCumulusToast(useToast());
const saving = ref(false);
const loading = ref(false);
const loadFailed = ref(false);
const costPriceChangeComputed = computed<number>(() => unitCost.value - inventoriedProductInfo.value.costPrice);

const previouslyFocusedInput = ref<HTMLInputElement | null>(null);

const inventoriedProductInfo = ref<InventoriedProductInfo>(new InventoriedProductInfo());
const inventoryProductCost = ref<InventoryProductCost>(new InventoryProductCost());

const initialInventoryProductCost = ref<InventoryProductCost>(new InventoryProductCost());
const inventoryCost = ref(0);

const initialUnitCost = ref(0);
const unitCost = ref(0);

const visible = computed<boolean>({
  get: () => {
    return props.showEditCostDialog;
  },
  set: (value) => {
    emit("update:showEditCostDialog", value);
  },
});

const onSave = async () => {
  val.value.$touch();
  await val.value.$validate();

  if (val.value.$error) {
    toast.add({
      severity: "warn",
      summary: t("toast.validation-error.summary"),
      detail: t("toast.validation-error.detail"),
    });
    return;
  }

  saving.value = true;

  try {
    let successMessage = "";
    if (inventoryProductCost.value.costType === InventoryCostType.TotalInventoryCost) {
      inventoryProductCost.value.costPriceChange = inventoryCost.value;
      successMessage = t("inventory.edit-cost.add-success-inventory");
    } else {
      inventoryProductCost.value.costPriceChange = costPriceChangeComputed.value;
      successMessage = t("inventory.edit-cost.add-success-unit");
    }

    await modifyCostPrice(inventoryProductCost.value);
    toast.add({
      severity: "success",
      summary: successMessage,
    });
    visible.value = false;
  } finally {
    saving.value = false;
  }
};

const fetchProductWithBatches = async () => {
  if (!props.warehouseId || !props.productId) return;

  loading.value = true;
  try {
    inventoriedProductInfo.value = await getProductWithBatches(props.warehouseId, props.productId);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    loadFailed.value = true;
  } finally {
    loading.value = false;
  }
};

onMounted(async () => {
  await fetchProductWithBatches();

  unitCost.value = inventoriedProductInfo.value.costPrice;
  initialUnitCost.value = cloneDeep(unitCost.value);
  inventoryProductCost.value.productId = inventoriedProductInfo.value.productId;
  inventoryProductCost.value.warehouseId = props.warehouseId;
  inventoryProductCost.value.quantity = inventoriedProductInfo.value.quantityInStock;
  inventoryProductCost.value.totalInventoryCost = inventoriedProductInfo.value.totalInventoryCost;
  initialInventoryProductCost.value = cloneDeep(inventoryProductCost.value);
});

const hasUpdates = computed(() => {
  return (
    !isEqual(unitCost.value, initialUnitCost.value) ||
    !isEqual(inventoryProductCost.value, initialInventoryProductCost.value)
  );
});

const onCancel = () => {
  previouslyFocusedInput.value = document.activeElement as HTMLInputElement;
  visible.value = false;
};

const handleKeydown = (event: KeyboardEvent) => {
  if (event.key === "Escape") {
    event.stopImmediatePropagation();
    onCancel();
  } else if (event.ctrlKey && event.key === "i") {
    visible.value = false;
  }
};

onMounted(async () => {
  document.addEventListener("keydown", handleKeydown);
});
onBeforeUnmount(() => {
  document.removeEventListener("keydown", handleKeydown);
});

const saveButtonTooltip = computed(() => {
  return hasUpdates.value ? { value: "" } : { value: t("common.save-tooltip"), showDelay: 100, hideDelay: 300 };
});
</script>
<style scoped lang="scss">
:deep(.p-checkbox .p-checkbox.p-highlight) {
  border-color: var(--success-btn-bg);
  background: var(--success-btn-bg);
}
:deep(.formgroup-inline .field-checkbox) {
  margin: 0;
}

.c-loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(26, 26, 26, 0.2);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
