import { AuthHeaders } from "@cumulus/event-bus";
import { httpClient } from "@cumulus/http";
import { DefaultPrinterInfo } from "./DefaultPrinterInfo";
import { Printer } from "./Printer";
import { PrintDocumentRequest } from "./PrintDocumentRequest";
import { DocumentType } from "./DocumentType";
import { TemplateType } from "./TemplateType";

class DocumentApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_DOCUMENT != undefined && import.meta.env.VITE_APP_API_DOCUMENT
        ? import.meta.env.VITE_APP_API_DOCUMENT
        : `${import.meta.env.VITE_APP_API_URL}`;
  }

  public async getPrinters(authHeaders: AuthHeaders): Promise<Printer[]> {
    return httpClient(this.uri, authHeaders)
      .get("/printing/printers")
      .then(({ data }) => data);
  }

  public async getDefaultPrinterByDocumentType(
    authHeaders: AuthHeaders,
    documentType: DocumentType
  ): Promise<DefaultPrinterInfo> {
    return httpClient(this.uri, authHeaders)
      .get(`/printing/default-printers/${documentType}`)
      .then(({ data }) => data);
  }

  public async printDocument(authHeaders: AuthHeaders, request: PrintDocumentRequest): Promise<void> {
    return httpClient(this.uri, authHeaders)
      .put("/printing/print-document", request)
      .then(({ data }) => data);
  }

  public async getPackingNoteUrl(authHeaders: AuthHeaders, packingNoteId: string): Promise<string> {
    return await httpClient(this.uri, authHeaders)
      .get("/documents", { params: { templateType: TemplateType.PackingNote, id: packingNoteId } })
      .then(({ data }) => data);
  }
  public async getPickingListUrl(authHeaders: AuthHeaders, pickingListId: string): Promise<string> {
    return await httpClient(this.uri, authHeaders)
      .get("/documents", { params: { templateType: TemplateType.PickingList, id: pickingListId } })
      .then(({ data }) => data);
  }
}

export const documentApi = new DocumentApi();
