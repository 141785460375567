<template>
  <h4 class="text-center">{{ t("inventory.warehouse") }}</h4>
  <Select
    id="inventory-warehouses"
    v-model="selectedWarehouseIdComputed"
    :options="warehouses"
    optionLabel="name"
    optionValue="id"
    :placeholder="t('placeholder.select', { property: t('warehouse.header').toLowerCase() })"
    class="w-full"
    :loading="loading"
    pt:list:data-testid="inventory-warehouses-list"
  />

  <hr class="c-seperator-line" />
  <h4 class="text-center">{{ t("inventory.search-filters.manufacturer") }}</h4>
  <MultiSelect
    id="inventory-manufacturers"
    v-model="selectedManufacturerIdsComputed"
    :placeholder="t('placeholder.select', { property: t('inventory.search-filters.manufacturer').toLowerCase() })"
    optionLabel="name"
    optionValue="id"
    :options="manufacturers"
    display="chip"
    class="w-full field"
    :filter="true"
    :loading="loading"
    pt:list:data-testid="inventory-manufacturers-list"
  />

  <hr class="c-seperator-line" />
  <h4 class="text-center">{{ t("inventory.search-filters.status") }}</h4>
  <div
    v-for="(status, index) in allStatuses"
    :key="index"
    class="flex justify-between relative items-center p-2 rounded-md"
    :class="{ 'c-list-row-bg mt-1': index % 2 === 0, 'c-list-alternate-row-bg mt-1': index % 2 === 1 }"
  >
    <label :for="'product-status-' + status.toLowerCase()">{{
      t(`inventory.product-status.${status.toLowerCase()}`)
    }}</label>
    <Checkbox
      v-model="statusesComputed"
      :value="status"
      :inputId="'product-status-' + status.toLowerCase()"
      :data-testid="'product-status-' + status.toLowerCase()"
    />
  </div>
</template>

<script setup lang="ts">
import { Manufacturer } from "@/repositories/manufacturer/model/Manufacturer";
import { ProductSearchFilters } from "@/repositories/search/model/product/ProductSearchFilters";
import { ProductStatus } from "@/repositories/search/model/product/ProductStatus";
import { Warehouse } from "@/repositories/warehouse/model/Warehouse";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

const { t } = useI18n();
const router = useRouter();
const route = useRoute();

const setRouteQuery = (name: string, query?: string) => {
  router.push({ path: route.path, query: { ...route.query, [name]: query } });
};

const allStatuses = ref<ProductStatus[]>(Object.values(ProductStatus));

const props = defineProps<{
  filters: ProductSearchFilters;
  warehouses: Warehouse[];
  manufacturers: Manufacturer[];
  loading: boolean;
}>();

const emit = defineEmits<{
  (event: "update:filters", value: ProductSearchFilters): void;
  (event: "update:selectedWarehouseId", value: string): void;
}>();

const selectedWarehouseIdComputed = computed<string>({
  get: () => (route.query.warehouse !== undefined ? (route.query.warehouse as string) : props.filters.warehouses[0]),
  set: (value) => {
    const filters = { ...props.filters, warehouses: [value] };
    if (value) {
      setRouteQuery("warehouse", value);
      emit("update:filters", filters);
    }
  },
});

const statusesComputed = computed<ProductStatus[]>({
  get: () => props.filters.productStatuses,
  set: (value) => {
    const filters = { ...props.filters, productStatuses: value };
    emit("update:filters", filters);
  },
});

const selectedManufacturerIdsComputed = computed<string[]>({
  get: () => props.filters.manufacturers,
  set: (value) => {
    const filters = { ...props.filters, manufacturers: value };
    emit("update:filters", filters);
  },
});
</script>

<style scoped lang="scss">
h4,
label {
  font-family: var(--font-family);
}
h4 {
  margin-bottom: 0.5rem;
  margin-top: 1.5rem;
}
.c-list-row-bg {
  background-color: var(--list-row-bg);
}

.c-list-alternate-row-bg {
  background-color: var(--list-alternate-row-bg);
}
</style>
