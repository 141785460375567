import { useAuth } from "@cumulus/event-bus";
import { useErrorHandler } from "@/repositories/ErrorHandler";
import { clientRepository } from "./ClientRepository";
import { Client } from "./model/Client";

export function useClient() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getClient = async (id: string): Promise<Client> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await clientRepository.get(authHeaders, id);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return {
    getClient,
  };
}
