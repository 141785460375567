import { defineStore } from "pinia";
import { useAuth } from "@cumulus/event-bus";
import { productHierarchyApi } from "./ProductHierarchyApi";
import { ProductHierarchy } from "../model/ProductHierarchy";

import { useErrorHandler } from "./ErrorHandler";
import { ref } from "vue";

export const useProductHierarchyStore = defineStore("inventoryProductHierarchy", () => {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const productHierarchies = ref<ProductHierarchy[]>([]);
  const loading = ref(false);

  const getProductHierarchies = async (): Promise<void> => {
    try {
      loading.value = true;
      const authHeaders = await getAuthHeaders();
      productHierarchies.value = await productHierarchyApi.getProductHierarchies(authHeaders);
    } catch (error) {
      await handleError(error);
    } finally {
      loading.value = false;
    }
  };

  const getProductHierarchyById = async (productHierarchyId: string): Promise<ProductHierarchy | null> => {
    try {
      if (productHierarchies.value.length === 0) {
        await getProductHierarchies();
      }

      loading.value = true;

      const productHierarchy = productHierarchies.value.find(
        (productHierarchy) => productHierarchy.id === productHierarchyId
      );

      if (productHierarchy) {
        return productHierarchy;
      }
    } catch (error) {
      await handleError(error);
    } finally {
      loading.value = false;
    }
    return null;
  };

  return {
    loading,
    getProductHierarchyById,
  };
});
