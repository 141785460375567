<template>
  <div>
    <span class="font-medium text-m mr-1">{{ t(`checkout.pickinglist-number`) }}</span>
    <span class="text-lg" data-testid="checkout-picking-list-number">{{ pickinglistNumber }}</span>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";

const { t } = useI18n();

defineProps<{
  pickinglistNumber: number;
}>();
</script>
