import { ref, computed, type Ref } from "vue";
import { useRouter, onBeforeRouteLeave } from "vue-router";
import { InventoryCountJob } from "../model/InventoryCountJob";

export function useRouteFunctions(
  inventoryCountJobSummary: Ref<InventoryCountJob>,
  initialInventoryCountJobSummary: Ref<InventoryCountJob>,
) {
  const confirmedDiscard = ref(false);
  const unsavedChangesDialogVisible = ref(false);
  const previouslyFocusedInput = ref<HTMLInputElement | null>(null);

  const router = useRouter();

  const routeToSearchPage = () => {
    confirmedDiscard.value = true;
    router.push({ name: "inventory-count-search" });
  };

  const stayOnPage = () => {
    unsavedChangesDialogVisible.value = false;
    if (previouslyFocusedInput.value) {
      previouslyFocusedInput.value.focus();
    }
  };

  const handleCancelAction = () => {
    previouslyFocusedInput.value = document.activeElement as HTMLInputElement;
    if (isInventoryCountJobChanged.value === true) {
      unsavedChangesDialogVisible.value = true;
    } else {
      routeToSearchPage();
    }
  };

  onBeforeRouteLeave((_to, _from, next) => {
    if (isInventoryCountJobChanged.value && !confirmedDiscard.value) {
      unsavedChangesDialogVisible.value = true;
      next(false);
    } else {
      next();
    }
  });

  const isInventoryCountJobChanged = computed(() => {
    return !deepEqual(inventoryCountJobSummary.value, initialInventoryCountJobSummary.value);
  });

  const deepEqual = (x: unknown, y: unknown): boolean => {
    const ok = Object.keys,
      tx = typeof x,
      ty = typeof y;
    return x && y && tx === "object" && tx === ty
      ? ok(x as { [key: string]: unknown }).length === ok(y as { [key: string]: unknown }).length &&
          ok(x as { [key: string]: unknown }).every((key) =>
            deepEqual((x as { [key: string]: unknown })[key], (y as { [key: string]: unknown })[key]),
          )
      : x === y;
  };

  return {
    stayOnPage,
    routeToSearchPage,
    handleCancelAction,
    isInventoryCountJobChanged,
    unsavedChangesDialogVisible,
    previouslyFocusedInput,
  };
}
