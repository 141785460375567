import { httpClient, AuthHeaders } from "@cumulus/http";
import { BringShippingGuideRequest } from "./BringShippingGuideRequest";
import { NewBringBooking } from "./NewBringBooking";
import { PickupPointReponse } from "./PickupPoint";

class BringApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_WAREHOUSE != undefined && import.meta.env.VITE_APP_API_WAREHOUSE
        ? `${import.meta.env.VITE_APP_API_WAREHOUSE}/bring-integration`
        : `${import.meta.env.VITE_APP_API_URL as string}/bring-integration`;
  }

  public async getBringShippingGuide(authHeaders: AuthHeaders, data: BringShippingGuideRequest) {
    return await httpClient(this.uri, authHeaders)
      .post("/shipping-guide", data)
      .then(({ data }) => data);
  }

  public async storeBringBookingData(authHeaders: AuthHeaders, body: NewBringBooking): Promise<string> {
    return await httpClient(this.uri, authHeaders)
      .post(`/bring-booking-data`, body)
      .then(({ data }) => data);
  }

  public async getPickupPoints(authHeaders: AuthHeaders, uri: string): Promise<PickupPointReponse> {
    return await httpClient(this.uri, authHeaders)
      .get(`/pickup-points?${uri}`)
      .then(({ data }) => data);
  }
}

const bringApi = new BringApi();
export { bringApi };
