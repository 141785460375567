import { Batch } from "./Batch";
import { Dimension } from "./Dimension";
import { Weight } from "./Weight";

export class InventoriedProductInfo {
  productId = "";
  productName = "";
  productNumber = "";
  gtin = "";
  salesUnitName = "";
  mainSupplierName = "";
  mainSuppliersProductNumber = "";
  costPrice = 0;
  quantityAvailable = 0;
  quantityAvailableFromStock = 0;
  quantityInStock = 0;
  quantityAllocated = 0;
  quantityAllocatedForPicking = 0;
  quantityInOrders = 0;
  batches: Batch[] = [];
  productHierarchyId = "";
  weight = new Weight();
  dimension = new Dimension();
  totalInventoryCost = 0;
  warehouseId = "";
}
