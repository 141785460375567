import { httpClient, type AuthHeaders } from "@cumulus/http";
import { Manufacturer } from "./model/Manufacturer";

class ManufacturerApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_PRODUCT != "undefined" && import.meta.env.VITE_APP_API_PRODUCT
        ? `${import.meta.env.VITE_APP_API_PRODUCT as string}/manufacturers`
        : `${import.meta.env.VITE_APP_API_URL as string}/manufacturers`;
  }

  public async getManufacturers(authHeaders: AuthHeaders): Promise<Manufacturer[]> {
    return await httpClient(this.uri, authHeaders)
      .get("")
      .then(({ data }) => data);
  }
}

export const manufacturerApi = new ManufacturerApi();
