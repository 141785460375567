export const InventoryProductListColumn = {
  ProductNumber: "productNumber",
  Name: "name",
  Gtin: "gtin",
  Inventory: "availability.quantityInStock",
  InPickList: "availability.quantityAllocatedForPicking",
  Available: "availability.quantityAvailableFromStock",
  CostPrice: "availability.costPrice",
  TotalInventoryCost: "availability.totalInventoryCost",
} as const;
