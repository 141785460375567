<template>
  <FloatLabel variant="on">
    <Select
      id="inventory-transfer-reason"
      v-model="selectedReason"
      class="w-full"
      optionLabel="label"
      optionValue="value"
      :options="options"
      :fluid="true"
      :disabled="quantityInStock === 0 || quantityInStock === undefined"
      @show="isActive = true"
      @hide="isActive = false"
      @keydown.esc="isActive === true ? $event.stopPropagation() : ''"
      pt:list:data-testid="inventory-transfer-reason-list"
    />
    <label for="inventory-transfer-reason">{{ t("inventory.reason") }}</label>
  </FloatLabel>
  <small class="p-error" v-if="validate.reason.$error" data-testid="inventory-reason-error">
    {{ validate.reason.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";
import { InventoryTransferChangedReason } from "@/models/inventory/InventoryTransferChangedReason";
import { FloatLabel } from "primevue";

const { t } = useI18n();
const options = ref<Record<string, string>[]>([]);

const isActive = ref(false);

const props = defineProps<{
  reason: InventoryTransferChangedReason;
}>();

const quantityInStock = defineModel<number>("quantityInStock", { required: true, default: 0 });

const emit = defineEmits<{
  (e: "update:reason", value: InventoryTransferChangedReason): void;
}>();

const selectedReason = computed<InventoryTransferChangedReason>({
  get: (): InventoryTransferChangedReason => {
    return props.reason;
  },
  set: (value: InventoryTransferChangedReason) => {
    emit("update:reason", value);
  },
});

onMounted(() => {
  options.value = Object.keys(InventoryTransferChangedReason).map((key) => {
    return {
      label: t(`inventory.edit-transfer.reason.${key.toLowerCase()}`),
      value: key,
    };
  });
});

const rules = {
  reason: {
    required: required,
  },
};

const validate = useVuelidate(rules, props);
</script>
32ea
