<template>
  <label for="warehouse-address-lines">{{ t("common.address.address") }}</label>
  <PrimeTextarea
    id="warehouse-address-lines"
    data-testid="warehouse-address"
    v-model="addressLines"
    class="inputfield w-full"
    :class="{ 'p-invalid': validate.warehouseAddress.$error }"
    :placeholder="t('placeholder.type', { property: t('common.address.address').toLowerCase() })"
  />
  <small class="p-error" v-if="validate.warehouseAddress.$error" data-testid="warehouse-address-lines-error">
    {{ validate.warehouseAddress.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useVuelidate } from "@vuelidate/core";
import { minLength } from "@vuelidate/validators";
import { required } from "@/locales/i18n-validators";
import { computed } from "vue";

const { t } = useI18n();
const emit = defineEmits<{ (e: "update:warehouseAddress", value: string[]): void }>();

const props = defineProps<{
  warehouseAddress: string[];
}>();

const addressLines = computed<string>({
  get: (): string => {
    return props.warehouseAddress.join("\n");
  },
  set: (val: string) => {
    emit("update:warehouseAddress", getArr(val));
  },
});

const getArr = (val: string): string[] => {
  if (val === "") return [];
  return val.split("\n");
};

const rules = {
  warehouseAddress: {
    required,
    minLength: minLength(1),
  },
};

const validate = useVuelidate(rules, props);
</script>
