<template>
  <div class="c-packing-note" v-if="loadFailed">
    <Card>
      <template #content>
        {{ t("packing-note.load-failed") }}
      </template>
    </Card>
  </div>
  <div class="c-packing-note" v-else>
    <div id="open-picking-navbar-shortcuts" v-if="useTeleport">
      <Teleport to="#c-navbar-title">
        <h1>{{ t("packing-note.header") }}</h1>
      </Teleport>
      <Teleport to="#c-navbar-shortcuts">
        <div class="c-header-icon-container"></div>
      </Teleport>
    </div>
    <div class="c-toolbar">
      <h1>{{ t("packing-note.header") }}</h1>
      <div class="c-header-icon-container"></div>
    </div>

    <Card>
      <template #content>
        <Suspense> <PackingNoteList /></Suspense>
      </template>
    </Card>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import PackingNoteList from "./PackingNoteList.vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const loadFailed = ref(false);

const useTeleport = !(import.meta.env.VITE_APP_STANDALONE === "true" || import.meta.env.MODE === "test");
</script>
