<template>
  <FloatLabel variant="on"
    ><Select
      id="warehouse-client"
      :options="clients"
      optionLabel="name"
      class="inputfield w-full"
      :class="{ 'p-invalid': validate.clientId.$error }"
      v-model="selectedClient"
      :placeholder="t('placeholder.select', { property: t('warehouse.client').toLowerCase() })"
      @show="isActive = true"
      @hide="isActive = false"
      @keydown.esc="isActive === true ? $event.stopPropagation() : ''"
      pt:list:data-testid="warehouse-client-list"
    />
    <label for="warehouse-client">{{ t("warehouse.client") }}</label>
  </FloatLabel>
  <small class="p-error" v-if="validate.clientId.$error" data-testid="warehouse-client-error">
    {{ validate.clientId.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { computed, ref } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";
import { WarehouseClient } from "@/repositories/warehouse/model/WarehouseClient";
import { storeToRefs } from "pinia";
import { useClientStore } from "../stores/ClientStore";

const { t } = useI18n();
const isActive = ref(false);
const { clients } = storeToRefs(useClientStore());

const props = defineProps<{
  clientId: string;
}>();

const selectedClient = computed<WarehouseClient>({
  get: (): WarehouseClient => {
    return clients.value.find((c) => c.id === props.clientId) ?? new WarehouseClient();
  },
  set: (value: WarehouseClient) => {
    emit("update:clientId", value.id);
  },
});

const emit = defineEmits<{ (e: "update:clientId", value: string): void }>();

const rules = {
  clientId: {
    required,
  },
};

const validate = useVuelidate(rules, props);
</script>
