import { CustomerAddress } from "./CustomerAddress";
import { Delivery } from "./Delivery";
import { PickingListLine } from "./PickingListLine";
import { PickingListState } from "./PickingListState";

export class PickingList {
  id = "";
  companyId = "";
  clientId = "";
  warehouseId = "";
  orderId = "";
  orderNumber = 0;
  orderReference = "";
  orderComment = "";
  shippingDate = "";
  delivery = new Delivery();
  invoiceAddress = new CustomerAddress();
  freightMethodId = "";
  freightMethodName = "";
  completeDelivery = true;
  paymentTermName = "";
  customerId = "";
  customerName = "";
  customerNumber = "";
  contactName = "";
  pickingListNumber = 0;
  state = PickingListState.Open;
  assignedEmployeeId: string | null = "";
  assignedEmployeeInitials = "";
  pickingStarted = new Date().toJSON();
  quantityOfOrderItems = 0;
  sumTotalLines = 0;
  lines: PickingListLine[] = [];
  agreedFreightPriceExVat: number | null | undefined = null;
}
