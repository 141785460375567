import { httpClient, type AuthHeaders } from "@cumulus/http";
import { ConfirmPickingModel } from "./model/ConfirmPickingModel";
import { PickingList } from "./model/PickingList";
import { type CreatePickingListsRequest } from "./model/CreatePickingListBody";

class PickingListApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_WAREHOUSE != "undefined" && import.meta.env.VITE_APP_API_WAREHOUSE
        ? import.meta.env.VITE_APP_API_WAREHOUSE + "/picking-lists"
        : `${import.meta.env.VITE_APP_API_URL as string}/picking-lists`;
  }

  public async createPickingLists(authHeaders: AuthHeaders, body: CreatePickingListsRequest): Promise<void> {
    return await httpClient(this.uri, authHeaders)
      .post("", body)
      .then(({ data }) => data);
  }

  public async getOpen(authHeaders: AuthHeaders): Promise<PickingList[]> {
    return await httpClient(this.uri, authHeaders)
      .get("", { params: { open: "true" } })
      .then(({ data }) => data);
  }

  public async get(authHeaders: AuthHeaders, id: string): Promise<PickingList> {
    return await httpClient(this.uri, authHeaders)
      .get(id)
      .then(({ data }) => data);
  }

  public async delete(authHeaders: AuthHeaders, id: string): Promise<void> {
    return await httpClient(this.uri, authHeaders)
      .delete(id)
      .then(({ data }) => data);
  }

  public async start(authHeaders: AuthHeaders, id: string): Promise<void> {
    return await httpClient(this.uri, authHeaders)
      .put(id + "/start")
      .then(({ data }) => data);
  }

  public async park(authHeaders: AuthHeaders, id: string): Promise<void> {
    return await httpClient(this.uri, authHeaders)
      .put(id + "/park")
      .then(({ data }) => data);
  }

  public async cancel(authHeaders: AuthHeaders, id: string): Promise<void> {
    return await httpClient(this.uri, authHeaders)
      .put(id + "/cancel")
      .then(({ data }) => data);
  }

  public async confirm(authHeaders: AuthHeaders, confirmPickingRequest: ConfirmPickingModel): Promise<void> {
    return await httpClient(this.uri, authHeaders)
      .put(confirmPickingRequest.pickingListId + "/confirm", confirmPickingRequest)
      .then(({ data }) => data);
  }

  public async saveProgress(
    authHeaders: AuthHeaders,
    id: string,
    lineId: string,
    quantityPicked: number,
  ): Promise<void> {
    return await httpClient(this.uri, authHeaders)
      .put(id + "/save-progress", { pickingListLineId: lineId, quantityPicked: quantityPicked })
      .then(({ data }) => data);
  }
}

export const pickingListApi = new PickingListApi();
