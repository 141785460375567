import { httpClient, type AuthHeaders } from "@cumulus/http";
import { OrderReadyForPickingSearchRequest } from "./model/order/OrderReadyForPickingSearchRequest";
import { OrderReadyForPickingSearchResponse } from "./model/order/OrderReadyForPickingSearchResponse";
import { ProductSearchRequest } from "./model/product/ProductSearchRequest";
import { ProductSearchResponse } from "./model/product/ProductSearchResponse";

class SearchApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_SEARCH != "undefined" && import.meta.env.VITE_APP_API_SEARCH
        ? import.meta.env.VITE_APP_API_SEARCH
        : (import.meta.env.VITE_APP_API_URL as string);
  }

  public async productSearch(authHeaders: AuthHeaders, request: ProductSearchRequest): Promise<ProductSearchResponse> {
    return await httpClient(this.uri, authHeaders)
      .post("/products/search", request)
      .then(({ data }) => data);
  }

  public async orderReadyForPickingSearch(
    authHeaders: AuthHeaders,
    request: OrderReadyForPickingSearchRequest,
  ): Promise<OrderReadyForPickingSearchResponse> {
    return await httpClient(this.uri, authHeaders)
      .post("/orders/search/ready-for-picking", request)
      .then(({ data }) => data);
  }

  public async getWebPubSubUrl(hubName: string, authHeaders: AuthHeaders): Promise<string> {
    return await httpClient(this.uri, authHeaders)
      .get(`/web-pub-sub/search/${hubName}/connection-string`)
      .then(({ data }) => data);
  }
}

const searchApi = new SearchApi();
export { searchApi };
