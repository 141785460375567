import { httpClient, AuthHeaders } from "@cumulus/http";
import { PackingNote } from "../models/PackingNote";

class PackingApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_WAREHOUSE != undefined && import.meta.env.VITE_APP_API_WAREHOUSE
        ? import.meta.env.VITE_APP_API_WAREHOUSE + "/packing-notes"
        : `${import.meta.env.VITE_APP_API_URL as string}/packing-notes`;
  }

  public async list(authHeaders: AuthHeaders): Promise<PackingNote[]> {
    return await httpClient(this.uri, authHeaders)
      .get("")
      .then(({ data }) => data);
  }

  public async get(authHeaders: AuthHeaders, id: string): Promise<PackingNote> {
    return await httpClient(this.uri, authHeaders)
      .get(id)
      .then(({ data }) => data);
  }
}

export const packingApi = new PackingApi();
