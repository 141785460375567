import { InventoryQuantityChangedReason } from "./InventoryQuantityChangedReason";
import { NIL as emptyUuid } from "uuid";

export class InventoryProductQuantity {
  productId: string = emptyUuid;
  warehouseId: string = emptyUuid;
  newQuantity = 0;
  oldQuantity = 0;
  reason: InventoryQuantityChangedReason = InventoryQuantityChangedReason.Defective;
  comment = "";
}
