<template>
  <Teleport to="#c-mobile-toolbar" v-if="isMobile || isTablet">
    <Button v-if="startPickingAllowed" @click="emit('start')" data-testid="btn-start-picking" class="p-button-text">
      <i class="pi pi-caret-right"></i>
      <span class="c-mobile-toolbar-text">{{ startPickingButtonLabel }}</span>
    </Button>
    <Button v-if="editMode" class="p-button-text" @click="emit('confirm')" data-testid="btn-confirm-picking">
      <i class="pi pi-check"></i>
      <span class="c-mobile-toolbar-text">{{ t("picking-list.confirm") }}</span>
    </Button>
  </Teleport>
  <Teleport to="#c-more-options-menu" v-if="isMobile || isTablet">
    <Button v-if="editMode" @click="emit('park')" data-testid="btn-park-picking" class="p-button-text">
      <i class="pi pi-check"></i>
      <span class="c-mobile-toolbar-text">{{ t("picking-list.park") }}</span>
    </Button>
    <Button v-if="!editMode" class="p-button-text" @click="emit('close')" data-testid="btn-close-picking-list">
      <i class="pi pi-times"></i>
      <span class="c-mobile-toolbar-text">{{ t("common.close") }}</span>
    </Button>
    <Button
      v-if="!editMode && state === PickingListState.Open"
      class="p-button-text"
      @click="emit('delete')"
      data-testid="btn-delete-picking-list"
    >
      <i class="pi pi-trash"></i>
      <span class="c-mobile-toolbar-text">{{ t("common.delete") }}</span>
    </Button>
    <Button v-if="editMode" class="p-button-text" @click="emit('cancel')" data-testid="btn-cancel-picking-list">
      <i class="pi pi-times"></i>
      <span class="c-mobile-toolbar-text">{{ t("common.cancel") }}</span>
    </Button>
  </Teleport>
  <div class="col-span-12">
    <div class="flex flex-wrap my-3 pl-4">
      <div class="flex-1 min-w-full lg:min-w-0">
        <div v-if="!editMode">
          <Button
            icon="pi pi-arrow-left"
            rounded
            variant="text"
            class="mr-4"
            @click="emit('close')"
            data-testid="btn-close-picking-list"
          >
          </Button>
          <div class="inline-block text-lg lg:w-36 xl:w-auto truncate xl:text-wrap align-middle">
            <span class="text-slate-800">
              {{ pickingLists?.pickingListNumber }} - {{ pickingLists?.contactName }}
            </span>
          </div>
        </div>

        <div v-if="editMode" class="mr-20">
          <Button
            icon="pi pi-arrow-left"
            rounded
            variant="text"
            class="mr-4"
            @click="emit('cancel')"
            data-testid="btn-cancel-picking-list"
          >
          </Button>
          <div class="inline-block text-lg lg:w-36 xl:w-auto truncate xl:text-wrap align-middle">
            <span class="text-slate-800">
              {{ pickingLists?.pickingListNumber }} - {{ pickingLists?.contactName }}
            </span>
          </div>
        </div>
      </div>

      <div class="flex-1 min-w-full lg:min-w-0 mb-4 lg:mb-0 text-right mr-4">
        <Button
          v-if="startPickingAllowed"
          @click="emit('start')"
          data-testid="btn-start-picking"
          class="c-circular-button mr-4"
        >
          <i class="pi pi-caret-right c-success-button c-circular-icon"></i>
          <span class="px-4">{{ startPickingButtonLabel }}</span>
        </Button>

        <Button
          v-if="!editMode && state === PickingListState.Open"
          class="c-circular-button mr-4"
          @click="emit('delete')"
          data-testid="btn-delete-picking-list"
        >
          <i class="pi pi-trash c-delete-button c-circular-icon"></i>
          <span class="px-4">{{ t("common.delete") }}</span>
        </Button>

        <Button
          v-if="editMode"
          class="c-circular-button ml-4"
          @click="emit('confirm')"
          data-testid="btn-confirm-picking"
        >
          <i class="pi pi-check c-success-button c-circular-icon"></i>
          <span class="px-4">{{ t("picking-list.confirm") }}</span>
        </Button>

        <Button v-if="editMode" @click="emit('park')" data-testid="btn-park-picking" class="c-circular-button mr-4">
          <i class="pi pi-check c-default-button c-circular-icon"></i>
          <span class="px-4">{{ t("picking-list.park") }}</span>
        </Button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PickingListState } from "@/repositories/picking-list/model/PickingListState";
import { useAuth, User } from "@cumulus/event-bus";
import { computed, ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useMobile } from "@/utils/MobileService";
import { PickingList } from "@/repositories/picking-list/model/PickingList";

const { t } = useI18n();
const { isMobile, isTablet } = useMobile();

const props = defineProps<{
  editMode: boolean;
  state: PickingListState;
  assignedEmployeeId: string | null;
  pickingLists: PickingList | undefined;
}>();

const user = ref<User | null>(null);
const { getUser } = useAuth();
onMounted(async () => {
  user.value = await getUser();
});

const emit = defineEmits<{
  (e: "close"): void;
  (e: "cancel"): void;
  (e: "start"): void;
  (e: "park"): void;
  (e: "delete"): void;
  (e: "confirm"): void;
  (e: "confirmDialog"): void;
}>();

const startPickingAllowed = computed<boolean>(() => {
  return (
    !props.editMode &&
    (props.state === PickingListState.Open ||
      (props.state === PickingListState.InPicking &&
        user.value != null &&
        props.assignedEmployeeId === user.value.getEmployee().id) ||
      props.state === PickingListState.Parked)
  );
});

const startPickingButtonLabel = computed<string>(() => {
  if (props.state === PickingListState.Parked) {
    return t("picking-list.context-menu.continue-picking");
  } else {
    return t("picking-list.context-menu.start-picking");
  }
});
</script>
