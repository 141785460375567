<template>
  <label for="warehouse-country">{{ t("common.address.country") }}</label>
  <Select
    id="warehouse-country"
    :options="countryOptions"
    optionLabel="name"
    optionValue="iso"
    class="inputfield w-full"
    :class="{ 'p-invalid': validate.countryIso.$error }"
    v-model="countryIso"
    :placeholder="t('placeholder.select', { property: t('common.address.country').toLowerCase() })"
    @show="isActive = true"
    @hide="isActive = false"
    @keydown.esc="isActive === true ? $event.stopPropagation() : ''"
    pt:list:data-testid="warehouse-country-list"
  />
  <small class="p-error" v-if="validate.countryIso.$error" data-testid="warehouse-country-error">
    {{ validate.countryIso.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { onMounted, ref } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";
import { Country } from "@/repositories/country/model/Country";
import { useCountryService } from "@/repositories/country/CountryService";

const { t } = useI18n();
const { getAllCountries } = useCountryService();

const isActive = ref(false);

const countryIso = defineModel("countryIso", {
  required: true,
});

const rules = {
  countryIso: {
    required,
  },
};

const loading = ref();
const countryOptions = ref<Country[]>([]);

const loadCountries = async () => {
  loading.value = true;

  try {
    countryOptions.value = await getAllCountries();
  } finally {
    loading.value = false;
  }
};

onMounted(loadCountries);
const validate = useVuelidate(rules, { countryIso });
</script>
