import { Parcel } from "./Parcel";

export class Consignment {
  id = "";
  fromCountryCode = "";
  fromPostalCode = "";
  toCountryCode = "";
  toPostalCode = "";
  products: Product[] = [];
  packages: Package[] = [];
  shippingdate = new ShippingDate();
  pickupPointId = "";
}

export class BringShippingGuideRequest {
  consignments: Consignment[] = [];
  language = "";
  withGuiInformation = false;
  withPrice = true;
}

export class Package {
  id = "";
  length = 0;
  width = 0;
  height = 0;
  grossWeight = 0;

  fromParcel = (parcel: Parcel) => {
    this.id = parcel.parcelNumber.toString();
    this.length = parcel.length;
    this.width = parcel.width;
    this.height = parcel.height;
    this.grossWeight = parcel.weight * 1000;
    return this;
  };
}

export class ShippingDate {
  day: string;
  hour: string;
  minute: string;
  month: string;
  year: string;

  constructor() {
    const date = new Date();
    this.day = date.getDate().toString();
    this.hour = date.getHours().toString();
    this.minute = date.getMinutes().toString();
    this.month = (date.getMonth() + 1).toString();
    this.year = date.getFullYear().toString();
  }
}

export class Product {
  customerNumber = "";
  id = "";
}
