import { WarehouseAddress as Address } from "./WarehouseAddress";
import { WarehouseStates } from "./WarehouseStates";

export class Warehouse {
  id = "";
  companyId = "";
  name = "";
  shortName = "";
  address: Address = new Address();
  currencyIso = "";
  clientId = "";
  state = WarehouseStates.Active;
}
