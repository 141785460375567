<template>
  <ul class="c-chips-container" v-if="hasActiveFilters" data-testid="c-active-filters-container">
    <li class="c-chips-clear-filter">
      <span data-testid="c-clear-active-filters-label">{{ t("common.filters.clear-filter") }}</span>
      <span
        class="c-chips-token-icon pi pi-times-circle"
        @click="clearFilters"
        data-testid="c-clear-active-filters-btn"
      >
      </span>
    </li>

    <ActiveFilterChip
      v-if="filters.manufacturers.length > 0"
      :filterValue="manufacturersComputed"
      filterType="manufacturers"
      @removeFilter="removeFilter"
      dataTestId="c-manufacturers-filter"
    />
    <ActiveFilterChip
      v-if="filters.warehouses.length > 1"
      :filterValue="warehousesComputed"
      filterType="warehouses"
      @removeFilter="removeFilter"
      dataTestId="c-warehouses-filter"
    />
    <ActiveFilterChip
      v-if="filters.productStatuses.length > 0"
      :filterValue="filters.productStatuses.map((status) => t(`inventory.product-status.${status.toLowerCase()}`))"
      filterType="productStatuses"
      @removeFilter="removeFilter"
      dataTestId="c-active-filters-inventory-product-status"
    />
  </ul>
</template>
<script setup lang="ts">
import { Manufacturer } from "@/repositories/manufacturer/model/Manufacturer";
import { ProductSearchFilters } from "@/repositories/search/model/product/ProductSearchFilters";
import { Warehouse } from "@/repositories/warehouse/model/Warehouse";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import ActiveFilterChip from "./ActiveFilterChip.vue";

const { t } = useI18n();

const props = defineProps<{
  filters: ProductSearchFilters;
  manufacturers: Manufacturer[];
  warehouses: Warehouse[];
}>();

const emit = defineEmits<{
  (event: "update:filters", value: ProductSearchFilters): void;
}>();

const removeFilter = (filterType: keyof ProductSearchFilters | string, index: number) => {
  const filters = { ...props.filters };
  const filterToRemove = filters[filterType as keyof ProductSearchFilters];

  if (Array.isArray(filterToRemove)) {
    filterToRemove.splice(index, 1);
  }

  emit("update:filters", filters);
};

const hasActiveFilters = computed<boolean>(() => {
  return (
    props.filters.manufacturers.length > 0 ||
    props.filters.warehouses.length > 1 ||
    props.filters.productStatuses.length > 0
  );
});

const clearFilters = () => {
  const filters = new ProductSearchFilters();
  filters.warehouses = [props.warehouses[0].id];
  emit("update:filters", filters);
};

const manufacturersComputed = computed<string[]>(() => {
  return props.filters.manufacturers.map((id) => {
    const manufacturer = props.manufacturers.find((m) => m.id === id);
    return manufacturer?.name ?? "";
  });
});

const warehousesComputed = computed<string[]>(() => {
  return props.filters.warehouses.map((id) => {
    const warehouse = props.warehouses.find((w) => w.id === id);
    return warehouse?.name ?? "";
  });
});
</script>
<style scoped lang="scss">
.c-chips-clear-filter {
  padding: 0.25rem 0.5rem;
  margin-right: 0.5rem;
  color: var(--primary-color-text);
  border-radius: 1rem;
  background-color: var(--chips-bg-primary);
  display: inline-flex;
  align-items: center;
}

.c-chips-container {
  padding: 0.25rem 0.5rem;
  margin: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.c-chips-token-icon {
  margin-left: 0.5rem;
  cursor: pointer;
}
</style>
