<template>
  <div class="c-toolbar-wrapper">
    <div class="c-toolbar">
      <div class="c-header-icon-container">
        <PrimeButton class="c-circular-button mr-4" @click="onImportClicked" data-testid="btn-import">
          <i class="pi pi-plus c-success-button c-circular-icon"></i>
          <span class="px-4">{{ t("inventory-count.import") }}</span>
        </PrimeButton>
      </div>
    </div>
  </div>

  <ImportInventoryCountJobDialog v-model:visibleDialog="visibleExportDialog" />
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import ImportInventoryCountJobDialog from "./ImportInventoryCountJobDialog.vue";
import { ref } from "vue";

const { t } = useI18n();

const visibleExportDialog = ref<boolean>(false);

const onImportClicked = () => {
  visibleExportDialog.value = true;
};
</script>
