<template>
  <FloatLabel variant="on">
    <InputText
      id="warehouse-name"
      data-testid="warehouse-name"
      :model-value="warehouseName"
      class="inputfield w-full"
      @input="$emit('update:warehouseName', ($event.target as HTMLInputElement).value)"
      :class="{ 'p-invalid': validate.warehouseName.$error }"
      v-tooltip.focus.bottom="{
        value: t('placeholder.type', { property: t('warehouse.name').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
    />
    <label for="warehouse-name">{{ t("warehouse.name") }}</label>
  </FloatLabel>

  <small class="p-error" v-if="validate.warehouseName.$error" data-testid="warehouse-name-error">
    {{ validate.warehouseName.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";

const { t } = useI18n();

const props = defineProps<{
  warehouseName: string;
}>();

const rules = {
  warehouseName: {
    required,
  },
};

const validate = useVuelidate(rules, props);
</script>
