<template>
  <InventoryCountHeader
    :unsavedChangesDialogVisible="unsavedChangesDialogVisible"
    :inventoryCountJobStatus="inventoryCountJob.status"
    :isDeleting="isDeleting"
    :isStarting="isStarting"
    @cancelClicked="handleCancelAction"
    @deleteClicked="onDelete"
    @stayOnPage="stayOnPage"
    @routeToSearch="routeToSearchPage"
    @startClicked="onStart"
    @saveClicked="onSave"
  />
  <div class="c-margin">
    <div class="grid grid-cols-12 gap-4 mb-6">
      <div class="col-span-12">
        <Card>
          <template #content>
            <div class="formgrid grid grid-cols-12 gap-4">
              <div class="col-span-12 md:col-span-6 xl:col-span-3 field">
                <FloatLabel variant="on">
                  <InputText
                    id="inventory-count-name"
                    fluid
                    v-model="inventoryCountJob.name"
                    :selectAllOnFocus="true"
                    data-testid="inventory-count-name"
                    :disabled="false"
                    v-tooltip.focus.bottom="{
                      value: t('placeholder.type', { property: t('inventory-count.name').toLowerCase() }),
                      showDelay: 1000,
                      hideDelay: 300,
                    }"
                  />
                  <label for="inventory-count-name">{{ t("inventory-count.name") }}</label>
                </FloatLabel>
              </div>
              <div class="col-span-12 md:col-span-6 xl:col-span-3 field">
                <div class="ml-6 border-slate-300 mr-6">
                  <FloatLabel variant="on">
                    <InputText
                      ref="commentInputRef"
                      id="inventory-count-comment"
                      data-testid="inventory-count-comment"
                      v-model="inventoryCountJob.comment"
                      maxlength="15000"
                      :pt:overlay:class="'hidden'"
                      :fluid="true"
                      @click="showCommentPanel"
                    />
                    <label for="inventory-count-comment">{{ t("inventory-count.comment") }}</label>
                  </FloatLabel>
                  <CommentPanel ref="commentPanelRef" v-model:comment="inventoryCountJob.comment" />
                </div>
              </div>

              <div class="col-span-12 md:col-span-6 xl:col-span-3 field mr-6">
                <FloatLabel variant="on">
                  <InputText
                    id="inventory-count-warehouse"
                    :label="t('inventory-count.warehouse')"
                    v-model="warehouse.name"
                    :selectAllOnFocus="true"
                    fluid
                    data-testid="inventory-count-warehouse"
                    :disabled="false"
                    v-tooltip.focus.bottom="{
                      value: t('placeholder.type', { property: t('inventory-count.warehouse').toLowerCase() }),
                      showDelay: 1000,
                      hideDelay: 300,
                    }"
                  />
                  <label for="inventory-count-warehouse">{{ t("inventory-count.warehouse") }}</label>
                </FloatLabel>
              </div>
              <div class="col-span-12 md:col-span-6 xl:col-span-3 field">
                <label for="status" class="w-full mr-2">{{ t("inventory-count.status") }}: </label>
                <Tag
                  class="mr-2"
                  data-testid="inventory-count-status"
                  :value="t(`inventory-count.count-job-status.${inventoryCountJob.status.toLowerCase()}`)"
                />
              </div>
            </div>
          </template>
        </Card>
      </div>
    </div>

    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-12">
        <Card>
          <template #content>
            <InventoryCountLinesList
              v-model:lines="inventoryCountJob.lines"
              :loading="loading"
              :pageSize="pageSize"
              :totalHits="inventoryCountJob.lines.length"
              @failedLineClicked="onFailedLineClicked"
              :sortOrder="sortOrder"
              @update:sortOrder="onUpdateSortInventoryOrder"
              @inventoryListRefresh="onRefreshList"
            />
          </template>
        </Card>
      </div>
    </div>
  </div>

  <LinesCorrectionDialog
    v-model:visibleDialog="correctLinesDialogVisible"
    :warehouseId="inventoryCountJob.warehouseId"
    :failedLines="failedLines"
    :inventoryCountJob="inventoryCountJob"
    :pageSize="pageSize"
    :totalHits="failedLines.length"
    @linesCorrected="routeToSearchPage"
  />
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import { useInventoryCountService } from "../api/InventoryCountService";
import { InventoryCountJob } from "../model/InventoryCountJob";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { useWarehouse } from "@/repositories/warehouse/WarehouseService";
import { useRouteFunctions } from "../composables/RouteFunctions";
import { Warehouse } from "@/repositories/warehouse/model/Warehouse";
import InventoryCountLinesList from "../components/InventoryCountLinesList.vue";
import InventoryCountHeader from "../components/InventoryCountHeader.vue";
import { InventoryCountLineStatus } from "../model/InventoryCountLineStatus";
import LinesCorrectionDialog from "../components/LinesCorrectionDialog.vue";
import { InventoryCountLine } from "../model/InventoryCountLine";
import { InventoryCountStatus } from "../model/InventoryCountStatus";
import { useCumulusToast } from "@cumulus/toast";
import { useToast } from "primevue/usetoast";
import { UpdateInventoryCountJobLinesRequest } from "../model/UpdateInventoryCountJobLinesRequest";
import CommentPanel from "./CommentPanel.vue";
import { ProductSearchRequest } from "@/repositories/search/model/product/ProductSearchRequest";
import { ProductSearchFilters } from "@/repositories/search/model/product/ProductSearchFilters";

import { useSearch } from "@/repositories/search/SearchService";
import { SearchProduct } from "@/repositories/search/model/product/SearchProduct";

const inventoryCountJob = ref<InventoryCountJob>(new InventoryCountJob());
const initialInventoryCountJob = ref<InventoryCountJob>(new InventoryCountJob());
const warehouse = ref<Warehouse>(new Warehouse());
const loading = ref<boolean>(false);
const isDeleting = ref<boolean>(false);
const isStarting = ref<boolean>(false);
const pageSize = ref<number>(50);
const correctLinesDialogVisible = ref<boolean>(false);
const failedLines = ref<InventoryCountLine[]>([]);
const sortOrder = ref(-1);
const sortField = ref("");
const query = ref("");
const page = ref(1);
const totalHits = ref(0);
const filters = ref<ProductSearchFilters>(new ProductSearchFilters());
const { productSearch } = useSearch();

const toast = useCumulusToast(useToast());
const { getById, deleteInventoryCountJob, startInventoryCountJob, updateInventoryCountJobLines } =
  useInventoryCountService();
const { getWarehouse } = useWarehouse();
const { stayOnPage, routeToSearchPage, handleCancelAction, unsavedChangesDialogVisible } = useRouteFunctions(
  inventoryCountJob,
  initialInventoryCountJob,
);
const route = useRoute();
const { t } = useI18n();

const products = ref<SearchProduct[]>([]);

const search = async () => {
  const id = route.params.id as string;
  inventoryCountJob.value = await getById(id);
  initialInventoryCountJob.value = { ...inventoryCountJob.value };

  try {
    const request = new ProductSearchRequest(query.value ? query.value : "*");
    request.includeAvailability = true;
    request.page = page.value;
    request.pageSize = pageSize.value;
    if (sortField.value === "") {
      sortOrder.value = -1;
    }
    request.sortBy = sortField.value;
    request.sortOrder = sortOrder.value === 1 ? "asc" : "desc";
    request.filters = filters.value;
    const response = await productSearch(request);

    products.value = response.products;
    totalHits.value = response.totalHits;
  } finally {
    loading.value = false;
  }
};

const getWarehouseById = async (id: string) => {
  warehouse.value = await getWarehouse(id);
};

const onUpdateSortInventoryOrder = (value: number) => {
  sortOrder.value = value;
  loading.value = true;
};

const onRefreshList = async () => {
  loading.value = true;
  await search();
};

const onFailedLineClicked = () => {
  failedLines.value = inventoryCountJob.value.lines.filter((line) => line.status === InventoryCountLineStatus.Failed);
  correctLinesDialogVisible.value = true;
};

const onDelete = async () => {
  if (
    inventoryCountJob.value.status !== InventoryCountStatus.Ready &&
    inventoryCountJob.value.status !== InventoryCountStatus.Failed
  ) {
    toast.add({
      severity: "warn",
      summary: t("inventory-count.delete-error"),
      detail: t("inventory-count.delete-invalid-status"),
    });
    return;
  }

  try {
    isDeleting.value = true;
    await deleteInventoryCountJob(inventoryCountJob.value.id);

    toast.add({
      severity: "success",
      summary: t("inventory-count.delete-success"),
      detail: t("inventory-count.delete-success-details", { number: inventoryCountJob.value.inventoryCountNumber }),
    });

    routeToSearchPage();
  } finally {
    isDeleting.value = false;
  }
};

const onStart = async () => {
  try {
    isStarting.value = true;
    await startInventoryCountJob(inventoryCountJob.value);

    toast.add({
      severity: "success",
      summary: t("inventory-count.start-success"),
      detail: t("inventory-count.start-success-details", { number: inventoryCountJob.value.inventoryCountNumber }),
    });

    routeToSearchPage();
  } finally {
    isStarting.value = false;
  }
};

const onSave = async () => {
  try {
    isStarting.value = true;

    const modifiedLines = inventoryCountJob.value.lines.filter((line) => {
      const initialLine = initialInventoryCountJob.value.lines.find((l) => l.id === line.id);
      return JSON.stringify(line) !== JSON.stringify(initialLine);
    });
    const request = new UpdateInventoryCountJobLinesRequest(inventoryCountJob.value.id, modifiedLines, []);
    await updateInventoryCountJobLines(request);

    toast.add({
      severity: "success",
      summary: t("inventory-count.save-success"),
      detail: t("inventory-count.save-success-details", { number: inventoryCountJob.value.inventoryCountNumber }),
    });

    routeToSearchPage();
  } finally {
    isStarting.value = false;
  }
};

const commentPanelRef = ref();
const showCommentPanel = (event: Event) => {
  const key = (event as KeyboardEvent).key;
  if (key === "Shift" || key === "Escape" || key === "Tab") {
    return;
  }

  commentPanelRef.value.toggle(event);
};

onMounted(async () => {
  try {
    loading.value = true;
    await search();
    await getWarehouseById(inventoryCountJob.value.warehouseId);
  } finally {
    loading.value = false;
  }
});
</script>

<style scoped lang="scss">
.c-margin {
  margin: var(--default-content-margin);
  overflow: hidden;
}
</style>
