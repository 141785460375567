<template>
  <FloatLabel variant="on">
    <InputText
      id="warehouse-postal-code"
      data-testid="warehouse-postal-code"
      v-model="postalCodeComputed"
      @change="onPostalCodeChange(($event.target as HTMLInputElement).value)"
      :class="{ 'p-invalid': validate.warehousePostalCode.$error }"
      class="inputfield w-full"
      maxlength="4"
      type="text"
      v-tooltip.focus.bottom="{
        value: t('placeholder.type', { property: t('common.address.postal-code').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
    />
    <label class="w-full" for="warehouse-postal-code">{{ t("common.address.postal-code") }}</label>
  </FloatLabel>
  <small class="p-error" v-if="validate.warehousePostalCode.$error" data-testid="warehouse-postal-code-error">
    {{ validate.warehousePostalCode.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";
import { countryRepository } from "@/repositories/country/CountryRepository";
import { computed } from "vue";
import { useAuth } from "@cumulus/event-bus";

const { getAuthHeaders } = useAuth();

const { t } = useI18n();

const props = defineProps<{
  warehousePostalCode: string;
  warehouseCity: string;
}>();

const emit = defineEmits<{
  (e: "update:warehouseCity", value: string): void;
  (e: "update:warehousePostalCode", value: string): void;
}>();

const postalCodeComputed = computed({
  get: (): string => {
    return props.warehousePostalCode;
  },
  set: (value: string) => {
    emit("update:warehousePostalCode", value);
  },
});

const onPostalCodeChange = async (code: string) => {
  try {
    if (code.trim() === "") {
      return;
    }

    const authHeaders = await getAuthHeaders();
    const response = await countryRepository.getCity(authHeaders, code);

    emit("update:warehouseCity", response[0].city);
  } catch {
    // Failed to get city
  }
};

const rules = {
  warehousePostalCode: {
    required,
  },
};

const validate = useVuelidate(rules, props);
</script>
