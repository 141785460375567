<template>
  <h4 class="text-center mt-4 mb-2">
    {{ t("order-ready-for-picking.shipping-date") }}
  </h4>
  <div class="formgrid grid grid-cols-12 gap-4">
    <div class="col-span-6 field text-center">
      <CumulusDatePicker
        v-model:date="fromDateComputed"
        :label="t('common.filters.filter-date-from')"
        :dataTestId="'from-date-filter'"
        :debounceTime="400"
      />
    </div>
    <div class="col-span-6 field text-center">
      <CumulusDatePicker
        v-model:date="toDateComputed"
        :label="t('common.filters.filter-date-to')"
        :dataTestId="'to-date-filter'"
        :debounceTime="400"
      />
    </div>
  </div>
  <hr class="c-seperator-line" />

  <h4 class="text-center mt-4 mb-2">
    {{ t("common.filters.filter-order-status") }}
  </h4>
  <div
    v-for="(value, index) in allStatusesComputed"
    :key="index"
    class="flex justify-between relative items-center p-2 rounded-md"
    :class="{
      'c-list-row-bg mt-1': index % 2 === 0,
      'c-list-alternate-row-bg mt-1': index % 2 === 1,
    }"
  >
    <label :for="'order-status-' + value.status.toLowerCase()">{{
      t(`common.status.${value.status.toLowerCase()}`)
    }}</label>
    <div class="ml-auto">
      <Checkbox
        v-model="statusesComputed"
        :value="value.status"
        :inputId="'order-status-' + value.status.toLowerCase()"
        :data-testid="'order-status-' + value.status.toLowerCase()"
      />
    </div>
  </div>

  <hr class="c-seperator-line" />

  <h4 class="text-center mt-4 mb-2">
    {{ t("common.filters.filter-customer") }}
  </h4>
  <AutoComplete
    v-model="customerNumbersComputed"
    class="w-full"
    data-testid="customer-number-filter"
    :placeholder="t('common.filters.filter-customer-number')"
    multiple
    fluid
    :typeahead="false"
    :loading="loading"
  ></AutoComplete>

  <MultiSelect
    id="customer-groups"
    v-model="selectedCustomerGroupsIdComputed"
    :placeholder="
      t('placeholder.select', {
        property: t('common.filters.filter-customer-group').toLowerCase(),
      })
    "
    optionLabel="name"
    optionValue="id"
    :options="customerGroups"
    display="chip"
    class="w-full field mt-4"
    :filter="true"
    :loading="loadingCustomerGroups"
    pt:list:data-testid="customer-groups-list"
  />

  <hr class="c-seperator-line" />

  <h4 class="text-center mt-4 mb-2">
    {{ t("common.filters.filter-product") }}
  </h4>
  <AutoComplete
    v-model="productNumbersComputed"
    class="w-full"
    data-testid="product-number-filter"
    :placeholder="t('common.filters.filter-product-number')"
    multiple
    fluid
    :typeahead="false"
    :loading="loading"
  ></AutoComplete>

  <hr class="c-seperator-line" />

  <h4 class="text-center mt-4 mb-2">
    {{ t("common.filters.filter-freight") }}
  </h4>
  <MultiSelect
    id="freight-methods"
    v-model="selectedFreightMethodComputed"
    :options="freightMethods"
    optionLabel="name"
    optionValue="id"
    class="w-full field mt-4"
    :placeholder="
      t('placeholder.select', {
        property: t('common.filters.filter-freight').toLowerCase(),
      })
    "
    display="chip"
    :filter="true"
    :loading="loadingFreightMethods"
    pt:list:data-testid="freight-methods-list"
  />
</template>

<script setup lang="ts">
import { CustomerGroup } from "@/repositories/customer-group/model/CustomerGroup";
import { type FreightMethod } from "@/repositories/freight-method/model/FreightMethod";
import { OrderStatus } from "@/repositories/search/model/order/OrderStatus";
import { SearchFilters } from "@/repositories/search/model/order/SearchFilters";
import { CumulusDatePicker } from "@cumulus/components";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const props = defineProps<{
  filters: SearchFilters;
  customerGroups: CustomerGroup[];
  freightMethods: FreightMethod[];
  loadingFreightMethods: boolean;
  loadingCustomerGroups: boolean;
  loading: boolean;
}>();

const emit = defineEmits<{
  (event: "update:filters", value: SearchFilters): void;
}>();

const dateFormatted = (value: string): string => {
  const date = value ? new Date(value) : new Date();
  const yyyy = date.getFullYear();
  const month = date.getMonth() + 1; // Months start at 0 index!
  const day = date.getDate();
  const mm = month < 10 ? "0" + month : month;
  const dd = day < 10 ? "0" + day : day;
  return yyyy + "-" + mm + "-" + dd;
};

const fromDateComputed = computed<string>({
  get: () => {
    if (props.filters.fromDate) {
      return dateFormatted(props.filters.fromDate);
    }
    return "";
  },
  set: (value) => {
    let fromDate: string | null = null;
    if (value === null || value === "") {
      if (props.filters.fromDate === null) return;
    } else {
      fromDate = new Date(value).toJSON();
      if (props.filters.fromDate === fromDate) return;
    }

    const filters = { ...props.filters, fromDate: fromDate };
    emit("update:filters", filters);
  },
});

const toDateComputed = computed<string>({
  get: () => {
    if (props.filters.toDate) {
      return dateFormatted(props.filters.toDate);
    }
    return "";
  },
  set: (value) => {
    let toDate: string | null = null;
    if (value === null || value === "") {
      if (props.filters.toDate === null) return;
    } else {
      toDate = new Date(value).toJSON();
      if (props.filters.toDate === toDate) return;
    }

    const filters = { ...props.filters, toDate: toDate };
    emit("update:filters", filters);
  },
});

const allStatusesComputed = computed(() => {
  const allStatuses = Object.values(OrderStatus);
  return allStatuses.map((status, index) => {
    return {
      status: status,
      index: index,
    };
  });
});

const statusesComputed = computed<OrderStatus[]>({
  get: () => props.filters.orderStatuses,
  set: (value) => {
    const filters = { ...props.filters, orderStatuses: value };
    emit("update:filters", filters);
  },
});

const customerNumbersComputed = computed<string[]>({
  get: () => props.filters.customerNumbers,
  set: (value) => {
    const filters = { ...props.filters, customerNumbers: value };
    emit("update:filters", filters);
  },
});

const productNumbersComputed = computed<string[]>({
  get: () => props.filters.productNumbers,
  set: (value) => {
    const filters = { ...props.filters, productNumbers: value };
    emit("update:filters", filters);
  },
});

const selectedCustomerGroupsIdComputed = computed<string[]>({
  get: () => props.filters.customerGroups,
  set: (value) => {
    const filters = { ...props.filters, customerGroups: value };
    emit("update:filters", filters);
  },
});

const selectedFreightMethodComputed = computed<string[]>({
  get: () => props.filters.freightMethods,
  set: (value) => {
    const filters = { ...props.filters, freightMethods: value };
    emit("update:filters", filters);
  },
});
</script>
<style scoped lang="scss">
h4,
label {
  font-family: var(--font-family);
}
.c-list-row-bg {
  background-color: var(--list-row-bg);
}
.c-list-alternate-row-bg {
  background-color: var(--list-alternate-row-bg);
}
:deep(ul) {
  width: 100%;
}
</style>
