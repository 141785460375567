import { NIL as emptyUuid } from "uuid";
import { InventoryCostChangedReason } from "./InventoryCostChangedReason";
import { InventoryCostType } from "./InventoryCostType";

export class InventoryProductCost {
  productId: string = emptyUuid;
  warehouseId: string = emptyUuid;
  costType: InventoryCostType = InventoryCostType.UnitCost;
  costPriceChange = 0;
  reason: InventoryCostChangedReason = InventoryCostChangedReason.CostAdjustment;
  comment = "";
  quantity = 0;
  totalInventoryCost = 0;
}
