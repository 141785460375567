<template>
  <label for="inventory-weight" class="c-text-ellipsis mb-2">{{
    t(`inventory.weight`, { unit: unitOfWeightComputed })
  }}</label>
  <div id="inventory-weight" data-testid="inventory-weight" class="c-col-color">{{ weightComputed }}</div>
</template>

<script setup lang="ts">
import { Weight } from "@/models/inventory/Weight";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  weight: Weight;
}>();

const unitOfWeightComputed = computed(() => props.weight?.unitOfWeight ?? "");
const weightComputed = computed(() => (props.weight && props.weight.value != 0 ? props.weight.value : ""));
</script>
<style scoped lang="scss">
.c-col-color {
  min-height: calc(2rem + 0.25rem);
  flex-grow: 1;
  background-color: var(--list-row-bg);
  padding: 0.5rem 1rem;
  border-radius: 3px;
}
.c-text-ellipsis {
  display: block;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
