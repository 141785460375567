<template>
  <label :for="props.id" class="c-text-ellipsis mb-2">{{ label }}</label>
  <div :id="props.id" :data-testid="props.id" class="c-col-color">{{ valueComputed }}</div>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = defineProps<{
  id: string;
  label: string;
  value: string | number;
  alignText: string;
}>();

const valueComputed = computed<string | number>(() => {
  if (props.value !== undefined && props.value !== null) {
    return props.value;
  } else {
    return "-";
  }
});
</script>
<style scoped lang="scss">
.c-col-color {
  min-height: calc(2rem + 0.25rem);
  flex-grow: 1;
  background-color: var(--list-row-bg);
  padding: 0.5rem 1rem;
  border-radius: 3px;
  text-align: v-bind(alignText);
  overflow-wrap: break-word;
}
.c-text-ellipsis {
  display: block;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
