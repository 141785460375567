import { NIL as emptyUuid } from "uuid";

export class PickingFreight {
  freightMethodId = "";
  freightMethodName = "";
  freightMethodTaxRate = 0;
  freightReference = "";
  shippingPrice = 0.0;
  shippingCost = 0.0;
  freightWeight = 0.0;
  freightPackages = 1;
  bringBookingDataId = emptyUuid;
}
