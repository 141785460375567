<template>
  <div class="c-chips">
    <ul class="c-chips-multiple-container" v-if="hasActiveFilters">
      <li class="c-chips-token-clear-filter">
        <span>{{ t("common.filters.clear-filter") }}</span>
        <span class="c-chips-token-icon pi pi-times-circle" @click="clearFilters" data-testid="clear-filter-btn">
        </span>
      </li>
      <FilterItem
        v-if="filters.fromDate !== null"
        filterType="fromDate"
        :values="d(filters.fromDate)"
        @removeFilter="removeFilter"
      />
      <FilterItem
        v-if="filters.toDate !== null"
        filterType="toDate"
        :values="d(filters.toDate)"
        @removeFilter="removeFilter"
      />
      <FilterItem
        v-if="filters.orderStatuses.length > 0"
        :values="filters.orderStatuses.map((status) => t(`common.status.${status.toLowerCase()}`))"
        filterType="orderStatuses"
        @removeFilter="removeFilter"
      />
      <FilterItem
        v-if="filters.customerNumbers.length > 0"
        :values="filters.customerNumbers"
        filterType="customerNumbers"
        @removeFilter="removeFilter"
      />
      <FilterItem
        v-if="filters.customerGroups.length > 0"
        :values="customerGroupsSelectedComputed"
        filterType="customerGroups"
        @removeFilter="removeFilter"
      />
      <FilterItem
        v-if="filters.productNumbers.length > 0"
        :values="filters.productNumbers"
        filterType="productNumbers"
        @removeFilter="removeFilter"
      />
      <FilterItem
        v-if="filters.freightMethods.length > 0"
        :values="freightMethodSelectedComputed"
        filterType="freightMethods"
        @removeFilter="removeFilter"
      />
    </ul>
  </div>
</template>
<script setup lang="ts">
import { SearchFilters } from "@/repositories/search/model/order/SearchFilters";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import FilterItem from "./FilterItem.vue";
import { CustomerGroup } from "@/repositories/customer-group/model/CustomerGroup";
import { FreightMethod } from "@/repositories/freight-method/model/FreightMethod";

const { t, d } = useI18n();

const props = defineProps<{
  filters: SearchFilters;
  customerGroups: CustomerGroup[];
  freightMethods: FreightMethod[];
}>();

const emit = defineEmits<{
  (event: "update:filters", value: SearchFilters): void;
}>();

const clearFilters = () => {
  const filters = new SearchFilters();
  emit("update:filters", filters);
};
const removeFilter = (filterType: keyof SearchFilters, index: number) => {
  const filters = { ...props.filters };
  const filterToRemove = filters[filterType];

  if (Array.isArray(filterToRemove)) {
    filterToRemove.splice(index, 1);
  } else if (filterType === "fromDate" || filterType === "toDate") {
    filters[filterType] = null;
  }

  emit("update:filters", filters);
};

const hasActiveFilters = computed<boolean>(() => {
  return (
    props.filters.orderStatuses.length > 0 ||
    props.filters.productNumbers.length > 0 ||
    props.filters.customerNumbers.length > 0 ||
    props.filters.customerGroups.length > 0 ||
    props.filters.freightMethods.length > 0 ||
    props.filters.fromDate !== null ||
    props.filters.toDate !== null
  );
});

const customerGroupsSelectedComputed = computed<string[]>(() =>
  props.customerGroups
    .filter((customerGroup) => props.filters.customerGroups.includes(customerGroup.id))
    .map((customerGroup) => customerGroup.name)
);

const freightMethodSelectedComputed = computed<string[]>(() =>
  props.freightMethods
    .filter((freightMethod) => props.filters.freightMethods.includes(freightMethod.id))
    .map((freightMethod) => freightMethod.name)
);
</script>
<style scoped lang="scss">
.c-chips {
  display: inline-flex;
}

.c-chips-multiple-container {
  padding: 0.25rem 0.5rem;
  margin: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.c-chips-token-clear-filter {
  padding: 0.25rem 0.5rem;
  margin-right: 0.5rem;
  color: var(--primary-color-text);
  border-radius: 1rem;
  background-color: var(--chips-bg-primary);
  display: inline-flex;
  align-items: center;
}

.c-chips-token-icon {
  margin-left: 0.5rem;
  cursor: pointer;
}
</style>
