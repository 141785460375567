<template>
  <div class="flex items-center -mt-2 pb-2 border-b">
    <div class="flex-1 text-color font-semibold leading-6">
      <Button
        class="text-sm"
        variant="text"
        size="small"
        @click="emit('navigateToInventoryClicked')"
        data-testid="btn-detailed-overview"
      >
        <i class="pi pi-search c-default-button c-circular-icon"></i>
        <span class="px-3">{{ t("inventory.search") }}</span>
      </Button>
    </div>

    <div class="ml-2">
      <Button
        data-testid="c-refresh-button"
        severity="secondary"
        variant="text"
        size="small"
        @click="emit('refreshList')"
      >
        <i class="pi pi-refresh"></i>
      </Button>
    </div>

    <div class="ml-2">
      <Button
        data-testid="filter-button"
        severity="secondary"
        variant="text"
        size="small"
        :class="{ 'c-toolbar-button-active': showSidebar }"
        @click="showSideBar = !showSidebar"
      >
        <i class="pi pi-filter"></i>
      </Button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useMobile } from "@/utils/MobileService";
import { InventoryChangeLogFilters } from "@/models/inventory/search/InventoryChangeLogFilters";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const showSideBar = defineModel<boolean>("showSidebar", {
  required: true,
});

const emit = defineEmits<{
  (e: "navigateToInventoryClicked"): void;
  (e: "update:filters", value: InventoryChangeLogFilters): void;
  (e: "refreshList"): void;
}>();

const { isMobile } = useMobile();
</script>
