export const OrderReadyForPickingListColumn = {
  SelectPickingList: "selectPickingList",
  ShippingDate: "shippingDate",
  OrderNumber: "orderNumber",
  CustomerNumber: "customer.customerNumber",
  CustomerName: "customer.customerName",
  OrderReference: "orderReference",
  FreightMethodName: "freightMethodName",
  QuantityOfOrderLines: "orderlines",
  SumTotalLines: "sumTotalLines",
} as const;
