import { Currency } from "./model/Currency";

class CurrencyRepository {
  public getAll(): Promise<Currency[]> {
    //TODO: Remove MOCK code!
    const promise = new Promise<Currency[]>((resolve) => {
      const response = [
        { id: "0", name: "NOK" },
        { id: "1", name: "SEK" },
        { id: "2", name: "EUR" },
        { id: "3", name: "AUD" },
        { id: "4", name: "BRL" },
        { id: "5", name: "CNY" },
        { id: "6", name: "EGP" },
        { id: "7", name: "INR" },
        { id: "8", name: "JPY" },
        { id: "9", name: "USD" },
        { id: "10", name: "CAD" },
        { id: "11", name: "EUR - Euro" },
        { id: "12", name: "BTC - Bitcoin" },
        { id: "13", name: "CRO - Cronos Coin" },
      ] as Currency[];

      resolve(response);
    });

    return promise;
    //TODO: END MOCK code!
  }
}

const currencyRepository = new CurrencyRepository();
export { currencyRepository };
