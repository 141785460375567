<template>
  <label for="inventory-comment">{{ t(`inventory.edit-inventory.comment`) }}</label>
  <InputText
    id="inventory-comment"
    v-model="commentComputed"
    class="inputfield w-full"
    data-testid="inventory-comment"
    :placeholder="t('placeholder.type', { property: t('inventory.edit-inventory.comment').toLowerCase() })"
  />
  <small class="p-error" v-if="validate.comment.$error" data-testid="inventory-comment-error">
    {{ validate.comment.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";
import { computed } from "vue";

const { t } = useI18n();

const props = defineProps<{
  comment: string;
}>();

const emit = defineEmits<{
  (e: "update:comment", value: string): void;
}>();

const commentComputed = computed<string>({
  get: (): string => {
    return props.comment;
  },
  set: (value: string) => {
    emit("update:comment", value);
  },
});

const rules = {
  comment: {
    required: required,
  },
};

const validate = useVuelidate(rules, props);
</script>
