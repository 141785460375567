<template>
  <div class="flex items-center -mt-2 pb-2 border-b">
    <div class="flex-1 text-color font-semibold leading-6">
      <div v-tooltip.bottom="savePickingTooltip" class="inline-block">
        <Button
          :disabled="pickingList === undefined || !startPickingAllowed"
          class="c-circular-button mr-4"
          @click="emit('startPicking')"
          data-testid="btn-start-picking"
        >
          <i class="pi pi-caret-right c-success-button c-circular-icon"></i>
          <span class="px-4">{{ startPickingButtonLabel }}</span>
        </Button>
      </div>
      <Button
        :disabled="pickingList === undefined || pickingList?.state !== PickingListState.Open"
        class="c-circular-button mr-4"
        @click="emit('deletePickingList')"
        data-testid="btn-delete-picking-list-toolbar"
      >
        <i class="pi pi-trash c-delete-button c-circular-icon"></i>
        <span class="px-4">{{ t("picking-list.context-menu.delete-picking-list") }}</span>
      </Button>

      <Button
        :disabled="pickingList === undefined"
        class="c-circular-button mr-4"
        @click="emit('showPickingList')"
        data-testid="btn-show-picking-list"
      >
        <i class="pi pi-sign-in c-default-button c-circular-icon button"></i>
        <span class="px-4">{{ t("picking-list.context-menu.show-picking-list") }}</span>
      </Button>
    </div>

    <div>
      <Button
        data-testid="btn-selected-icon"
        severity="secondary"
        variant="text"
        size="small"
        :class="{ 'c-toolbar-button-active': props.isSortingVisible }"
        @click="emit('openSortList', $event)"
      >
        <i :class="selectedIcon"></i>
      </Button>
    </div>
    <div class="ml-2">
      <Button
        data-testid="c-refresh-button"
        severity="secondary"
        variant="text"
        size="small"
        @click="emit('refreshList')"
      >
        <i class="pi pi-refresh"></i>
      </Button>
    </div>
    <div class="ml-2">
      <Button
        data-testid="btn-column-chooser"
        severity="secondary"
        variant="text"
        size="small"
        @click="emit('openColumnChooser', $event)"
      >
        <i class="pi pi-list"></i>
      </Button>
    </div>
    <div class="ml-2">
      <Button
        data-testid="filter-button"
        severity="secondary"
        variant="text"
        size="small"
        :class="{ 'c-toolbar-button-active': props.isFilterVisible }"
        @click="emit('onToggleFilterSidebar')"
      >
        <i class="pi pi-filter"></i>
      </Button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { computed, ref, onMounted } from "vue";
import { useAuth, User } from "@cumulus/event-bus";
import { PickingList } from "@/repositories/picking-list/model/PickingList";
import { PickingListState } from "@/repositories/picking-list/model/PickingListState";

const { t } = useI18n();

const user = ref<User>(new User());
const { getUser } = useAuth();
onMounted(async () => {
  user.value = await getUser();
});

const props = defineProps<{
  pickingList: PickingList | undefined;
  isSortingVisible: boolean;
  isFilterVisible: boolean;
}>();

const emit = defineEmits<{
  (e: "startPicking"): void;
  (e: "showPickingList"): void;
  (e: "deletePickingList"): void;
  (e: "onToggleFilterSidebar"): void;
  (e: "openSortList", value: Event): void;
  (e: "refreshList"): void;
  (e: "openColumnChooser", value: Event): void;
}>();

const startPickingAllowed = computed<boolean>(() => {
  if (!props.pickingList || props.pickingList === undefined) {
    return false;
  }
  return (
    props.pickingList.state === PickingListState.Open ||
    (props.pickingList.state === PickingListState.InPicking &&
      props.pickingList.assignedEmployeeId === user.value.getEmployee().id) ||
    props.pickingList.state === PickingListState.Parked
  );
});

const startPickingButtonLabel = computed<string>(() => {
  if (!props.pickingList || props.pickingList === undefined) {
    return t("picking-list.context-menu.start-picking");
  }

  if (props.pickingList.state === PickingListState.Parked) {
    return t("picking-list.context-menu.continue-picking");
  } else {
    return t("picking-list.context-menu.start-picking");
  }
});

const savePickingTooltip = computed(() => {
  return !props.pickingList
    ? { value: t("picking-list.select-picking-list"), showDelay: 100, hideDelay: 300 }
    : { value: "" };
});

const selectedIcon = defineModel<string>("selectedIcon", { required: true, default: "pi pi-sort-amount-up" });
</script>
