<template>
  <h4 class="text-center">{{ t("inventory.change-log.change-date") }}</h4>
  <div class="formgrid grid">
    <div class="col-6 field text-center">
      <CumulusDatePicker
        v-model:date="fromDateComputed"
        :label="t('inventory.change-log.from-date', { symbol: ':' })"
        :dataTestId="'from-date-filter'"
        :debounceTime="400"
      />
    </div>
    <div class="col-6 field text-center">
      <CumulusDatePicker
        v-model:date="toDateComputed"
        :label="t('inventory.change-log.to-date', { symbol: ':' })"
        :dataTestId="'to-date-filter'"
        :debounceTime="400"
      />
    </div>
  </div>

  <hr class="c-seperator-line" />

  <h4 class="text-center">
    <label for="product-number-filter">
      {{ t("inventory.change-log.reasons") }}
    </label>
  </h4>

  <AutoComplete
    v-model="reasonsComputed"
    class="w-full"
    data-testid="reasons-filter"
    inputId="reasons-filter"
    :placeholder="props.filters.reasons.length > 0 ? '' : t('inventory.change-log.reason')"
    multiple
    fluid
    :typeahead="false"
  ></AutoComplete>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { InventoryChangeLogFilters } from "@/models/inventory/search/InventoryChangeLogFilters";
import { CumulusDatePicker } from "@cumulus/components";

const { t } = useI18n();

const props = defineProps<{
  filters: InventoryChangeLogFilters;
}>();

const emit = defineEmits<{
  (event: "update:filters", value: InventoryChangeLogFilters): void;
}>();

const fromDateComputed = computed<string>({
  get: () => {
    return props.filters.fromDate ? props.filters.fromDate : "";
  },
  set: (fromDate) => {
    if (props.filters.fromDate === fromDate) return;

    const filters = { ...props.filters, fromDate: fromDate };
    emit("update:filters", filters);
  },
});

const toDateComputed = computed<string>({
  get: () => {
    return props.filters.toDate ? props.filters.toDate : "";
  },
  set: (toDate) => {
    toDate = toDate.replace("T00:00:00.000Z", "T23:59:59.999Z");
    if (props.filters.toDate === toDate) return;

    const filters = { ...props.filters, toDate: toDate };
    emit("update:filters", filters);
  },
});

const reasonsComputed = computed<string[]>({
  get: () => props.filters.reasons,
  set: (value) => {
    const filters = { ...props.filters, reasons: value };
    emit("update:filters", filters);
  },
});
</script>
