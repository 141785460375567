<template>
  <div class="c-toolbar-wrapper">
    <div class="c-toolbar">
      <div class="c-header-icon-container">
        <PrimeButton
          class="c-circular-button mr-3"
          @click="emit('navigateToInventoryClicked')"
          data-testid="btn-detailed-overview"
        >
          <i class="pi pi-search c-default-button c-circular-icon"></i>
          <span class="px-3">{{ t("inventory.search") }}</span>
        </PrimeButton>
      </div>
      <div class="c-header-filter-container c-header-icon-container">
        <div v-if="!isMobile">
          <FilterOverview v-model:filters="filters" />
        </div>
        <PrimeButton class="c-circular-button" @click="showSideBar = !showSidebar" data-testid="filter-button">
          <i class="pi pi-filter c-default-button c-circular-icon"></i>
          <span class="px-3">{{
            showSidebar ? t("inventory.change-log.hide-filter") : t("inventory.change-log.show-filter")
          }}</span>
        </PrimeButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useMobile } from "@/MobileService";
import { InventoryChangeLogFilters } from "@/models/inventory/search/InventoryChangeLogFilters";
import { useI18n } from "vue-i18n";
import FilterOverview from "./InventoryChangesFilterOverview.vue";

const { t } = useI18n();

const showSideBar = defineModel<boolean>("showSidebar", {
  required: true,
});

const filters = defineModel<InventoryChangeLogFilters>("filters", {
  required: true,
});

const emit = defineEmits<{
  (e: "navigateToInventoryClicked"): void;
  (e: "update:filters", value: InventoryChangeLogFilters): void;
}>();

const { isMobile } = useMobile();
</script>
