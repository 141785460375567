<template>
  <div class="card">
    <ColumnOptionsMenu :label="t('inventory.header.inventory')" :itemsList="items"></ColumnOptionsMenu>
    <DataTable
      :value="products"
      dataKey="id"
      :autoLayout="true"
      responsiveLayout="scroll"
      selectionMode="single"
      class="c-datatable"
      :loading="loading"
      contextMenu
      v-model:contextMenuSelection="selectedProductComputed"
      @rowContextmenu="onRowContextMenu"
      @row-select="onRowSelected"
      @row-dblclick="onRowDoubleClicked"
      v-model:selection="selectedProductComputed"
      paginator
      :rows="pageSize"
      :rowsPerPageOptions="[50, 100]"
      stripedRows
      data-testid="inventory-product-list-table"
      ref="inventoryProductTable"
      :lazy="true"
      :totalRecords="totalHits"
      :currentPageReportTemplate="
        t('common.current-page-template', { first: '{first}', last: '{last}', totalRecords: '{totalRecords}' })
      "
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :sortOrder="sortOrder"
      :sortField="sortField"
      @sort="onSort"
      @page="onPage"
      scrollable
      scrollHeight="75vh"
      :resizableColumns="true"
      columnResizeMode="fit"
      removableSort
      @column-resize-end="onColumnResizeEnd"
      :reorderable-columns="true"
      @column-reorder="onColumnReorder"
      :key="renderKey as unknown as number"
    >
      <Column
        v-for="(col, index) of selectedColumnsComputed as unknown as DataTableColumn[]"
        :field="col.field"
        :header="col.header"
        :key="col.field + '_' + index"
        :class="col.class"
        :sortable="col.sortable"
        :pt="{
          headerCell: {
            id: col.field,
          },
        }"
        :style="col.size ? `width: ${col.size}px; max-width: ${col.size}px;` : ''"
      >
        <template #body="{ data, field, index }">
          <template v-if="col.field === InventoryProductListColumn.ProductNumber">
            {{ data[field as keyof typeof data] }}
          </template>
          <template v-else-if="col.field === InventoryProductListColumn.Name">
            <div class="flex justify-between items-center">
              <span class="c-product-name-text" @click="onProductNameClicked(data, index)">{{ data.name }}</span>
              <i
                role="button"
                class="pi pi-ellipsis-v c-context-menu-button"
                @click="(e) => openEllipsisContextMenu(e, data, index)"
                data-testid="context-menu-button"
              >
              </i>
            </div>
          </template>
          <template v-else-if="col.field === InventoryProductListColumn.Gtin">
            <span>{{ data.gtin }}</span>
          </template>
          <template v-else-if="col.field === InventoryProductListColumn.Inventory">
            {{ getProductStock(data) }}
          </template>
          <template v-else-if="col.field === InventoryProductListColumn.InPickList">
            {{ getProductAllocatedForPick(data) }}
          </template>
          <template v-else-if="col.field === InventoryProductListColumn.Available">
            {{ getProductAvailable(data) }}
          </template>
          <template v-else-if="col.field === InventoryProductListColumn.CostPrice">
            {{ getProductCostPrice(data) }}
          </template>
          <template v-else-if="col.field === InventoryProductListColumn.TotalInventoryCost">
            {{ getInventoryValue(data) }}
          </template>
          <template v-else>
            {{ data[field as keyof typeof data] }}
          </template>
        </template>
      </Column>

      <template #loading>
        <span v-if="loading">{{ t("common.loading") }}</span>
      </template>
      <template #empty>
        <span>{{ t("inventory.no-products-found") }}</span>
      </template>
    </DataTable>

    <ColumnChooser
      v-model:visibleDialog="visible"
      v-model:selectedColumns="selectedColumnsComputed"
      :columns="filteredColumns"
      :label="t('common.reset')"
      :onSelectAllChange="onSelectAllChange"
      :selectAll="selectAll"
      @resetColumns="resetColumns"
      @columnSelected="onColumnSelected"
      @columnUnselected="onColumnUnselected"
    />
    <ContextMenu :model="menuModel" ref="contextMenu" pt:root:data-testid="inventory-context-row-menu" />
    <ContextMenu :model="ellipsisMenuModel" ref="ellipsisContextMenu" />
  </div>
</template>

<script setup lang="ts">
import { SearchProduct } from "@/repositories/search/model/product/SearchProduct";
import {
  type DataTablePageEvent,
  type DataTableRowContextMenuEvent,
  type DataTableRowDoubleClickEvent,
  type DataTableRowSelectEvent,
  type DataTableSelectAllChangeEvent,
  type DataTableSortEvent,
} from "primevue/datatable";
import { computed, ref, nextTick } from "vue";
import { useI18n } from "vue-i18n";
import { InventoryProductListColumn } from "@/models/inventory/search/InventoryProductListColumns";
import { ColumnChooser, useTablePreferences, ColumnOptionsMenu } from "@cumulus/components";
import type { DataTableColumn } from "@cumulus/components";

const contextMenu = ref();
const ellipsisContextMenu = ref();
const inventoryProductTable = ref();
const previouslyFocusedRow = ref();
const visible = ref(false);
const isTransferContextVisible = ref(false);

const { t, n } = useI18n();

const props = defineProps<{
  products: SearchProduct[];
  selectedInventoryProduct: SearchProduct | null;
  selectedWarehouseId: string;
  loading: boolean;
  totalHits: number;
  pageSize: number;
  page: number;
  sortOrder: number;
  sortField: string;
}>();

const emit = defineEmits<{
  (e: "update:sortOrder", value: number): void;
  (e: "update:sortField", value: string): void;
  (e: "update:page", value: number): void;
  (e: "update:pageSize", value: number): void;
  (e: "rowSelected", payload: DataTableRowSelectEvent): void;
  (e: "rowDoubleClicked", payload: DataTableRowDoubleClickEvent): void;
  (e: "openInventoryProductOverview"): void;
  (e: "openEditInventoryQuantity"): void;
  (e: "openEditInventoryCost"): void;
  (e: "update:selectedInventoryProduct", payload: SearchProduct | null): void;
  (e: "inventoryRefresh"): void;
  (e: "openTransferInventory"): void;
}>();

const inventoryProductSearchColumns = [
  { field: "productNumber", header: t("inventory.productnumber"), sortable: true },
  {
    field: "name",
    header: t("inventory.productname"),
    sortable: true,
  },
  {
    field: "gtin",
    header: t("inventory.ean"),
    sortable: true,
  },
  {
    field: "availability.quantityInStock",
    header: t("inventory.quantity-in-inventory"),
    class: "text-center",
    sortable: true,
  },
  {
    field: "availability.quantityAllocatedForPicking",
    header: t("inventory.quantity-in-picking-list"),
    sortable: true,
  },

  {
    field: "availability.quantityAvailableFromStock",
    header: t("inventory.quantity-available"),
    sortable: true,
  },
  {
    field: "availability.costPrice",
    header: t("inventory.cost-price"),
    sortable: true,
  },
  {
    field: "availability.totalInventoryCost",
    header: t("inventory.total-inventory-cost"),
    sortable: true,
    class: "text-center",
  },
];

const clearSelectedRow = (newValue: boolean) => {
  if (newValue) {
    return;
  }
  clearSelectedInventoryProduct();

  if (previouslyFocusedRow.value) {
    previouslyFocusedRow.value.focus();
  }
};

defineExpose({
  clearSelectedRow,
});

const {
  selectedColumnsComputed,
  renderKey,
  onColumnReorder,
  resetColumns,
  orderedColumns,
  onColumnResizeEnd,
  onColumnSelected,
  onColumnUnselected,
} = useTablePreferences("inventoryProductSearch", inventoryProductSearchColumns, null);

const filteredColumns = computed(() => {
  return orderedColumns.value.filter(
    (col) => col.field !== InventoryProductListColumn.ProductNumber && col.field !== InventoryProductListColumn.Name,
  );
});

const selectedProductComputed = computed<SearchProduct>({
  get: () => props.selectedInventoryProduct ?? new SearchProduct(),
  set: (product) => emit("update:selectedInventoryProduct", product),
});

const getProductStock = (product: SearchProduct) => {
  const availability = product.availability.find((a) => a.warehouseId === props.selectedWarehouseId);
  return availability ? availability.quantityInStock : 0;
};

const getProductAllocatedForPick = (product: SearchProduct) => {
  const availability = product.availability.find((a) => a.warehouseId === props.selectedWarehouseId);
  return availability ? availability.quantityAllocatedForPicking : 0;
};

const getProductAvailable = (product: SearchProduct) => {
  const availability = product.availability.find((a) => a.warehouseId === props.selectedWarehouseId);
  return availability ? availability.quantityAvailableFromStock : 0;
};

const getProductCostPrice = (product: SearchProduct) => {
  const availability = product.availability.find((a) => a.warehouseId === props.selectedWarehouseId);

  return availability ? n(availability.costPrice, "decimal") : 0;
};

const getInventoryValue = (product: SearchProduct) => {
  const availability = product.availability.find((a) => a.warehouseId === props.selectedWarehouseId);
  return availability?.totalInventoryCost ?? 0;
};

const onProductNameClicked = (product: SearchProduct, index: number) => {
  setFocusedRow(index);
  selectedProductComputed.value = product;
  emit("openInventoryProductOverview");
};

const openEllipsisContextMenu = (event: Event, data: SearchProduct, index: number) => {
  setFocusedRow(index);
  selectedProductComputed.value = data;
  ellipsisContextMenu.value.show(event);
};

const onPage = async (event: DataTablePageEvent) => {
  nextTick(() => {
    emit("update:page", event.page + 1);
    emit("update:pageSize", event.rows);
  });
};

const onSort = async (event: DataTableSortEvent) => {
  let sortField = "";
  nextTick(() => {
    if (typeof event.sortField === "string") {
      sortField = event.sortField;
    }
    emit("update:sortOrder", -props.sortOrder);
    emit("update:sortField", sortField);
    emit("update:page", 1);
  });
};

const items = [
  {
    label: t("common.reload"),
    icon: "pi pi-refresh c-default-button c-circular-icon",
    command: () => {
      onClickInventoryRefresh();
    },
  },
  {
    label: t("common.column-chooser"),
    icon: "pi pi-list c-default-button c-circular-icon",

    class: "c-column-chooser",
    command: () => {
      visible.value = true;
    },
  },
];

const menuModel = ref([
  {
    label: t("inventory.context-menu.detailed-overview"),
    icon: "pi pi-fw pi-search",
    command: () => emit("openInventoryProductOverview"),
  },
  {
    label: t("inventory.edit-transfer.btn"),
    visible: isTransferContextVisible,
    icon: "pi pi-fw pi-pencil",
    command: () => emit("openTransferInventory"),
  },
  {
    label: t("inventory.context-menu.change-inventory"),
    icon: "pi pi-fw pi-pencil",
    command: () => emit("openEditInventoryQuantity"),
  },
  {
    label: t("inventory.context-menu.change-cost"),
    icon: "pi pi-fw pi-pencil",
    command: () => emit("openEditInventoryCost"),
  },
]);

const ellipsisMenuModel = ref([
  {
    label: t("inventory.context-menu.detailed-overview"),
    icon: "pi pi-fw pi-search",
    command: () => emit("openInventoryProductOverview"),
  },
  {
    label: t("inventory.context-menu.change-inventory"),
    icon: "pi pi-fw pi-pencil",
    command: () => emit("openEditInventoryQuantity"),
  },
  {
    label: t("inventory.context-menu.change-cost"),
    icon: "pi pi-fw pi-pencil",
    command: () => emit("openEditInventoryCost"),
  },
  {
    label: t("inventory.context-menu.copy"),
    icon: "pi pi-copy",
    command: () => copyToClipboard(),
  },
]);

const selectAll = ref(false);
const onSelectAllChange = (event: DataTableSelectAllChangeEvent) => {
  selectAll.value = event.checked;
  selectedColumnsComputed.value = event.checked
    ? inventoryProductSearchColumns
    : inventoryProductSearchColumns.filter(
        (c) => c.field === InventoryProductListColumn.ProductNumber || c.field === InventoryProductListColumn.Name,
      );
};

const onRowSelected = (event: DataTableRowSelectEvent) => {
  setFocusedRow(event.index);
  emit("rowSelected", event);
};

const onRowDoubleClicked = (event: DataTableRowDoubleClickEvent) => {
  setFocusedRow(event.index);
  emit("rowDoubleClicked", event);
};

const clearSelectedInventoryProduct = () => {
  selectedProductComputed.value = new SearchProduct();
};

const copyToClipboard = () => {
  navigator.clipboard.writeText(selectedProductComputed.value.name);
};

const onRowContextMenu = (event: DataTableRowContextMenuEvent) => {
  event.data.warehouseIds.length > 1
    ? (isTransferContextVisible.value = true)
    : (isTransferContextVisible.value = false);

  setFocusedRow(event.index);
  selectedProductComputed.value = event.data as SearchProduct;
  contextMenu.value.show(event.originalEvent);
};

const setFocusedRow = (index: number) => {
  previouslyFocusedRow.value = inventoryProductTable.value.$el.querySelector(`tbody tr:nth-child(${index + 1})`);
};

const onClickInventoryRefresh = () => {
  emit("inventoryRefresh");
};
</script>

<style scoped lang="scss">
.c-multiselect-wrapper {
  position: absolute;
  top: 0px;
  right: 10px;
  z-index: 9;
}

:deep(.p-paginator) {
  .p-paginator-first {
    margin-left: auto;
  }
  .p-paginator-current {
    margin-left: auto;
  }
}

.c-context-menu-button {
  visibility: hidden;
  font-size: 16px;
}
.p-datatable-selectable-row:hover .c-context-menu-button {
  visibility: visible;
}
.p-datatable-selectable-row:focus .c-context-menu-button {
  visibility: visible;
}

.p-highlight .c-context-menu-button {
  visibility: visible;
}
.c-context-menu-button:hover {
  outline: 4px solid;
  outline-color: rgba(255, 255, 255, 0.3);
  background: rgba(255, 255, 255, 0.3);
}
.c-context-menu-button:active {
  outline: 4px solid;
  outline-color: rgba(255, 255, 255, 0.3);
  background: rgba(255, 255, 255, 0.3);
}
.c-product-name-text:hover {
  text-decoration: underline;
}
</style>
