<template>
  <PrimeDialog
    v-model:visible="visible"
    :header="t('inventory.product-modal.overview-header', { product: inventoriedProduct.productName })"
    :modal="true"
    :breakpoints="{ '999px': '90vw', '640px': '95vw' }"
  >
    <div v-if="loading" class="c-loading-overlay">
      <ProgressSpinner class="w-16 h-16" />
    </div>
    <div v-if="loadFailed">
      {{ t("inventory.load-failed-inventoried-product") }}
    </div>
    <div v-else>
      <div class="grid grid-cols-12 gap-4 mb-4">
        <div class="col-span-12 md:col-span-6 text-center">
          <ValueWithHeader
            alignText="center"
            id="inventory-product-name"
            :label="t('inventory.productname')"
            :value="inventoriedProduct.productName"
          />
        </div>
        <div class="col-span-12 md:col-span-3 text-center">
          <ValueWithHeader
            alignText="center"
            id="inventory-product-number"
            :label="t('inventory.productnumber')"
            :value="inventoriedProduct.productNumber"
          />
        </div>
        <div class="col-span-12 md:col-span-3 text-center">
          <ValueWithHeader
            alignText="center"
            id="inventory-gti-number"
            :label="t('inventory.ean')"
            :value="inventoriedProduct.gtin"
          />
        </div>
        <div class="col-span-12 md:col-span-6 text-center">
          <ProductHierarchy :productHierarchyId="inventoriedProduct.productHierarchyId" />
        </div>
        <div class="col-span-12 md:col-span-3 text-center">
          <Dimension :dimension="inventoriedProduct.dimension" />
        </div>
        <div class="col-span-12 md:col-span-3 text-center">
          <Weight :weight="inventoriedProduct.weight" />
        </div>
        <div class="col-span-12 md:col-span-6 text-center">
          <ValueWithHeader
            alignText="left"
            id="inventory-main-supplier-name"
            :label="t(`inventory.main-supplier`)"
            :value="inventoriedProduct.mainSupplierName"
          />
        </div>
        <div class="col-span-12 md:col-span-6 text-center">
          <ValueWithHeader
            alignText="right"
            id="inventory-main-supplier-product-number"
            :label="t(`inventory.main-supplier-productnumber`)"
            :value="inventoriedProduct.mainSuppliersProductNumber"
          />
        </div>
      </div>
    </div>

    <Card class="mb-4">
      <template #content>
        <div class="grid grid-cols-12 gap-4 mb-4">
          <div class="col-span-12 md:col-span-2 text-center">
            <ValueWithHeader
              alignText="right"
              id="inventory-quantity-in-inventory"
              :label="t(`inventory.quantity-in-inventory`)"
              :value="inventoriedProduct.quantityInStock"
            />
          </div>
          <div class="col-span-12 md:col-span-2 text-center">
            <ValueWithHeader
              alignText="right"
              id="inventory-quantity-in-reserve"
              :label="t(`inventory.quantity-order-reserve`)"
              :value="inventoriedProduct.quantityInOrders"
            />
          </div>
          <div class="col-span-12 md:col-span-2 text-center">
            <ValueWithHeader
              alignText="right"
              id="inventory-quantity-in-picking-list"
              :label="t(`inventory.quantity-in-picking-list`)"
              :value="inventoriedProduct.quantityAllocatedForPicking"
            />
          </div>
          <div class="col-span-12 md:col-span-2 text-center">
            <ValueWithHeader
              alignText="right"
              id="inventory-quantity-available-for-sale"
              :label="t(`inventory.quantity-available`)"
              :value="inventoriedProduct.quantityAvailableFromStock"
            />
          </div>
          <div class="col-span-12 md:col-span-2 text-center">
            <ValueWithHeader
              alignText="right"
              id="inventory-cost-price"
              :label="t(`inventory.cost-price`)"
              :value="inventoriedProduct.costPrice"
            />
          </div>
          <div class="col-span-12 md:col-span-2 text-center">
            <ValueWithHeader
              alignText="right"
              id="inventory-total-cost-price"
              :label="t(`inventory.total-inventory-cost`)"
              :value="inventoriedProduct.totalInventoryCost"
            />
          </div>
        </div>
        <div>
          <Batches :batches="inventoriedProduct.batches" />
        </div>
      </template>
    </Card>

    <template #footer>
      <div class="flex flex-row-reverse justify-between pt-8">
        <div class="flex justify-between">
          <PrimeButton
            class="c-dialog-default-button mr-6"
            :label="t('inventory.context-menu.change-cost')"
            data-testid="change-inventory-cost-btn"
            @click="emit('editInventoryProductCost')"
          />
          <PrimeButton
            class="c-dialog-default-button"
            :label="t('inventory.context-menu.change-inventory')"
            data-testid="change-inventory-btn"
            @click="emit('editInventoryProductQuantity')"
          />
        </div>
        <PrimeButton
          class="c-dialog-default-button mr-6"
          :label="t(`common.cancel`)"
          data-testid="cancel-btn"
          @click="visible = false"
          text
        />
      </div>
    </template>
  </PrimeDialog>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import ProductHierarchy from "../../product-hierarchy/components/ProductHierarchy.vue";
import Dimension from "../overview/Dimension.vue";
import Weight from "../overview/Weight.vue";
import Batches from "../overview/Batches.vue";
import ValueWithHeader from "../overview/ValueWithHeader.vue";
import { InventoriedProductInfo } from "@/models/inventory/InventoriedProductInfo";
import { useInventory } from "@/api/inventory/InventoryService";

const props = defineProps<{
  showOverviewDialog: boolean;
  productId: string;
  warehouseId: string;
}>();

const emit = defineEmits<{
  (e: "update:showOverviewDialog", value: boolean): void;
  (e: "editInventoryProductQuantity"): void;
  (e: "editInventoryProductCost"): void;
}>();

const { t } = useI18n();
const { getProductWithBatches } = useInventory();

const loading = ref(false);
const loadFailed = ref(false);
const inventoriedProduct = ref<InventoriedProductInfo>(new InventoriedProductInfo());

const visible = computed<boolean>({
  get: () => props.showOverviewDialog,
  set: (value) => emit("update:showOverviewDialog", value),
});

const fetchProductWithBatches = async () => {
  if (!props.warehouseId || !props.productId) return;

  loading.value = true;
  try {
    inventoriedProduct.value = await getProductWithBatches(props.warehouseId, props.productId);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    loadFailed.value = true;
  } finally {
    loading.value = false;
  }
};

onMounted(async () => {
  await fetchProductWithBatches();
});
</script>

<style scoped lang="scss">
.c-loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(26, 26, 26, 0.2);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
