import { PickingFreight } from "./PickingFreight";
import { PickingListLine } from "./PickingListLine";

export class ConfirmPickingModel {
  pickingListId = "";
  lines: PickingListLine[] = [];
  freight: PickingFreight = new PickingFreight();
  shippingLabelPrinter = "";
  packingNotePrinter = "";
}
