import { useAuth } from "@cumulus/event-bus";
import { useErrorHandler } from "@/repositories/ErrorHandler";
import { InventoryCountJobInfo } from "../model/InventoryCountJobInfo";
import { inventoryCountApi } from "./InventoryCountApi";
import { InventoryCountJob } from "../model/InventoryCountJob";
import { UpdateInventoryCountJobLinesRequest } from "../model/UpdateInventoryCountJobLinesRequest";

export function useInventoryCountService() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getAll = async (): Promise<InventoryCountJobInfo[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await inventoryCountApi.getAll(authHeaders);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getById = async (id: string): Promise<InventoryCountJob> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await inventoryCountApi.getById(authHeaders, id);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const importExcel = async (warehouseId: string, formData: FormData): Promise<void> => {
    try {
      const authHeaders = await getAuthHeaders();
      await inventoryCountApi.importExcel(authHeaders, warehouseId, formData);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const importCsv = async (warehouseId: string, formData: FormData): Promise<void> => {
    try {
      const authHeaders = await getAuthHeaders();
      await inventoryCountApi.importCsv(authHeaders, warehouseId, formData);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getWebPubSubUrl = async (): Promise<string> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await inventoryCountApi.getWebPubSubUrl(authHeaders);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const updateInventoryCountJobLines = async (request: UpdateInventoryCountJobLinesRequest): Promise<void> => {
    try {
      const authHeaders = await getAuthHeaders();
      await inventoryCountApi.updateInventoryCountJobLines(authHeaders, request);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const deleteInventoryCountJob = async (id: string): Promise<void> => {
    try {
      const authHeaders = await getAuthHeaders();
      await inventoryCountApi.deleteInventoryCountJob(authHeaders, id);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const startInventoryCountJob = async (inventoryCountJob: InventoryCountJob): Promise<void> => {
    try {
      const authHeaders = await getAuthHeaders();
      await inventoryCountApi.startInventoryCountJob(authHeaders, inventoryCountJob);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return {
    getAll,
    getById,
    importExcel,
    importCsv,
    getWebPubSubUrl,
    updateInventoryCountJobLines,
    deleteInventoryCountJob,
    startInventoryCountJob,
  };
}
