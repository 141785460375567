import { packingApi } from "./PackingApi";
import { useErrorHandler } from "../../../repositories/ErrorHandler";
import { useAuth } from "@cumulus/event-bus";
import { PackingNote } from "../models/PackingNote";
import { ref } from "vue";
import { defineStore } from "pinia";

export const usePackingNoteStore = defineStore("packingNote", () => {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const packingNotes = ref<PackingNote[]>([]);
  const loading = ref(false);

  const getPackingNotes = async (): Promise<void> => {
    try {
      loading.value = true;
      const authHeaders = await getAuthHeaders();
      packingNotes.value = await packingApi.list(authHeaders);
    } catch (error) {
      await handleError(error);
      throw error;
    } finally {
      loading.value = false;
    }
  };

  const getPackingNote = async (id: string): Promise<PackingNote> => {
    try {
      loading.value = true;
      const authHeaders = await getAuthHeaders();
      const packingNote = await packingApi.get(authHeaders, id);
      const index = packingNotes.value.findIndex((packingNote) => packingNote.id === id);
      if (index !== -1) {
        packingNotes.value[index] = packingNote;
      } else {
        packingNotes.value.push(packingNote);
      }
      return packingNote;
    } catch (error) {
      await handleError(error);
      throw error;
    } finally {
      loading.value = false;
    }
  };

  return {
    packingNotes,
    loading,
    getPackingNotes,
    getPackingNote,
  };
});
