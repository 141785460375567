<template>
  <DataTable :value="parcels" class="c-datatable">
    <Column field="parcelNumber" header="Parcel">
      <template #body="{ data, field }">
        <InputNumber disabled v-model="data[field]" inputClass="w-12" :placeholder="data[field].toString()" />
      </template>
    </Column>
    <Column field="content" :header="t('checkout.content')">
      <template #body="{ data, field, index }">
        <InputText v-model="data[field]" :data-testid="`parcel-content-${index}`" />
      </template>
    </Column>
    <Column field="weight" :header="t('checkout.weight')">
      <template #body="{ data, field, index }">
        <InputNumber
          v-model="data[field]"
          inputClass="w-16"
          :minFractionDigits="0"
          :maxFractionDigits="2"
          :data-testid="`parcel-weight-${index}`"
          @update:model-value="emit('weightChanged')"
        />
      </template>
    </Column>
    <Column field="length" :header="t('checkout.length')">
      <template #body="{ data, field, index }">
        <InputNumber
          v-model="data[field]"
          inputClass="w-16"
          :data-testid="`parcel-length-${index}`"
          @update:model-value="emit('dimensionsChanged')"
        />
      </template>
    </Column>
    <Column field="width" :header="t('checkout.width')">
      <template #body="{ data, field, index }">
        <InputNumber
          v-model="data[field]"
          inputClass="w-16"
          :data-testid="`parcel-width-${index}`"
          @update:model-value="emit('dimensionsChanged')"
        />
      </template>
    </Column>
    <Column field="height" :header="t('checkout.height')">
      <template #body="{ data, field, index }">
        <InputNumber
          v-model="data[field]"
          inputClass="w-16"
          :data-testid="`parcel-height-${index}`"
          @update:model-value="emit('dimensionsChanged')"
        />
      </template>
    </Column>
    <Column>
      <template #body="{ index }">
        <PrimeButton
          severity="danger"
          :disabled="parcels.length <= 1"
          icon="pi pi-trash"
          class="c-danger-button"
          @click="deleteParcel(index)"
        />
      </template>
    </Column>
    <template #footer>
      <small class="p-error" data-testid="parcel-validation-error" if="val.parcels.$error">
        {{ val.parcels.$errors[0] && val.parcels.$errors[0].$response.$message }}
      </small>
      <div class="flex mt-2">
        <PrimeButton
          :label="t('checkout.add-parcel')"
          icon="pi pi-plus-circle"
          @click="addParcel"
          class="p-button-text c-black-text"
          iconClass="c-success-icon"
          data-testid="btn-add-parcel"
        />
      </div>
    </template>
  </DataTable>
</template>

<script setup lang="ts">
import { Parcel } from "@/goods-out/bring-integration/Parcel";
import { useI18n } from "vue-i18n";
import useValidate from "@vuelidate/core";

const { t } = useI18n();
const addParcel = () => {
  parcels.value = [...parcels.value, new Parcel((parcels.value[parcels.value.length - 1]?.parcelNumber ?? 0) + 1)];
  emit("parcelAdded");
};
const emit = defineEmits<{
  (e: "weightChanged"): void;
  (e: "parcelDeleted"): void;
  (e: "parcelAdded"): void;
  (e: "dimensionsChanged"): void;
}>();

const parcels = defineModel<Parcel[]>("parcels", {
  required: true,
  default: [new Parcel(1)],
});

const deleteParcel = (index: number) => {
  if (parcels.value.length <= 1) return;
  parcels.value.splice(index, 1);
  parcels.value.forEach((x, index) => (x.parcelNumber = index + 1));
  emit("parcelDeleted");
};

const validateParcels = (parcels: Parcel[]) => {
  for (let i = 0; i < parcels.length; i++) {
    if (parcels[i].weight <= 0) {
      return {
        $valid: false,
        $message: t("picking-list.validation.package-weight-missing", { packageNumber: i + 1 }),
      };
    }
    if (parcels[i].length <= 0) {
      return {
        $valid: false,
        $message: t("picking-list.validation.package-length-missing", { packageNumber: i + 1 }),
      };
    }
    if (parcels[i].width <= 0) {
      return {
        $valid: false,
        $message: t("picking-list.validation.package-width-missing", { packageNumber: i + 1 }),
      };
    }
    if (parcels[i].height <= 0) {
      return {
        $valid: false,
        $message: t("picking-list.validation.package-height-missing", { packageNumber: i + 1 }),
      };
    }
  }
  return true;
};

const rules = {
  parcels: {
    mustHaveValidParcels: validateParcels,
  },
};

const val = useValidate(rules, { parcels: parcels });
</script>
<style>
.pi.pi-plus-circle.c-success-icon {
  color: var(--success-btn-bg);
}
.c-black-text span {
  color: black;
}
</style>
