<template>
  <div class="flex items-center -mt-2 pb-2 border-b">
    <label class="text-color font-semibold my-3">{{ t("picking-lines.header") }}</label>

    <div class="flex-1 text-color font-semibold leading-6"></div>

    <div class="ml-2">
      <Button
        data-testid="btn-column-chooser"
        severity="secondary"
        variant="text"
        size="small"
        @click="emit('openColumnChooser', $event)"
      >
        <i class="pi pi-list"></i>
      </Button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";

const emit = defineEmits<{
  (e: "openColumnChooser", value: Event): void;
}>();
const { t } = useI18n();
</script>
