import { freightMethodApi } from "./FreightMethodApi";
import { useErrorHandler } from "../ErrorHandler";
import { useAuth } from "@cumulus/event-bus";
import { type FreightMethod } from "./model/FreightMethod";

export function useFreightMethods() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getFreightMethods = async (): Promise<FreightMethod[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await freightMethodApi.getFreightMethodsForCustomers(authHeaders);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return {
    getFreightMethods,
  };
}
