<template>
  <label for="inventory-new-cost-value" class="c-text-ellipsis mb-2">
    {{ t("inventory.edit-cost.inventory-cost-up-down") }}</label
  >
  <InputNumber
    id="inventory-new-cost-value"
    :modelValue="costValue"
    @update:modelValue="updateInventoryValue($event)"
    v-bind:class="costValue < 0 ? 'c-warning' : ''"
    :allow-empty="false"
    class="w-full c-input-width"
    data-testid="inventory-new-cost-value"
    :disabled="disabled"
  />
  <small class="p-error" v-if="validate.costValue.$error" data-testid="inventory-new-cost-error">
    {{ validate.costValue.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useVuelidate } from "@vuelidate/core";
import { helpers } from "@vuelidate/validators";

const { t } = useI18n();

const props = defineProps<{
  disabled: boolean;
  costValue: number;
}>();

const emit = defineEmits<{
  (event: "update:costValue", value: number): void;
}>();

const updateInventoryValue = (value: number) => {
  emit("update:costValue", value);
};

const rules = {
  costValue: {
    notZeroOrDisabled: helpers.withMessage(t("inventory.edit-cost.no-inventory-cost-change"), (value: number) => {
      return props.disabled || value !== 0;
    }),
  },
};

const validate = useVuelidate(rules, props);
</script>

<style scoped lang="scss">
.c-input-width {
  :deep(.p-inputnumber-input) {
    min-width: 0;
  }
}
.c-text-ellipsis {
  display: block;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
:deep(.p-inputtext) {
  text-align: right;
}
</style>
