<template>
  <div class="flex items-center">
    <RadioButton
      :id="costType.toLowerCase()"
      name="category"
      :value="costType"
      v-model="selectedCostType"
      :data-testid="'inventory-select-' + costType.toLowerCase()"
      :disabled="disabled"
    />
    <label class="ml-2" :class="uc ? 'c-under-construction' : ''">{{ labelComputed }}</label>
  </div>

  <div :for="costType.toLowerCase()" class="field-radiobutton"></div>
</template>

<script setup lang="ts">
import { InventoryCostType } from "@/models/inventory/InventoryCostType";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  costType: InventoryCostType;
  costValue: InventoryCostType;
  disabled: boolean;
  uc: boolean;
}>();

const emit = defineEmits<{
  (e: "update:costValue", value: InventoryCostType): void;
}>();

const labelComputed = computed<string>(() => {
  return props.costType === InventoryCostType.TotalInventoryCost
    ? t("inventory.edit-cost.cost.totalInventoryCost")
    : t("inventory.edit-cost.cost.unitCost");
});

const selectedCostType = computed<InventoryCostType>({
  get: (): InventoryCostType => {
    return props.costValue;
  },
  set: (value: InventoryCostType) => {
    emit("update:costValue", value);
  },
});
</script>
