import { Product } from "@/models/product/Product";
import { useErrorHandler } from "@/repositories/ErrorHandler";
import { useAuth } from "@cumulus/event-bus";
import { productApi } from "./ProductApi";

export function useProductService() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getProductById = async (id: string): Promise<Product> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await productApi.get(authHeaders, id);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return {
    getProductById,
  };
}
