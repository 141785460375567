<template>
  <div class="grid grid-cols-12 gap-8 mb-6">
    <div class="col-span-12 xl:col-span-4 lg:col-span-12 h-full">
      <Card class="c-customer c-card-background">
        <template #content>
          <div v-if="!isMobile" class="flex justify-start gap-2 items-center">
            <div class="flex w-full">
              <div class="flex-1 w-1/2">
                <PickingListNumber
                  :pickinglistNumber="pickingList.pickingListNumber"
                  data-testid="picking-list-number"
                />
              </div>
              <div class="flex-1 w-1/2 text-right">
                <Tag
                  class="mr-2"
                  data-testid="state-tag"
                  :value="stateComputed"
                  :severity="severityComputed as any"
                ></Tag>
                <span
                  v-if="
                    pickingList?.state === PickingListState.InPicking || pickingList?.state === PickingListState.Parked
                  "
                  >{{ pickingStartedComputed }}</span
                >
              </div>
            </div>
          </div>
          <div class="grid grid-cols-12 md:p-6">
            <div class="col-span-12 md:col-span-6">
              <b
                >{{ t("picking-list.customer-number") }} :
                <span data-testid="customer-number">{{ pickingList?.customerNumber }}</span></b
              >
              <div class="c-invoice-address">
                <div data-testid="customer-name">{{ pickingList?.customerName }}</div>
                <div>{{ pickingList?.invoiceAddress.addressLines.join(", ") }}</div>
                <div>{{ pickingList?.invoiceAddress.postalCode }} {{ pickingList?.invoiceAddress.city }}</div>
              </div>
            </div>

            <div class="col-span-6">
              <b>{{ t("picking-list.deliver-to") }}</b>
              <div>{{ pickingList?.customerName }}</div>
              <div>{{ pickingList?.delivery.address.addressLines.join(", ") }}</div>
              <div>{{ pickingList?.delivery.address.postalCode }} {{ pickingList?.delivery.address.city }}</div>
            </div>
          </div>
        </template>
      </Card>
    </div>

    <div class="col-span-12 xl:col-span-4 md:col-span-6">
      <Card class="h-full">
        <template #content>
          <div class="c-order-props pt-12 ml-8">
            <div class="grid grid-cols-12 gap-4">
              <div class="col-span-12 xl:col-span-6">
                <b>{{ t("picking-list.order-reference") }} : </b>
                <span data-testid="order-reference">{{ pickingList?.orderReference }}</span>
                <div class="mt-6">
                  <b>{{ t("picking-list.contact") }} : </b>
                  <span>{{ pickingList?.contactName }}</span>
                </div>
              </div>
              <div class="col-span-12 xl:col-span-6">
                <b>{{ t("picking-list.freight") }} : </b>
                <span data-testid="freight-method">{{ pickingList?.freightMethodName }}</span>
                <div class="field-checkbox mt-6">
                  <Checkbox
                    inputId="complete-delivery"
                    :model-value="pickingList?.completeDelivery"
                    :binary="true"
                    :disabled="true"
                  ></Checkbox>
                  <label for="complete-delivery" class="ml-1">{{ t("picking-list.complete-delivery") }}</label>
                </div>
              </div>
            </div>
          </div>
        </template>
      </Card>
    </div>

    <div class="col-span-12 xl:col-span-4 md:col-span-6 mr-2">
      <Card class="h-full">
        <template #content>
          <div class="c-order-comment pt-12 ml-8">
            <div class="grid grid-cols-12 gap-4">
              <div class="col-span-12 xl:col-span-6">
                <b>{{ t("picking-list.payment") }}: </b>
                <span data-testid="payment-term">{{ pickingList?.paymentTermName }}</span>
                <div class="mt-6">
                  <b>{{ t("picking-list.order-type") }}: </b>
                </div>
                <span></span>
              </div>
              <div class="col-span-12 xl:col-span-6">
                <b>{{ t("picking-list.message") }}: </b>
                <span>{{ pickingList?.orderComment }}</span>
              </div>
            </div>
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import PickingListNumber from "./checkout-dialog/components/PickingListNumber.vue";
import { PickingList } from "@/repositories/picking-list/model/PickingList";
import { PickingListState } from "@/repositories/picking-list/model/PickingListState";

const props = defineProps<{
  pickingList: PickingList;
  isMobile: boolean;
  stateComputed?: string;
  severityComputed?: unknown;
}>();

const { t, d } = useI18n();

const pickingStartedComputed = computed<string>(() =>
  props.pickingList.pickingStarted
    ? t("picking-list.detail-picking-started") + d(props.pickingList?.pickingStarted, "long")
    : "",
);
</script>
