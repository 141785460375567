<template>
  <InventoryChangesLogsHeader
    v-model:filters="filtersComputed"
    v-model:showSidebar="showSidebar"
    @navigateToInventoryClicked="navigateToInventorySearch"
  />

  <div class="c-inventory-changes-log-list">
    <div class="flex">
      <div class="flex-auto c-transition">
        <DataTable
          class="c-datatable"
          :value="inventoryChanges"
          dataKey="id"
          :autoLayout="true"
          responsiveLayout="scroll"
          selectionMode="single"
          :loading="loading"
          contextMenu
          data-testid="inventory-changes-log-list"
          stripedRows
          :sortField="sortField"
          :sortOrder="sortOrder"
          :rows="50"
          :paginator="true"
          :removableSort="true"
          :lazy="true"
          scrollable
          scrollHeight="75vh"
          :rowsPerPageOptions="[50, 100]"
          :totalRecords="totalHits"
          :currentPageReportTemplate="
            t('common.current-page-template', {
              first: '{first}',
              last: '{last}',
              totalRecords: '{totalRecords}',
            })
          "
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          @page="onPage"
          @sort="onSort"
          @row-dblclick="openInventoryChangeLogDialog"
        >
          <Column field="changeNumber" :header="t('inventory.change-log.change-number')" sortable />
          <Column field="reason" :header="t('inventory.change-log.reason')" sortable />
          <Column field="costPrice" :header="t('inventory.change-log.cost-price')" sortable>
            <template #body="{ data, field }">
              {{ n(data[field], "decimal") }}
            </template>
          </Column>
          <Column field="quantityChange" :header="t('inventory.change-log.quantity-change')" sortable>
            <template #body="{ data, field }">
              {{ n(data[field], "decimal") }}
            </template>
          </Column>
          <Column field="totalChangeValue" :header="t('inventory.change-log.total-change-value')" sortable>
            <template #body="{ data, field }">
              {{ n(data[field], "decimal") }}
            </template>
          </Column>
          <Column field="changeDate" :header="t('inventory.change-log.change-date')" filterField="fromDate" sortable>
            <template #body="{ data, field }">
              {{ d(data[field]) }}
            </template>
          </Column>
          <template #empty>
            <span>{{ t("inventory.change-log.no-inventory-changes-found") }}</span>
          </template>
        </DataTable>
      </div>

      <div
        class="c-transition pb-3 lg:pl-5"
        :class="{
          'show-filterbar': showSidebar,
        }"
      >
        <ChangeLogFilterSidebar :showSidebar="showSidebar" v-model:filters="filtersComputed" />
      </div>
    </div>

    <InventoryChangeLogDialog
      v-if="visibleDialog"
      v-model:visible="visibleDialog"
      :inventoryChangeLogId="selectedInventoryChangeLogId"
    />
  </div>
</template>

<script lang="ts" setup>
import { useInventory } from "@/api/inventory/InventoryService";
import { InventoryChangeLog } from "@/models/inventory/InventoryChangeLog";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import InventoryChangeLogDialog from "../components/InventoryChangeLogDialog.vue";
import { DataTablePageEvent, DataTableRowDoubleClickEvent, DataTableSortEvent } from "primevue/datatable";
import { GetInventoryChangeLogsRequest } from "@/models/inventory/GetInventoryChangeLogsRequest";
import { InventoryChangeLogFilters } from "@/models/inventory/search/InventoryChangeLogFilters";
import ChangeLogFilterSidebar from "../components/ChangeLogFilterSidebar.vue";
import InventoryChangesLogsHeader from "../components/InventoryChangesLogsHeader.vue";

const loading = ref<boolean>(false);
const totalHits = ref<number>(0);
const page = ref<number>(1);
const pageSize = ref<number>(50);
const visibleDialog = ref<boolean>(false);
const sortField = ref<string>("");
const sortOrder = ref<number>(-1);
const inventoryChanges = ref<InventoryChangeLog[]>([]);
const selectedInventoryChangeLogId = ref<string>("");
const showSidebar = ref(false);
const filters = ref<InventoryChangeLogFilters>(new InventoryChangeLogFilters());

const { t, d, n } = useI18n();
const router = useRouter();
const { getInventoryChangeLogs } = useInventory();

const navigateToInventorySearch = () => {
  router.push({ name: "warehouse/inventory/search" });
};

const openInventoryChangeLogDialog = (event: DataTableRowDoubleClickEvent) => {
  selectedInventoryChangeLogId.value = event.data.id;
  visibleDialog.value = true;
};

const onPage = (event: DataTablePageEvent) => {
  page.value = event.page + 1;
  pageSize.value = event.rows;

  get();
};

const onSort = (event: DataTableSortEvent) => {
  if (typeof event.sortField === "string") {
    sortField.value = event.sortField;
    sortOrder.value = event.sortOrder === 1 ? 1 : -1;
    page.value = 1;
    get();
  }
};

const filtersComputed = computed<InventoryChangeLogFilters>({
  get: () => filters.value,
  set: async (value) => {
    await onFilterUpdate(value);
  },
});

const onFilterUpdate = async (value: InventoryChangeLogFilters) => {
  filters.value = value;
  loading.value = true;
  page.value = 1;
  await get();
};

const get = async () => {
  try {
    loading.value = true;
    const request = new GetInventoryChangeLogsRequest(page.value, pageSize.value, filters.value, sortField.value);
    request.sortOrder = sortOrder.value === 1 ? "asc" : "desc";
    const repsonse = await getInventoryChangeLogs(request);

    inventoryChanges.value = repsonse.inventoryChangeLogs;
    totalHits.value = repsonse.totalHits;
  } finally {
    loading.value = false;
  }
};

onMounted(() => {
  sortField.value = "changeNumber";
  sortOrder.value = -1;
  get();
});
</script>

<style scoped lang="scss">
.c-inventory-changes-log-list {
  margin: var(--default-content-margin);
}

.c-transition {
  transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
  max-height: 90vh;
}
</style>
