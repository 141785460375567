<template>
  <div class="card">
    <PickingLinesListToolbar @openColumnChooser="openColumnChooser"> </PickingLinesListToolbar>
    <DataTable
      v-if="pickingList !== undefined"
      :value="pickingList.lines"
      dataKey="id"
      :autoLayout="true"
      stripedRows
      :rowClass="rowClass"
      :resizableColumns="true"
      columnResizeMode="fit"
      @column-resize-end="onColumnResizeEnd"
      :reorderable-columns="true"
      @column-reorder="onColumnReorder"
      removableSort
      class="c-datatable"
      :key="renderKey as unknown as number"
    >
      <Column
        v-for="(col, index) of selectedColumnsComputed as unknown as DataTableColumn[]"
        :field="col.field"
        :header="t(col.header)"
        :key="col.field + '_' + index"
        :class="col.class"
        :sortable="col.sortable"
        :pt="{
          headerCell: {
            id: col.field,
          },
        }"
        :style="col.size ? `width: ${col.size}px; max-width: ${col.size}px;` : ''"
      >
        <template #body="{ field, data }">
          <template v-if="col.field === PickingLinesColumn.PositionNumber">
            <span>{{ data.positionNumber }}</span>
          </template>
          <template v-else-if="col.field === PickingLinesColumn.ProductNumber">
            <span>{{ data.productNumber }}</span>
          </template>
          <template v-else-if="col.field === PickingLinesColumn.SupplierProductNumber">
            <span>{{ data.supplierProductNumber }}</span>
          </template>
          <template v-else-if="col.field === PickingLinesColumn.Gtin">
            <span>{{ data.gtin }}</span>
          </template>
          <template v-else-if="col.field === PickingLinesColumn.ProductName">
            <span>{{ data.productName }}</span>
          </template>
          <template v-else-if="col.field === PickingLinesColumn.BatchNumber">
            <span>{{ data.batchNumber }}</span>
          </template>
          <template v-else-if="col.field === PickingLinesColumn.QuantityForPicking">
            <div data-testid="picking-list-quantity-for-picking">{{ data.quantityForPicking }}</div>
          </template>
          <template v-else-if="col.field === PickingLinesColumn.QuantityPicked">
            <div v-if="!editMode" data-testid="picking-list-quantity-picked">{{ data.quantityPicked }}</div>
            <div v-else>
              <InputNumber
                v-model="data.quantityPicked"
                data-testid="picking-list-quantity-picked-input"
                :min="0"
                :max="data.quantityForPicking"
                :allowEmpty="true"
                @blur="onSavePickingProgress(data)"
                inputClass="w-20"
              ></InputNumber>
            </div>
          </template>
          <template v-else-if="col.field === PickingLinesColumn.QuantityRemaining">
            <div data-testid="picking-list-quantity-remaining">{{ calculateQuantityRemaining(data) }}</div>
          </template>
          <template v-else>
            {{ data[field as keyof typeof data] }}
          </template>
        </template>
      </Column>

      <template #loading>
        <span v-if="loading">{{ t("common.loading") }}</span>
      </template>
      <template #empty>
        <span>{{ t("picking-list.no-result") }}</span>
      </template>
    </DataTable>
  </div>
  <Popover ref="op" data-testid="sort-list">
    <div class="flex flex-col gap-4 w-[14rem] p-2">
      <div>
        <span class="font-bold block mb-2 underline">{{ t("common.sorting-by") }} </span>
        <ul class="list-none p-2 m-0">
          <li
            v-for="column in pickingLinesSearchColumns"
            :key="column.field"
            class="flex px-2 py-3 hover:bg-emphasis cursor-pointer rounded-border"
            :class="[
              'transition-colors duration-200',
              {
                'bg-primary-50 dark:bg-primary-400/10': isSelectedColumn(column),
                'hover:bg-emphasis': !isSelectedColumn(column),
              },
            ]"
            @click="onSelectedRow(column)"
          >
            <div class="flex-1">
              <span class="font-medium">{{ t(column.header) }}</span>
            </div>
            <div class="text-right">
              <i v-if="isSelectedColumn(column)" :class="selectedIcon"></i>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </Popover>

  <PopOverColumnChooser
    ref="chooserRef"
    v-model:selectedColumns="selectedColumnsComputed"
    :columns="filteredColumns"
    :label="t('common.reset')"
    @resetColumns="resetColumns"
  />
</template>

<script setup lang="ts">
import { PickingList } from "@/repositories/picking-list/model/PickingList";
import { PickingListState } from "@/repositories/picking-list/model/PickingListState";
import { PickingListLine } from "@/repositories/picking-list/model/PickingListLine";
import { useI18n } from "vue-i18n";
import { computed, nextTick, ref } from "vue";
import { useTablePreferences } from "@cumulus/components";
import type { DataTableColumn } from "@cumulus/components";
import { storeToRefs } from "pinia";
import { usePickingListStore } from "@/repositories/picking-list/PickingListStore";
import { PickingLinesColumn } from "../open-picking-lists/PickingLinesColumn";
import PopOverColumnChooser from "./PopOverColumnChooser.vue";
import PickingLinesListToolbar from "./PickingLinesListToolbar.vue";

const pickingListStore = usePickingListStore();
const { saveProgress } = pickingListStore;
const { loading, pickingLists } = storeToRefs(pickingListStore);
const { t } = useI18n();
const currentIconIndex = ref(0);
const pickingListSearchResult = ref();
const sortOrder = ref(-1);
const sortField = ref("");
const chooserRef = ref();

const props = defineProps<{
  pickingList: PickingList;
  editMode: boolean;
}>();

const emit = defineEmits<{
  (e: "update:page", value: number): void;
  (e: "openColumnChooser", value: Event): void;
}>();

const pickingLinesSearchColumns: DataTableColumn[] = [
  { field: "positionNumber", header: "picking-list.position-number", sortable: true },
  { field: "productNumber", header: "picking-list.product-number", sortable: true },
  { field: "supplierProductNumber", header: "picking-list.supplier-product-number", sortable: true },
  { field: "gtin", header: "picking-list.gtin", sortable: true },
  { field: "productName", header: "picking-list.product-name", sortable: true },
  { field: "batchNumber", header: "picking-list.batch-number", sortable: false },
  { field: "quantityForPicking", header: "picking-list.quantity-for-picking", sortable: true },
  { field: "quantityPicked", header: "picking-list.quantity-picked", class: "w-20 p-1", sortable: true },
  { field: "quantityRemaining", header: "picking-list.quantity-remaining", class: "w-20 p-1", sortable: true },
];

const { selectedColumnsComputed, renderKey, onColumnReorder, resetColumns, orderedColumns, onColumnResizeEnd } =
  useTablePreferences("pickingLinesSearchColumns", pickingLinesSearchColumns, null);

const sortBy = [
  { name: "Ascending", icon: "pi pi-sort-amount-up", value: 1 },
  { name: "Descending", icon: "pi pi-sort-amount-down", value: -1 },
];

const defaultSortField = ref(
  pickingLinesSearchColumns.find((column) => column.field === PickingLinesColumn.PositionNumber),
);
const defaultSortOrder = ref(sortBy[currentIconIndex.value].value);

const filteredColumns = computed(() => {
  return orderedColumns.value;
});

const focusSearchResult = () => {
  if (pickingLists.value.length > 0 && pickingListSearchResult.value) {
    pickingListSearchResult.value.$el.querySelector("tbody tr:first-of-type").focus();
  }
};

const updateSortOrder = (order: number) => {
  sortOrder.value = order;
};

const updateSortField = (field: string) => {
  sortField.value = field;
};

const onSelectedRow = (row: DataTableColumn) => {
  nextTick(() => {
    focusSearchResult();
    defaultSortOrder.value = defaultSortOrder.value === -1 ? 1 : -1;
    currentIconIndex.value = defaultSortOrder.value === 1 ? 1 : 0;
    defaultSortField.value = row;
    updateSortOrder(-defaultSortOrder.value);
    if (defaultSortField.value) {
      updateSortField(defaultSortField.value.field);
    }
    emit("update:page", 1);
  });
};

const selectedIcon = computed(() => {
  return sortBy[currentIconIndex.value].icon;
});

const isSelectedColumn = (column: DataTableColumn) => {
  return column.field === (defaultSortField.value?.field ?? "");
};

const openColumnChooser = (event: Event) => {
  chooserRef.value.toggle(event);
};

const calculateQuantityRemaining = (line: PickingListLine) => {
  if (line.quantityPicked === undefined || line.quantityPicked === null) {
    return line.quantityForPicking;
  }

  return line.quantityForPicking - line.quantityPicked;
};

const rowClass = (line: PickingListLine) => {
  if (line.quantityPicked == null) return;

  if (props.pickingList.state !== PickingListState.Open) {
    if (line.quantityPicked === line.quantityForPicking) {
      return "c-line-done";
    } else if (isNumber(line.quantityPicked)) {
      return "c-line-partial";
    }
  }
  return "";
};
const isNumber = (val: number | null) => typeof val === "number";

const onSavePickingProgress = async (line: PickingListLine) => {
  nextTick(async () => {
    if (line.quantityPicked === null) {
      return;
    }

    if (line.quantityPicked > line.quantityForPicking) {
      line.quantityPicked = line.quantityForPicking;
    }

    if (line.quantityPicked < 0) {
      line.quantityPicked = 0;
    }

    await saveProgress(props.pickingList.id, line.id, line.quantityPicked);
  });
};
</script>
