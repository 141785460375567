import { ProductAvailability } from "./ProductAvailability";
import { SearchPriceGroup } from "./SearchPriceGroup";
import { SearchProductSupplier } from "./SearchProductSupplier";

export class SearchProduct {
  id = "";
  name = "";
  productNumber = "";
  gtin = "";
  description = "";
  prices: SearchPriceGroup[] = [];
  supplierPrices: SearchProductSupplier[] = [];
  availability: ProductAvailability[] = [];
}
