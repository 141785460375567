<template>
  <InventoryCountHeader
    :unsavedChangesDialogVisible="unsavedChangesDialogVisible"
    :inventoryCountJobStatus="inventoryCountJob.status"
    :isDeleting="isDeleting"
    :isStarting="isStarting"
    @cancelClicked="handleCancelAction"
    @deleteClicked="onDelete"
    @stayOnPage="stayOnPage"
    @routeToSearch="routeToSearchPage"
    @startClicked="onStart"
    @saveClicked="onSave"
  />
  <div class="c-margin">
    <div class="grid grid-cols-12 gap-4 mb-6">
      <div class="col-span-12">
        <Card>
          <template #content>
            <div class="formgrid grid grid-cols-12 gap-4">
              <div class="col-span-12 md:col-span-6 xl:col-span-3 field">
                <FloatLabelInput
                  id="inventory-count-name"
                  :label="t('inventory-count.name')"
                  v-model:value="inventoryCountJob.name"
                  :selectAllOnFocus="true"
                  :placeholder="t('placeholder.type', { property: t('inventory-count.name').toLowerCase() })"
                  :dataTestId="'inventory-count-name'"
                  :disabled="false"
                  :tabIndex="0"
                />
              </div>
              <div class="col-span-12 md:col-span-6 xl:col-span-3 field">
                <div class="mt-2.5">
                  <FloatLabelDropdownPanel
                    ref="commentInputRef"
                    id="inventory-count-comment"
                    :selectedItemName="inventoryCountJob.comment?.trim() ?? ''"
                    :label="t('inventory-count.comment')"
                    :selectLabel="t('placeholder.type', { property: t('inventory-count.comment').toLowerCase() })"
                    @toggleDropdownPanel="showCommentPanel"
                    data-testid="inventory-count-comment"
                    maxlength="15000"
                    dataTestId="order-comment"
                    class="c-comment"
                    :setFocus="true"
                  />
                </div>

                <CommentPanel ref="commentPanelRef" v-model:comment="inventoryCountJob.comment" />
              </div>

              <div class="col-span-12 md:col-span-6 xl:col-span-3 field">
                <FloatLabelInput
                  id="inventory-count.warehouse"
                  :label="t('inventory-count.warehouse')"
                  v-model:value="warehouse.name"
                  :selectAllOnFocus="true"
                  :placeholder="t('placeholder.type', { property: t('inventory-count.warehouse').toLowerCase() })"
                  :dataTestId="'inventory-count.warehouse'"
                  :disabled="false"
                  :tabIndex="0"
                />
              </div>
              <div class="col-span-12 md:col-span-6 xl:col-span-3 field">
                <label for="status" class="w-full">{{ t("inventory-count.status") }}: </label>
                <Tag
                  class="mr-2"
                  data-testid="inventory-count-status"
                  :value="t(`inventory-count.count-job-status.${inventoryCountJob.status.toLowerCase()}`)"
                />
              </div>
            </div>
          </template>
        </Card>
      </div>
    </div>

    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-12">
        <Card>
          <template #content>
            <InventoryCountLinesList
              v-model:lines="inventoryCountJob.lines"
              :loading="loading"
              :pageSize="pageSize"
              :totalHits="inventoryCountJob.lines.length"
              @failedLineClicked="onFailedLineClicked"
            />
          </template>
        </Card>
      </div>
    </div>
  </div>

  <LinesCorrectionDialog
    v-model:visibleDialog="correctLinesDialogVisible"
    :warehouseId="inventoryCountJob.warehouseId"
    :failedLines="failedLines"
    :inventoryCountJob="inventoryCountJob"
    :pageSize="pageSize"
    :totalHits="failedLines.length"
    @linesCorrected="routeToSearchPage"
  />
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import { useInventoryCountService } from "../api/InventoryCountService";
import { InventoryCountJob } from "../model/InventoryCountJob";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { useWarehouse } from "@/repositories/warehouse/WarehouseService";
import { useRouteFunctions } from "../composables/RouteFunctions";
import { Warehouse } from "@/repositories/warehouse/model/Warehouse";
import InventoryCountLinesList from "../components/InventoryCountLinesList.vue";
import InventoryCountHeader from "../components/InventoryCountHeader.vue";
import { InventoryCountLineStatus } from "../model/InventoryCountLineStatus";
import LinesCorrectionDialog from "../components/LinesCorrectionDialog.vue";
import { InventoryCountLine } from "../model/InventoryCountLine";
import { InventoryCountStatus } from "../model/InventoryCountStatus";
import { useCumulusToast } from "@cumulus/toast";
import { useToast } from "primevue/usetoast";
import { UpdateInventoryCountJobLinesRequest } from "../model/UpdateInventoryCountJobLinesRequest";
import { FloatLabelDropdownPanel, FloatLabelInput } from "@cumulus/components";
import CommentPanel from "./CommentPanel.vue";

const inventoryCountJob = ref<InventoryCountJob>(new InventoryCountJob());
const initialInventoryCountJob = ref<InventoryCountJob>(new InventoryCountJob());
const warehouse = ref<Warehouse>(new Warehouse());
const loading = ref<boolean>(false);
const isDeleting = ref<boolean>(false);
const isStarting = ref<boolean>(false);
const pageSize = ref<number>(50);
const correctLinesDialogVisible = ref<boolean>(false);
const failedLines = ref<InventoryCountLine[]>([]);

const toast = useCumulusToast(useToast());
const { getById, deleteInventoryCountJob, startInventoryCountJob, updateInventoryCountJobLines } =
  useInventoryCountService();
const { getWarehouse } = useWarehouse();
const { stayOnPage, routeToSearchPage, handleCancelAction, unsavedChangesDialogVisible } = useRouteFunctions(
  inventoryCountJob,
  initialInventoryCountJob
);
const route = useRoute();
const { t } = useI18n();

const getInventoryCountJob = async () => {
  const id = route.params.id as string;
  inventoryCountJob.value = await getById(id);
  initialInventoryCountJob.value = { ...inventoryCountJob.value };
};

const getWarehouseById = async (id: string) => {
  warehouse.value = await getWarehouse(id);
};

const onFailedLineClicked = () => {
  failedLines.value = inventoryCountJob.value.lines.filter((line) => line.status === InventoryCountLineStatus.Failed);
  correctLinesDialogVisible.value = true;
};

const onDelete = async () => {
  if (
    inventoryCountJob.value.status !== InventoryCountStatus.Ready &&
    inventoryCountJob.value.status !== InventoryCountStatus.Failed
  ) {
    toast.add({
      severity: "warn",
      summary: t("inventory-count.delete-error"),
      detail: t("inventory-count.delete-invalid-status"),
    });
    return;
  }

  try {
    isDeleting.value = true;
    await deleteInventoryCountJob(inventoryCountJob.value.id);

    toast.add({
      severity: "success",
      summary: t("inventory-count.delete-success"),
      detail: t("inventory-count.delete-success-details", { number: inventoryCountJob.value.inventoryCountNumber }),
    });

    routeToSearchPage();
  } finally {
    isDeleting.value = false;
  }
};

const onStart = async () => {
  try {
    isStarting.value = true;
    await startInventoryCountJob(inventoryCountJob.value);

    toast.add({
      severity: "success",
      summary: t("inventory-count.start-success"),
      detail: t("inventory-count.start-success-details", { number: inventoryCountJob.value.inventoryCountNumber }),
    });

    routeToSearchPage();
  } finally {
    isStarting.value = false;
  }
};

const onSave = async () => {
  try {
    isStarting.value = true;

    const modifiedLines = inventoryCountJob.value.lines.filter((line) => {
      const initialLine = initialInventoryCountJob.value.lines.find((l) => l.id === line.id);
      return JSON.stringify(line) !== JSON.stringify(initialLine);
    });
    const request = new UpdateInventoryCountJobLinesRequest(inventoryCountJob.value.id, modifiedLines, []);
    await updateInventoryCountJobLines(request);

    toast.add({
      severity: "success",
      summary: t("inventory-count.save-success"),
      detail: t("inventory-count.save-success-details", { number: inventoryCountJob.value.inventoryCountNumber }),
    });

    routeToSearchPage();
  } finally {
    isStarting.value = false;
  }
};

const commentPanelRef = ref();
const showCommentPanel = (event: Event) => {
  const key = (event as KeyboardEvent).key;
  if (key === "Shift" || key === "Escape" || key === "Tab") {
    return;
  }

  commentPanelRef.value.toggle(event);
};

onMounted(async () => {
  try {
    loading.value = true;
    await getInventoryCountJob();
    await getWarehouseById(inventoryCountJob.value.warehouseId);
  } finally {
    loading.value = false;
  }
});
</script>

<style scoped lang="scss">
.c-margin {
  margin: var(--default-content-margin);
  overflow: hidden;
}
</style>
