import { useAuth } from "@cumulus/event-bus";
import { useErrorHandler } from "../ErrorHandler";
import { countryRepository } from "./CountryRepository";
import { type Country } from "./model/Country";
import { type CityResponse } from "./model/CityResponse";

export function useCountryService() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getAllCountries = async (): Promise<Country[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await countryRepository.getAll(authHeaders);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getCity = async (code: string): Promise<CityResponse[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await countryRepository.getCity(authHeaders, code);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return { getAllCountries, getCity };
}
