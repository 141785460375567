<template>
  <OpenPickingListToolbar
    @startPicking="emit('onStartPicking')"
    @showPickingList="emit('showPickingList')"
    @deletePickingList="confirmDelete"
    :pickingList="selectedPickingList"
    v-model:selectedIcon="selectedIcon"
    :isSortingVisible="isSortingVisible"
    :isFilterVisible="showSidebar"
    :isChooserVisible="isChooserVisible"
    @openSortList="openSortList"
    @refreshList="onClickOpenPickingListsRefresh"
    @openColumnChooser="openColumnChooser"
  >
  </OpenPickingListToolbar>
  <DataTable
    :value="filteredPickingLists"
    dataKey="id"
    :autoLayout="true"
    responsiveLayout="scroll"
    selectionMode="single"
    class="c-datatable"
    :loading="loading"
    contextMenu
    v-model:contextMenuSelection="selectedPickingList"
    @row-select="onRowSelection"
    @row-contextmenu="onRowContextMenu"
    v-model:selection="selectedPickingList"
    :paginator="true"
    :rows="pageSize"
    :sortOrder="sortOrder"
    :sortField="sortField"
    @sort="onSort"
    @page="onPage"
    v-model:filters="filters"
    filterDisplay="menu"
    showAddButton="false"
    :rowsPerPageOptions="[50, 100]"
    :currentPageReportTemplate="
      t('common.current-page-template', { first: '{first}', last: '{last}', totalRecords: '{totalRecords}' })
    "
    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
    stripedRows
    removableSort
    :resizableColumns="true"
    columnResizeMode="fit"
    @column-resize-end="onColumnResizeEnd"
    :reorderable-columns="true"
    @column-reorder="onColumnReorder"
    @row-dblclick="onRowDblClicked"
    ref="pickingListSearchResult"
    data-testid="open-picking-lists-table"
    :key="renderKey as unknown as number"
  >
    <Column
      v-for="(col, index) of selectedColumnsComputed as unknown as DataTableColumn[]"
      :field="col.field"
      :header="t(col.header)"
      :key="col.field + '_' + index"
      :class="col.class"
      :sortable="col.sortable"
      :pt="{
        headerCell: {
          id: col.field,
        },
        filteraddbuttoncontainer: { class: 'hidden' },
      }"
      :style="col.size ? `width: ${col.size}px; max-width: ${col.size}px;` : ''"
    >
      <template v-if="col.field === OpenPickingListColumn.PickingListNumber" #filter="{ filterModel }">
        <InputText v-model="filterModel.value" type="text" :placeholder="t('picking-list.filter.picking-list-number')"
      /></template>
      <template v-else-if="col.field === OpenPickingListColumn.State" #filter="{ filterModel }">
        <Select
          v-model="filterModel.value"
          :options="pickingListState"
          :placeholder="t('picking-list.filter.state')"
          showClear
        >
          <template #option="slotProps">
            <Tag :value="slotProps.option" :severity="severityFromState(slotProps.option)" />
          </template>
        </Select>
      </template>
      <template v-else-if="col.field === OpenPickingListColumn.OrderNumber" #filter="{ filterModel }">
        <InputText v-model="filterModel.value" type="text" :placeholder="t('picking-list.filter.order-number')"
      /></template>
      <template v-else-if="col.field === OpenPickingListColumn.FreightMethodName" #filter="{ filterModel }">
        <InputText v-model="filterModel.value" type="text" :placeholder="t('picking-list.filter.freight-method-name')"
      /></template>
      <template v-else-if="col.field === OpenPickingListColumn.CustomerNumber" #filter="{ filterModel }">
        <InputText v-model="filterModel.value" type="text" :placeholder="t('picking-list.filter.customer-number')"
      /></template>
      <template v-else-if="col.field === OpenPickingListColumn.CustomerName" #filter="{ filterModel }">
        <InputText v-model="filterModel.value" type="text" :placeholder="t('picking-list.filter.customer-name')"
      /></template>
      <template v-else-if="col.field === OpenPickingListColumn.OrderReference" #filter="{ filterModel }">
        <InputText v-model="filterModel.value" type="text" :placeholder="t('picking-list.filter.order-reference')"
      /></template>

      <template #body="{ field, data, index }">
        <template v-if="col.field === OpenPickingListColumn.ShippingDate">
          {{ d(data[field as keyof typeof data], "short") }}
        </template>
        <template v-else-if="col.field === OpenPickingListColumn.PickingListNumber">
          <span
            data-testId="pickinglist-number-text"
            class="c-pickinglist-number-text"
            @click="onPickingListNumberClicked(data, index)"
            >{{ data.pickingListNumber }}</span
          >
        </template>
        <template v-else-if="col.field === OpenPickingListColumn.PickingStarted">
          {{ formatPickingStartedDate(data) }}
        </template>
        <template v-else-if="col.field === OpenPickingListColumn.State">
          <Tag
            :value="t(`picking-list.states.${data.state.toLowerCase()}`)"
            :severity="severityFromState(data.state) as any"
          />
        </template>
        <template v-else-if="col.field === OpenPickingListColumn.SumTotalLines">
          {{ n(data[field as keyof typeof data], "decimal") }}
        </template>
        <template v-else-if="col.field === OpenPickingListColumn.PDF">
          <div class="c-pdf-icon">
            <img :data-testid="'btn-show-pdf-' + index" :src="pdfIcon" @click="downloadPdf(data.id)" />
          </div>
        </template>
        <template v-else>
          {{ data[field as keyof typeof data] }}
        </template>
      </template>
    </Column>

    <template #loading>
      <span v-if="loading">{{ t("common.loading") }}</span>
    </template>
    <template #empty>
      <span>{{ t("picking-list.no-result") }}</span>
    </template>
  </DataTable>
  <Popover ref="op" data-testid="sort-list">
    <div class="flex flex-col gap-4 w-[14rem] p-2">
      <div>
        <span class="font-bold block mb-2 underline">{{ t("common.sorting-by") }} </span>
        <ul class="list-none p-2 m-0">
          <li
            v-for="column in openPickingListsSearchColumns"
            :key="column.field"
            class="flex px-2 py-3 hover:bg-emphasis cursor-pointer rounded-border"
            :class="[
              'transition-colors duration-200',
              {
                'bg-primary-50 dark:bg-primary-400/10': isSelectedColumn(column),
                'hover:bg-emphasis': !isSelectedColumn(column),
              },
            ]"
            @click="onSelectedRow(column)"
          >
            <div class="flex-1">
              <span class="font-medium">{{ t(column.header) }}</span>
            </div>
            <div class="text-right">
              <i v-if="isSelectedColumn(column)" :class="selectedIcon"></i>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </Popover>

  <PopOverColumnChooser
    ref="chooserRef"
    v-model:selectedColumns="selectedColumnsComputed"
    :columns="filteredColumns"
    :label="t('common.reset')"
    @resetColumns="resetColumns"
  />

  <ContextMenu :model="menuModel" ref="contextMenu" data-testid="picking-list-context-menu" />

  <ConfirmDialog />
  <PickingPrintDialog v-model:visibleDialog="showPrintModal" :pickingList="selectedPickingList" v-if="showPrintModal" />
</template>

<script setup lang="ts">
import { usePickingListStore } from "@/repositories/picking-list/PickingListStore";
import { PickingList } from "@/repositories/picking-list/model/PickingList";
import { PickingListState } from "@/repositories/picking-list/model/PickingListState";
import { storeToRefs } from "pinia";
import {
  type DataTableRowContextMenuEvent,
  type DataTableRowSelectEvent,
  type DataTableRowDoubleClickEvent,
  type DataTablePageEvent,
  type DataTableSortEvent,
} from "primevue/datatable";
import { computed, nextTick, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useDocumentService } from "../document/DocumentService";
import { useCumulusToast } from "@cumulus/toast";
import { useToast } from "primevue/usetoast";
import pdfIcon from "@/assets/PDF_file_icon.svg";
import PickingPrintDialog from "./PickingPrintDialog.vue";

import { OpenPickingListColumn } from "../open-picking-lists/OpenPickingListColumn";
import { useTablePreferences } from "@cumulus/components";
import type { DataTableColumn } from "@cumulus/components";
import { FilterMatchMode, FilterOperator } from "@primevue/core";
import OpenPickingListToolbar from "./OpenPickingListToolbar.vue";
import PopOverColumnChooser from "./PopOverColumnChooser.vue";
import { useConfirm } from "primevue/useconfirm";

const { t, d, n } = useI18n();
const pickingListState = ref(Object.values(PickingListState));

const pickingListStore = usePickingListStore();
const { getOpenPickingLists, deletePickingList } = pickingListStore;
const { getPickingListUrl } = useDocumentService();
const { pickingLists } = storeToRefs(pickingListStore);
const contextMenu = ref();
const query = ref("");
const pickingListSearchResult = ref();
const focusedRow = ref();
const toast = useCumulusToast(useToast());
const showPrintModal = ref(false);
const pageSize = ref<number>(50);
const sortField = ref("");
const sortOrder = ref(-1);

const loading = ref(true);
const currentIconIndex = ref(0);

defineProps<{
  showSidebar: boolean;
}>();

const selectedPickingList = defineModel<PickingList | undefined>("selectedPickingList");

const emit = defineEmits<{
  (e: "update:page", value: number): void;
  (e: "update:pageSize", value: number): void;
  (e: "showPickingList"): void;
  (e: "onStartPicking"): void;
  (e: "update:selectedPickingList", value: PickingList | undefined): void;
  (e: "onToggleFilterSidebar"): void;
}>();

const openPickingListsSearchColumns: DataTableColumn[] = [
  { field: "shippingDate", header: "picking-list.shipping-date", sortable: true },
  {
    field: "pickingListNumber",
    header: "picking-list.picking-list-number",
    class: "text-right",
    sortable: true,
  },
  { field: "state", header: "picking-list.state", class: "text-right", sortable: true },
  {
    field: "assignedEmployeeInitials",
    header: "picking-list.employee-initials",
    class: "text-right",
    sortable: true,
  },
  { field: "pickingStarted", header: "picking-list.picking-started", sortable: true },
  { field: "orderNumber", header: "picking-list.order-number", class: "text-right", sortable: true },
  { field: "freightMethodName", header: "picking-list.freight-method-name", sortable: true },
  { field: "customerNumber", header: "picking-list.customer-number", class: "text-right", sortable: true },
  { field: "customerName", header: "picking-list.customer-name", class: "text-right", sortable: true },
  { field: "orderReference", header: "picking-list.order-reference", sortable: true },
  {
    field: "quantityOfOrderItems",
    header: "picking-list.quantity-of-order-items",
    class: "text-right",
    sortable: true,
  },
  {
    field: "sumTotalLines",
    header: "picking-list.sum-of-total-ex-vat",
    class: "text-right",
    sortable: true,
  },
  {
    field: "PDF",
    header: "picking-list.pdf",
    class: "text-right",
    sortable: false,
  },
];

const op = ref();

const openSortList = (event: Event) => {
  op.value.toggle(event);
};

const chooserRef = ref();

const openColumnChooser = (event: Event) => {
  chooserRef.value.toggle(event);
};

const sortBy = [
  { name: "Ascending", icon: "pi pi-sort-amount-up", value: 1 },
  { name: "Descending", icon: "pi pi-sort-amount-down", value: -1 },
];

const isSortingVisible = computed<boolean>(() => {
  return op.value?.visible ?? false;
});

const isChooserVisible = computed<boolean>(() => {
  return chooserRef.value?.visible ?? false;
});

const defaultSortOrder = ref(sortBy[currentIconIndex.value].value);

const onSelectedRow = (row: DataTableColumn) => {
  nextTick(() => {
    focusSearchResult();
    defaultSortOrder.value = defaultSortOrder.value === -1 ? 1 : -1;
    currentIconIndex.value = defaultSortOrder.value === 1 ? 1 : 0;
    defaultSortField.value = row;
    updateSortOrder(-defaultSortOrder.value);

    if (defaultSortField.value) {
      updateSortField(defaultSortField.value.field);
    }
    emit("update:page", 1);
  });
};

const selectedIcon = computed(() => {
  return sortBy[currentIconIndex.value].icon;
});

const defaultSortField = ref(
  openPickingListsSearchColumns.find((column) => column.field === OpenPickingListColumn.PickingListNumber),
);

const isSelectedColumn = (column: DataTableColumn) => {
  return column.field === (defaultSortField.value?.field ?? "");
};

const { selectedColumnsComputed, renderKey, onColumnReorder, resetColumns, orderedColumns, onColumnResizeEnd } =
  useTablePreferences("openPickingListsSearchColumns", openPickingListsSearchColumns, null);

const filteredColumns = computed(() => {
  return orderedColumns.value;
});

const onClickOpenPickingListsRefresh = async () => {
  loading.value = true;
  try {
    await getOpenPickingLists();

    nextTick(() => {
      focusSearchResult();
    });
  } finally {
    loading.value = false;
  }
};

const downloadPdf = async (id: string) => {
  try {
    loading.value = true;
    const url = await getPickingListUrl(id);

    if (url && url.length > 0) {
      window.open(url, "_blank");
    } else {
      toast.add({
        severity: "error",
        summary: t("common.error"),
        detail: t("print-picking-list.download-link-missing"),
      });
    }
  } finally {
    loading.value = false;
  }
};

const severityFromState = (state: PickingListState): string => {
  switch (state) {
    case PickingListState.Open:
      return "success";
    case PickingListState.InPicking:
      return "";
    case PickingListState.Parked:
      return "warning";
    case PickingListState.Done:
      return "info";
    default:
      return "";
  }
};

const formatPickingStartedDate = (pickingList: PickingList): string => {
  if (pickingList.state === PickingListState.InPicking || pickingList.state === PickingListState.Parked) {
    if (pickingList.pickingStarted !== null) {
      return d(pickingList.pickingStarted, "long");
    }

    console.error("formatPickingStartedDate is null", pickingList);
  }
  return "";
};

const setFocusedRow = (index: number) => {
  focusedRow.value = pickingListSearchResult.value.$el.querySelector(`tbody tr:nth-child(${index + 1})`);
};

const onRowContextMenu = (event: DataTableRowContextMenuEvent) => {
  setFocusedRow(event.index);
  contextMenu.value.show(event.originalEvent);
};

const selectWasTriggeredFromEnterKey = (event: DataTableRowSelectEvent) =>
  event.originalEvent instanceof KeyboardEvent && (event.originalEvent as KeyboardEvent).key === "Enter";

const onRowSelection = (event: DataTableRowSelectEvent) => {
  console.log("onRowSelection", event);
  selectedPickingList.value = event.data as PickingList;

  if (!selectWasTriggeredFromEnterKey(event)) return;
  emit("showPickingList");
};

const onPickingListNumberClicked = (pickingList: PickingList, index: number) => {
  setFocusedRow(index);
  selectedPickingList.value = pickingList;
  emit("showPickingList");
};

const menuModel = ref([
  {
    label: t("picking-list.context-menu.show-picking-list"),
    icon: "pi pi-fw pi-search",
    command: () => emit("showPickingList"),
  },
  {
    label: t("picking-list.context-menu.start-picking"),
    icon: "pi pi-fw pi-pencil",
    command: () => emit("onStartPicking"),
  },
  {
    label: t("common.delete"),
    icon: "pi pi-fw pi-trash",
    command: () => confirmDelete(),
  },
  { label: t("common.print"), icon: "pi pi-fw pi-print", command: () => togglePrintModal() },
]);

const togglePrintModal = () => {
  showPrintModal.value = !showPrintModal.value;
};

const onSort = async (event: DataTableSortEvent) => {
  let sortField = "";
  nextTick(() => {
    if (typeof event.sortField === "string") {
      sortField = event.sortField;
    }
    focusSearchResult();

    updateSortOrder(-sortOrder.value);
    updateSortField(sortField);
    emit("update:page", 1);
  });
};

const updateSortOrder = (order: number) => {
  sortOrder.value = order;
};
const updateSortField = (field: string) => {
  sortField.value = field;
};

const onPage = async (event: DataTablePageEvent) => {
  nextTick(() => {
    focusSearchResult();
    emit("update:page", event.page + 1);
    emit("update:pageSize", event.rows);
  });
};

const onRowDblClicked = (event: DataTableRowDoubleClickEvent) => {
  selectedPickingList.value = event.data as PickingList;
  emit("showPickingList");
};

const filteredPickingLists = computed<PickingList[]>(() => {
  if (pickingLists.value.length === 0) {
    return [];
  }
  return pickingLists.value.filter((picking: PickingList) => {
    return (
      d(picking.shippingDate).includes(query.value.toLowerCase()) ||
      picking.orderNumber.toString().startsWith(query.value.trim()) ||
      picking.pickingListNumber.toString().startsWith(query.value.trim())
    );
  });
});

const focusSearchResult = () => {
  if (pickingLists.value.length > 0 && pickingListSearchResult.value) {
    pickingListSearchResult.value.$el.querySelector("tbody tr:first-of-type").focus();
  }
};

watch([() => selectedPickingList, () => loading.value], ([, loading]) => {
  if (loading === false) {
    nextTick(() => {
      focusSearchResult();
    });
  }
});

const confirm = useConfirm();
const confirmDelete = () => {
  if (selectedPickingList.value === undefined) {
    return;
  }

  confirm.require({
    header: t("common.confirm-delete"),
    message: t("picking-list.confirm-delete"),
    icon: "pi pi-exclamation-triangle",
    acceptClass: "c-dialog-success-button",
    rejectClass: "p-button-outlined",
    accept: onDeletePickingList,
    reject: clearSelectedRow,
  });
};

const clearSelectedRow = () => {
  selectedPickingList.value = undefined;
  focusSearchResult();
};

const onDeletePickingList = async () => {
  if (selectedPickingList.value === undefined) {
    return;
  }

  await deletePickingList(selectedPickingList.value?.id);
  clearSelectedRow();
};

const createFilter = (matchMode: string) => ({
  operator: FilterOperator.AND,
  constraints: [{ value: "", matchMode }],
});

const filters = ref({
  pickingListNumber: createFilter(FilterMatchMode.STARTS_WITH),
  state: createFilter(FilterMatchMode.CONTAINS),
  orderNumber: createFilter(FilterMatchMode.STARTS_WITH),
  freightMethodName: createFilter(FilterMatchMode.STARTS_WITH),
  customerNumber: createFilter(FilterMatchMode.STARTS_WITH),
  customerName: createFilter(FilterMatchMode.STARTS_WITH),
  orderReference: createFilter(FilterMatchMode.STARTS_WITH),
});

onMounted(async () => {
  loading.value = true;
  try {
    await getOpenPickingLists();

    nextTick(() => {
      focusSearchResult();
    });
  } finally {
    loading.value = false;
  }
});
</script>

<style scoped lang="scss">
.c-pdf-icon img {
  display: inline-block;
  width: 1.68rem;
  cursor: pointer;
}
</style>
