<template>
  <div class="font-medium">{{ t(`checkout.delivery-to`) }}</div>
  <div data-testid="checkout-delivery-name" v-show="deliverTo.customerName">{{ deliverTo.customerName }}</div>

  <div v-for="line in deliverTo.address.addressLines" :key="line" data-testid="checkout-delivery-address-lines">
    {{ line }}
  </div>

  <div data-testid="checkout-delivery-postal">{{ deliverTo.address.postalCode }} {{ deliverTo.address.city }}</div>
  <div v-show="deliverTo.phoneNumber" data-testid="checkout-delivery-phone">
    <span class="font-medium">{{ t(`checkout.phone-number`, { symbol: ":" }) }}</span> {{ deliverTo.phoneNumber }}
  </div>
  <div v-show="deliverTo.email" data-testid="checkout-delivery-email">
    <span class="font-medium">{{ t(`checkout.email`, { symbol: ":" }) }}</span> {{ deliverTo.email }}
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { Delivery } from "@/repositories/picking-list/model/Delivery";

defineProps<{
  deliverTo: Delivery;
}>();

const { t } = useI18n();
</script>
