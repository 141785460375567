<template>
  <label for="inventory-dimension" class="c-text-ellipsis mb-2">
    {{ t("inventory.dimension", { unit: unitOfSizeComputed }) }}
  </label>
  <div id="inventory-dimension" data-testid="inventory-dimension" class="c-col-color">{{ dimensionComputed }}</div>
</template>

<script setup lang="ts">
import { Dimension } from "@/models/inventory/Dimension";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  dimension: Dimension;
}>();

const unitOfSizeComputed = computed(() => (props.dimension ? props.dimension.unitOfSize : ""));

const dimensionComputed = computed(() => {
  let dimensionString = "";
  if (!props.dimension) {
    return dimensionString;
  }
  if (props.dimension.length != 0) {
    dimensionString += props.dimension.length + " x ";
  }
  if (props.dimension.width != 0) {
    dimensionString += props.dimension.width + " x ";
  }
  if (props.dimension.height != 0) {
    dimensionString += props.dimension.height;
  }
  return dimensionString;
});
</script>
<style scoped lang="scss">
.c-col-color {
  min-height: calc(2rem + 0.25rem);
  flex-grow: 1;
  background-color: var(--list-row-bg);
  padding: 0.5rem 1rem;
  border-radius: 3px;
}
.c-text-ellipsis {
  display: block;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
