import { Warehouse } from "@/repositories/warehouse/model/Warehouse";
import { Product } from "../product/Product";
import { InventoryChangeLog } from "./InventoryChangeLog";

export class InventoryChangeDetails {
  id = "";
  productName = "";
  productNumber = "";
  gtin = "";
  warehouseName = "";
  batchNumber = "";
  changeNumber = 0;
  costPrice = 0;
  changeDate = "";
  quantityChange = 0;
  reason = "";
  comment = "";
  totalChangeValue = 0;

  static create(
    product: Product,
    warehouse: Warehouse,
    inventoryChangeLog: InventoryChangeLog,
  ): InventoryChangeDetails {
    const dialog = new InventoryChangeDetails();
    dialog.id = inventoryChangeLog.id;
    dialog.productName = product.name;
    dialog.productNumber = product.productNumber;
    dialog.gtin = product.gtin;
    dialog.warehouseName = warehouse.name;
    dialog.batchNumber = inventoryChangeLog.batchNumber;
    dialog.changeNumber = inventoryChangeLog.changeNumber;
    dialog.costPrice = inventoryChangeLog.costPrice;
    dialog.changeDate = inventoryChangeLog.changeDate;
    dialog.quantityChange = inventoryChangeLog.quantityChange;
    dialog.reason = inventoryChangeLog.reason;
    dialog.totalChangeValue = inventoryChangeLog.totalChangeValue;
    dialog.comment = inventoryChangeLog.comment;
    return dialog;
  }
}
