import { OrderStatus } from "./OrderStatus";

export class SearchFilters {
  orderStatuses: OrderStatus[] = [];
  fromDate: string | null = null;
  toDate: string | null = null;
  productNumbers: string[] = [];
  customerNumbers: string[] = [];
  customerGroups: string[] = [];
  freightMethods: string[] = [];
  isReadyForPicking = true;
}
