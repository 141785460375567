<template>
  <div class="card">
    <InventoryCountLinesListToolbar
      @openColumnChooser="openColumnChooser"
      @onClickInventoryCountRefresh="onClickInventoryCountRefresh"
      :isChooserVisible="isChooserVisible"
    />
    <DataTable
      :value="inventoryCountJobs"
      dataKey="id"
      class="c-datatable"
      :paginator="true"
      :loading="loading"
      :rows="pageSize"
      :totalRecords="totalHits"
      @row-dblclick="onRowDblClick"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      ref="searchInventoryCountList"
      v-model:selection="selectedInventoryCountJob"
      :stripedRows="true"
      selectionMode="single"
      @row-select="onRowSelected"
      data-testid="inventory-count-job-search-result"
      tabindex="0"
      :rowsPerPageOptions="[50, 100]"
      @page="onPage"
      :rowClass="addGlobalSearchClass"
      scrollable
      scrollHeight="75vh"
      :currentPageReportTemplate="
        t('common.current-page-template', {
          first: '{first}',
          last: '{last}',
          totalRecords: '{totalRecords}',
        })
      "
      :sortOrder="sortOrder"
      :sortField="sortField"
      @sort="onSort"
      :resizableColumns="true"
      columnResizeMode="fit"
      removableSort
      @column-resize-end="onColumnResizeEnd"
      :reorderable-columns="true"
      @column-reorder="onColumnReorder"
      :key="renderKey as unknown as number"
    >
      <Column
        v-for="(col, index) of selectedColumnsComputed as unknown as DataTableColumn[]"
        :field="col.field"
        :header="t(col.header)"
        :key="col.field + '_' + index"
        :class="col.class"
        :sortable="col.sortable"
        :pt="{
          headerCell: {
            id: col.field,
          },
        }"
        :style="col.size ? `width: ${col.size}px; max-width: ${col.size}px;` : ''"
      >
        <template #body="{ data, field, index }">
          <template v-if="col.field === InventoryCountListColumn.CreatedDateTime">
            <span :data-testid="`c-inventory-count-job-registered-${index}`">{{
              d(data[field as keyof typeof data], "long")
            }}</span>
          </template>

          <template v-else-if="col.field === InventoryCountListColumn.InventoryCountNumber">
            <span :data-testid="`c-inventory-count-job-number-${index}`">{{ data[field as keyof typeof data] }}</span>
          </template>
          <template v-else-if="col.field === InventoryCountListColumn.Status">
            <span :data-testid="`c-inventory-count-job-status-${index}`">
              <template v-if="data.status !== InventoryCountStatus.InProgress">
                {{ t(`inventory-count.count-job-status.${data[field as keyof typeof data].toLowerCase()}`) }}
              </template>
              <ProgressBar
                :value="data.progressPercentage"
                v-if="data.status === InventoryCountStatus.InProgress"
              ></ProgressBar>
            </span>
          </template>
          <template v-else-if="col.field === InventoryCountListColumn.NumberOfLines">
            <span :data-testid="`c-inventory-count-job-lines-${index}`">{{ data[field as keyof typeof data] }}</span>
          </template>
          <template v-else-if="col.field === InventoryCountListColumn.UpdatedDateTime">
            <span :data-testid="`c-inventory-count-job-updated-${index}`">{{
              d(data[field as keyof typeof data], "long")
            }}</span>
          </template>
          <template v-else-if="col.field === InventoryCountListColumn.RegisteredByEmployeeName">
            <span :data-testid="`c-inventory-count-job-employee-${index}`">{{ data[field as keyof typeof data] }}</span>
          </template>
          <template v-else>
            {{ data[field as keyof typeof data] }}
          </template>
        </template>
      </Column>
      <template #empty
        ><span data-testid="c-inventory-count-job-not-found">{{ t("inventory-count.not-found") }}</span></template
      >
    </DataTable>
  </div>
  <PopOverColumnChooser
    ref="chooserRef"
    v-model:selectedColumns="selectedColumnsComputed"
    :columns="filteredColumns"
    :label="t('common.reset')"
    @resetColumns="resetColumns"
  />
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { ref, nextTick, computed } from "vue";
import {
  type DataTablePageEvent,
  type DataTableRowDoubleClickEvent,
  type DataTableRowSelectEvent,
  type DataTableSortEvent,
} from "primevue/datatable";
import { InventoryCountJobInfo } from "../model/InventoryCountJobInfo";
import { InventoryCountStatus } from "../model/InventoryCountStatus";
import { InventoryCountListColumn } from "../model/InventoryCountListColumn";

import { useTablePreferences } from "@cumulus/components";
import type { DataTableColumn } from "@cumulus/components";
import PopOverColumnChooser from "./PopOverColumnChooser.vue";
import InventoryCountLinesListToolbar from "./InventoryCountLinesListToolbar.vue";

const { t, d } = useI18n();
const searchInventoryCountList = ref();
const previouslyFocusRow = ref();
const selectedInventoryCountJob = ref<InventoryCountJobInfo>(new InventoryCountJobInfo());

const props = defineProps<{
  inventoryCountJobs: InventoryCountJobInfo[];
  loading: boolean;
  totalHits: number;
  pageSize: number;
  page: number;
  sortOrder: number;
  sortField: string;
}>();

const emit = defineEmits<{
  (e: "update:sortOrder", value: number): void;
  (e: "update:sortField", value: string): void;
  (e: "rowDblClicked", value: InventoryCountJobInfo): void;
  (e: "update:page", value: number): void;
  (e: "update:pageSize", value: number): void;
  (e: "refreshList"): void;
}>();

const inventoryCountSearchColumns: DataTableColumn[] = [
  {
    field: "createdDateTime",
    header: "inventory-count.created",
    sortable: true,
  },
  {
    field: "inventoryCountNumber",
    header: "inventory-count.number",
    sortable: true,
  },
  {
    field: "status",
    header: "inventory-count.status",
    sortable: true,
  },
  {
    field: "numberOfLines",
    header: "inventory-count.lines",
    class: "text-right",
    sortable: true,
  },
  {
    field: "updatedDateTime",
    header: "inventory-count.last-updated",
    sortable: true,
  },
  {
    field: "registeredByEmployeeName",
    header: "inventory-count.employee",
    sortable: true,
  },
];

const { selectedColumnsComputed, renderKey, onColumnReorder, resetColumns, orderedColumns, onColumnResizeEnd } =
  useTablePreferences("inventoryCountSearch", inventoryCountSearchColumns, null);

const filteredColumns = computed(() => {
  return orderedColumns.value;
});

const chooserRef = ref();
const openColumnChooser = (event: Event) => {
  chooserRef.value.toggle(event);
};
const isChooserVisible = computed<boolean>(() => {
  return chooserRef.value?.visible ?? false;
});

const onClickInventoryCountRefresh = () => {
  emit("refreshList");
};

const addGlobalSearchClass = (data: InventoryCountJobInfo) => {
  return ["c-search-result-row", { "c-search-result-row-selected": selectedInventoryCountJob.value?.id === data.id }];
};

const focusSearchResult = () => {
  if (props.inventoryCountJobs.length > 0 && searchInventoryCountList.value) {
    if (previouslyFocusRow.value) {
      nextTick(() => {
        previouslyFocusRow.value.focus();
      });
    } else {
      nextTick(() => {
        searchInventoryCountList.value.$el.querySelector("tbody tr:first-of-type").focus();
      });
    }
  }
};

const onRowSelected = (event: DataTableRowSelectEvent) => {
  if (!(event.originalEvent instanceof KeyboardEvent)) {
    return;
  }
  if (event.originalEvent.key !== "Enter") {
    return;
  }
  setFocusedRow(event.index);
  emit("rowDblClicked", event.data);
};

const onRowDblClick = (event: DataTableRowDoubleClickEvent) => {
  setFocusedRow(event.index);
  emit("rowDblClicked", event.data);
};

const setFocusedRow = (index: number) => {
  const pageIndex = (props.page - 1) * props.pageSize;
  const rowIndex = index - pageIndex;
  nextTick(() => {
    previouslyFocusRow.value = searchInventoryCountList.value.$el.querySelector(`tbody tr:nth-child(${rowIndex + 1})`);
  });
};

const onPage = async (event: DataTablePageEvent) => {
  nextTick(() => {
    focusSearchResult();
    emit("update:page", event.page + 1);
    emit("update:pageSize", event.rows);
  });
};

const onSort = async (event: DataTableSortEvent) => {
  let sortField = "";
  nextTick(() => {
    if (typeof event.sortField === "string") {
      sortField = event.sortField;
    }
    emit("update:sortOrder", -props.sortOrder);
    emit("update:sortField", sortField);
    emit("update:page", 1);
  });
};
</script>
<style scoped lang="scss">
:deep(.p-paginator) {
  .p-paginator-first {
    margin-left: auto;
  }
  .p-paginator-current {
    margin-left: auto;
  }
}
</style>
