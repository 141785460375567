import { httpClient, AuthHeaders } from "@cumulus/http";
import { InventoryCountJobInfo } from "../model/InventoryCountJobInfo";
import { InventoryCountJob } from "../model/InventoryCountJob";
import { UpdateInventoryCountJobLinesRequest } from "../model/UpdateInventoryCountJobLinesRequest";

class InventoryCountApi {
  private uri = "";

  constructor() {
    this.uri = import.meta.env.VITE_APP_API_WAREHOUSE
      ? `${import.meta.env.VITE_APP_API_WAREHOUSE}/inventory`
      : `${import.meta.env.VITE_APP_API_URL as string}/inventory`;
  }

  public async getAll(authHeaders: AuthHeaders): Promise<InventoryCountJobInfo[]> {
    return await httpClient(this.uri, authHeaders)
      .get("/inventory-count-jobs")
      .then(({ data }) => data);
  }

  public async getById(authHeaders: AuthHeaders, id: string): Promise<InventoryCountJob> {
    return await httpClient(this.uri, authHeaders)
      .get(`/inventory-count-jobs/${id}`)
      .then(({ data }) => data);
  }

  public async importExcel(authHeaders: AuthHeaders, warehouseId: string, formData: FormData): Promise<void> {
    return await httpClient(this.uri, authHeaders).postForm(
      `/${warehouseId}/inventory-count-jobs/import-excel`,
      formData
    );
  }

  public async importCsv(authHeaders: AuthHeaders, warehouseId: string, formData: FormData): Promise<void> {
    return await httpClient(this.uri, authHeaders).postForm(
      `/${warehouseId}/inventory-count-jobs/import-csv`,
      formData
    );
  }

  public async getWebPubSubUrl(authHeaders: AuthHeaders): Promise<string> {
    return await httpClient(this.uri, authHeaders)
      .get("/inventory-count-jobs/web-pubsub-url")
      .then(({ data }) => data);
  }

  public async updateInventoryCountJobLines(
    authHeaders: AuthHeaders,
    request: UpdateInventoryCountJobLinesRequest
  ): Promise<void> {
    return await httpClient(this.uri, authHeaders)
      .put(`/inventory-count-jobs/${request.inventoryCountJobId}/lines`, request)
      .then(({ data }) => data);
  }

  public async deleteInventoryCountJob(authHeaders: AuthHeaders, id: string): Promise<void> {
    return await httpClient(this.uri, authHeaders).delete(`/inventory-count-jobs/${id}`);
  }

  public async startInventoryCountJob(authHeaders: AuthHeaders, inventoryCountJob: InventoryCountJob): Promise<void> {
    return await httpClient(this.uri, authHeaders).post(
      `/inventory-count-jobs/${inventoryCountJob.id}/start`,
      inventoryCountJob
    );
  }
}

export const inventoryCountApi = new InventoryCountApi();
