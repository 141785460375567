import { ref, onMounted, onUnmounted } from "vue";

export function useMobile() {
  const isMobile = ref(false);
  const isTablet = ref(false);

  const checkScreen = () => {
    const isBrowser = typeof window !== "undefined";
    const windowWidth = isBrowser ? window.innerWidth : 0;

    if (windowWidth < 768) {
      isMobile.value = true;
      isTablet.value = false;
      return;
    } else if (windowWidth >= 768 && windowWidth < 992) {
      isTablet.value = true;
      isMobile.value = false;
    } else {
      isMobile.value = false;
      isTablet.value = false;
    }
  };

  onMounted(() => {
    checkScreen();
    window.addEventListener("resize", checkScreen);
  });
  onUnmounted(() => {
    window.removeEventListener("resize", checkScreen);
  });

  return { isMobile, isTablet };
}
