<template>
  <div>
    <span class="font-medium mr-1">{{ t(`checkout.customer`) }}</span>
    <span class="pr-1" data-testid="checkout-customer-number">{{ customerNumber }},</span>
    <span data-testid="checkout-customer-name">{{ customerName }}</span>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";

const { t } = useI18n();

defineProps<{
  customerNumber: string;
  customerName: string;
}>();
</script>
