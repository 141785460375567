<template>
  <label for="inventory-changed-value" class="c-text-ellipsis mb-2">{{
    t(`inventory.edit-inventory.changed-value`)
  }}</label>
  <div id="inventory-changed-value" class="text-right c-col-color">{{ changedInventoryValue }}</div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";

const { t } = useI18n();

defineProps<{
  changedInventoryValue: number;
}>();
</script>
<style scoped lang="scss">
.c-col-color {
  flex-grow: 1;
  background-color: var(--list-row-bg);
  padding: 0.5rem 1rem;
  border-radius: 3px;
}
.c-text-ellipsis {
  display: block;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
