<template>
  <div v-if="Array.isArray(filterValue)">
    <li v-for="(value, index) in filterValue" :key="index" class="c-chips-token">
      <span :data-testid="dataTestId + '-label-' + index">{{ value }}</span>
      <span
        class="c-chips-token-icon pi pi-times-circle"
        :data-testid="dataTestId + '-delete-btn-' + index"
        @click="emit('removeFilter', filterType, index)"
      >
      </span>
    </li>
  </div>
  <li v-else-if="filterValue" class="c-chips-token">
    <span :data-testid="dataTestId + '-label-' + 0">{{ filterValue }}</span>
    <span
      class="c-chips-token-icon pi pi-times-circle"
      @click="emit('removeFilter', filterType, 0)"
      :data-testid="dataTestId + '-delete-btn-' + 0"
    >
    </span>
  </li>
</template>

<script setup lang="ts">
import { ProductSearchFilters } from "@/repositories/search/model/product/ProductSearchFilters";

defineProps<{
  filterValue: string[] | string;
  filterType: keyof ProductSearchFilters | string;
  dataTestId: string;
}>();

const emit = defineEmits<{
  (event: "removeFilter", filterType: keyof ProductSearchFilters | string, index: number): void;
}>();
</script>

<style scoped lang="scss">
.c-chips-token {
  padding: 0.25rem 0.5rem;
  margin-right: 0.5rem;
  color: var(--chips-text-secondary);
  border-radius: 1rem;
  background-color: var(--chips-bg-secondary);
  display: inline-flex;
  align-items: center;
}

.c-chips-token-icon {
  margin-left: 0.5rem;
  cursor: pointer;
}
</style>
