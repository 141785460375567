<template>
  <label for="warehouse-company-name">{{ t("warehouse.company-name") }}</label>
  <InputText
    id="warehouse-company-name"
    data-testid="warehouse-company-name"
    :model-value="warehouseCompanyName"
    class="inputfield w-full"
    @input="$emit('update:warehouseCompanyName', ($event.target as HTMLInputElement).value)"
    :class="{ 'p-invalid': validate.warehouseCompanyName.$error }"
    :placeholder="t('placeholder.type', { property: t('warehouse.company-name').toLowerCase() })"
  />
  <small class="p-error" v-if="validate.warehouseCompanyName.$error" data-testid="warehouse-company-name-error">
    {{ validate.warehouseCompanyName.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";

const { t } = useI18n();

const props = defineProps<{
  warehouseCompanyName: string;
}>();

const rules = {
  warehouseCompanyName: {
    required,
  },
};

const validate = useVuelidate(rules, props);
</script>
