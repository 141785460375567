<template>
  <DataTable
    :value="batches"
    dataKey="supplierProductNumber"
    :autoLayout="true"
    responsiveLayout="scroll"
    selectionMode="single"
    contextMenu
    class="c-batch-table w-full c-datatable"
    data-testid="inventory-batches"
    stripedRows
  >
    <template #header>
      <div class="flex justify-center">
        <h3 class="m-0">{{ t("inventory.batch.header") }}</h3>
      </div>
    </template>
    <Column field="batchNumber" :header="t('inventory.batch.batch-number')"></Column>
    <Column field="receivedDate" :header="t('inventory.batch.date-received')">
      <template #body="{ data, field }">
        {{ d(data[field]) }}
      </template>
    </Column>
    <Column
      v-for="(col, index) of columns"
      :field="col.field"
      :header="col.header"
      :key="col.field + '_' + index"
      :class="col.class"
    >
    </Column>
    <template #empty>
      <span>{{ t("inventory.batch.no-batches-found") }}</span>
    </template>
  </DataTable>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { DataTableColumn } from "@cumulus/components";
import { Batch } from "@/models/inventory/Batch";

const { t, d } = useI18n();

defineProps<{
  batches: Batch[];
}>();

const columns = ref<DataTableColumn[]>([
  { field: "quantityReceived", header: t("inventory.batch.quantity-received"), class: "text-right", sortable: true },
  { field: "quantityInStock", header: t("inventory.batch.quantity-in-inventory"), class: "text-right", sortable: true },
  {
    field: "receivedCost",
    header: t("inventory.batch.received-cost"),
    class: "text-right",
    sortable: true,
  },
]);
</script>

<style scoped lang="scss">
.c-batch-table {
  :deep(.p-datatable-header) {
    background-color: #fff;
    border: none;
  }
  :deep(.p-datatable-thead > tr > .text-right > .p-column-header-content > .p-column-title) {
    width: 100%;
    text-align: right;
  }
}
</style>
