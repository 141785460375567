import { useAuth } from "@cumulus/event-bus";
import { useErrorHandler } from "@/repositories/ErrorHandler";
import { bringApi } from "./BringApi";
import { BringShippingGuideRequest } from "./BringShippingGuideRequest";
import { type NewBringBooking } from "./NewBringBooking";
import { type PickupPoint } from "./PickupPoint";

export function useBring() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getBringShippingGuide = async (request: BringShippingGuideRequest) => {
    try {
      const authHeaders = await getAuthHeaders();
      return await bringApi.getBringShippingGuide(authHeaders, request);
    } catch (error) {
      await handleError(error);
    }
  };

  const storeBringBookingData = async (newBringBooking: NewBringBooking): Promise<string> => {
    try {
      const authHeaders = await getAuthHeaders();
      return (await bringApi.storeBringBookingData(authHeaders, newBringBooking)).bringBookingId;
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getPickupPoints = async (
    countryCode: string,
    postalCode: string,
    street: string,
    streetNumber: string,
  ): Promise<PickupPoint[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      const response = await bringApi.getPickupPoints(authHeaders, countryCode, postalCode, street, streetNumber);

      return response.pickupPoint;
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return { storeBringBookingData, getBringShippingGuide, getPickupPoints };
}
